/* eslint-disable jsx-a11y/anchor-is-valid */
import {useRef, useState} from 'react'
import {Overlay, Tooltip} from 'react-bootstrap'
import {FormattedMessage, useIntl} from 'react-intl'
import {KTSVG} from '../../../_metronic/helpers'

export function News() {
  const [copied, setCopied] = useState(false)
  const intl = useIntl()

  const [show, setShow] = useState(false)
  const target = useRef(null)

  const channelLinkRef = useRef(null)
  const renderNotification = () => {
    setShow(true)
    console.log('copied')
  }
  const copyToClipboard = () => {
    let linkToCopy = ''
    if (channelLinkRef && channelLinkRef.current !== null) {
      // @ts-ignore
      linkToCopy = channelLinkRef.current.outerText
    }
    renderNotification()
    if ('clipboard' in navigator) {
      return navigator.clipboard.writeText(linkToCopy)
    } else {
      return document.execCommand('copy', true, linkToCopy)
    }
  }

  return (
    <div className='d-flex flex-wrap flex-stack mb-6 justify-content-center'>
      <div className='card-px text-center py-8 mb-10 d-flex flex-column align-items-center'>
        <h2 className='fs-2x fw-bold mb-10'>
          <FormattedMessage id='NEWS.PAGE.HEADING' />
        </h2>
        <p className=' fs-4 fw-semibold mb-10'>
          <FormattedMessage id='NEWS.PAGE.DESCRIPTION' />
         
        </p>
        <div className='text-center d-flex align-items-center'>
          <KTSVG
            path='/media/icons/telegram-app.svg'
            className='svg-icon-primary svg-icon-1 me-1'
          />
          <a
            href='https://t.me/joinchat/6lR2BQSZepdlYTgy'
            className='badge badge-dark text-muted p-2 fs-6 me-2'
            target='_blank'
            rel='noreferrer noopener'
            ref={channelLinkRef}
          >
            https://t.me/joinchat/6lR2BQSZepdlYTgy
          </a>{' '}
          <div
            ref={target}
            onClick={() => {
              copyToClipboard()
              setCopied(true)
            }}
          >
            <KTSVG
              path='/media/icons/duotune/general/gen054.svg'
              className={`svg-icon-3 ${copied ? 'svg-icon-dark' : 'svg-icon-gray-600'}`}
            />
          </div>{' '}
          <Overlay target={target.current} show={show} placement='right'>
            {(props) => (
              <Tooltip id='overlay-example' {...props}>
                {intl.formatMessage({id: 'GENERAL.TOOLTIPS.COPIED'})}{' '}
              </Tooltip>
            )}
          </Overlay>
        </div>
      </div>
    </div>
  )
}
