import {FormattedMessage, useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../../_metronic/helpers'
import {User} from '../../../modules/apps/user-management/users-list/core/_models'

interface Props {
  user: User
}

export function ProfileHeaderInfo({user}: Props) {
  const intl = useIntl()
  const {
    externalId,
    id,
    balance,
    city,
    street,
    house,
    packagesCount,
    ordersCount,
    tracksCount,
    currency,
  } = user

  return (
    <>
      {' '}
      <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
        <p className='d-flex align-items-center text-gray-400 text-hover-primary m-0 me-5 mb-2'>
          <KTSVG path='/media/icons/duotune/communication/com006.svg' className='svg-icon-4 me-1' />
          {intl.formatMessage({id: 'GENERAL.ID_LABEL'})}: {externalId ? externalId : id}
        </p>
        <p className='d-flex align-items-center text-gray-400 text-hover-primary m-0 me-5 mb-2'>
          <span className='me-1'>
            {' '}
            <span> {currency}</span>
          </span>{' '}
          <span style={{color: balance ? (+balance >= 0 ? 'green' : 'red') : ''}}> {balance}</span>{' '}
        </p>
        <p className='d-flex align-items-center text-gray-400 text-hover-primary m-0 mb-2'>
          <KTSVG path='/media/icons/duotune/general/gen018.svg' className='svg-icon-4 me-1' />
          {`${city}, ${street}, ${house ? house.toString().split(' ').join('') : ''}`}
        </p>
      </div>
      <div className='d-flex flex-wrap flex-stack'>
        <div className='d-flex flex-column flex-grow-1 pe-8'>
          <div className='d-flex flex-wrap'>
            <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
              <Link to='/buyouts'>
                <div className='d-flex align-items-center'>
                  <div className='fs-2 fw-bolder text-black text-hover-primary'>{ordersCount}</div>
                </div>
              </Link>

              <div className='fw-bold fs-6 text-gray-400'>
                <FormattedMessage id='BUYOUTS.PAGE.PAGE_TITLE' />{' '}
              </div>
            </div>

            <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
              <Link to='/tracks'>
                <div className='d-flex align-items-center'>
                  <div className='fs-2 fw-bolder text-black text-hover-primary'>{tracksCount}</div>
                </div>
              </Link>

              <div className='fw-bold fs-6 text-gray-400'>
                <FormattedMessage id='TRACKS.PAGE.PAGE_TITLE' />{' '}
              </div>
            </div>

            <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
              <Link to='/parcels'>
                {' '}
                <div className='d-flex align-items-center'>
                  <div className='fs-2 fw-bolder text-black text-hover-primary'>
                    {packagesCount}
                  </div>
                </div>
              </Link>

              <div className='fw-bold fs-6 text-gray-400'>
                <FormattedMessage id='PARCELS.PAGE.PAGE_TITLE' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
