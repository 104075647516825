import {useListView} from '../../../core/ListViewProvider'
import {BuyoutsListToolbar} from './BuyoutsListToolbar'
import {BuyoutsListGrouping} from './BuyoutsListGrouping'
import {BuyoutsListSearchComponent} from './BuyoutsListSearchComponent'

const BuyoutsListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6 justify-content-start'>
      <BuyoutsListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {/* {selected.length > 0 ? <BuyoutsListGrouping /> : <BuyoutsListToolbar />} */}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {BuyoutsListHeader}
