import {FormattedMessage, useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {scrollToTabsStart} from '../../../../_metronic/helpers/custom/funcs/scrollToTabsStart'
import {User} from '../../../modules/apps/user-management/users-list/core/_models'
import {ProfileHeaderInfo} from './ProfileHeaderInfo'
import {ProfileHeaderName} from './ProfileHeaderName'

interface Props {
  user: User
}

const ProfileHeader = ({user}: Props) => {
  const location = useLocation()
  const intl = useIntl()

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3 justify-content-between'>
          {' '}
          <div className='d-flex flex-row'>
            <div className='me-7 mb-4'>
              <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt='avatar_blank' />
              </div>
            </div>
            <div className='d-flex flex-column'>
              <ProfileHeaderName user={user} />{' '}
              <div className='d-none d-md-block'>
                <ProfileHeaderInfo user={user} />
              </div>
            </div>
          </div>
          <div className='d-md-none'>
            <ProfileHeaderInfo user={user} />
          </div>
        </div>

        <div className='d-flex overflow-auto h-55px mb-5 mb-md-0' id='item-page-header'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
            <li className='nav-item' onClick={scrollToTabsStart}>
              <Link
                className={
                  `nav-link text-active-primary me-6 text-center ` +
                  (location.pathname === `/profile/overview` && 'active')
                }
                to={`/profile/overview`}
              >
                <FormattedMessage id='PROFILE.PAGE.PAGE_TITLE' />
              </Link>
            </li>
            <li className='nav-item' onClick={scrollToTabsStart}>
              <Link
                className={
                  `nav-link text-active-primary me-6 text-center ` +
                  (location.pathname === `/profile/contacts` && 'active')
                }
                to={`/profile/contacts`}
              >
                <FormattedMessage id='PROFILE.PAGE.CONTACTS.TITLE' />
              </Link>
            </li>
            <li className='nav-item' onClick={scrollToTabsStart}>
              <Link
                className={
                  `nav-link text-active-primary me-6 text-center ` +
                  (location.pathname === `/profile/shipment` && 'active')
                }
                to={`/profile/shipment`}
              >
                <FormattedMessage id='PROFILE.PAGE.ADDRESS.TITLE' />
              </Link>
            </li>
            <li className='nav-item' onClick={scrollToTabsStart}>
              <Link
                className={
                  `nav-link text-active-primary me-6 text-center ` +
                  (location.pathname === `/profile/social-media` && 'active')
                }
                to={`/profile/social-media`}
              >
                <FormattedMessage id='PROFILE.PAGE.SOCIAL_MEDIA.TITLE' />
              </Link>
            </li>
            <li className='nav-item' onClick={scrollToTabsStart}>
              <Link
                className={
                  `nav-link text-active-primary me-6 text-center ` +
                  (location.pathname === `/profile/referal` && 'active')
                }
                to={`/profile/referal`}
              >
                <FormattedMessage id='PROFILE.PAGE.REFERAL.TITLE' />
              </Link>
            </li>
            <li className='nav-item' onClick={scrollToTabsStart}>
              <Link
                className={
                  `nav-link text-active-primary me-6 text-center ` +
                  (location.pathname === `/profile/password` && 'active')
                }
                to={`/profile/password`}
              >
                <FormattedMessage id='PROFILE.PAGE.PASSWORD.TITLE' />
              </Link>
            </li>
            <li className='nav-item' onClick={scrollToTabsStart}>
              <Link
                className={
                  `nav-link text-active-primary me-6 text-center ` +
                  (location.pathname === `/profile/settings` && 'active')
                }
                to={`/profile/settings`}
              >
                <FormattedMessage id='PROFILE.PAGE.SETTINGS.TITLE' />
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export {ProfileHeader}
