import {usePageData} from '../../../../_metronic/layout/core'
import {useListView} from '../core/ListViewProvider'
import {CreateTrackRawForm} from './CreateTrackRawForm'

export const OnePageWrapper = () => {
  const {itemIdForUpdate} = useListView()
  const {pageCustomData} = usePageData()

  if (!itemIdForUpdate && pageCustomData?.trackFormSimplified === true) {
    return <CreateTrackRawForm />
  }

  return null
}
