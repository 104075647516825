/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useQuery, useQueryClient} from 'react-query'
import {IShipmentAddress} from '../../../../../../_metronic/helpers/custom/tsHelpers/profileHelpers'
import {Portal} from '../../../../../../_metronic/partials'
import {SearchQueryProps} from '../../../../../modules/custom/page-items/shipment/Filter'
import {ShipmentCard} from '../../../../../modules/custom/page-items/shipment/ShipmentCard'
import {ShipmentCreateCard} from '../../../../../modules/custom/page-items/shipment/ShipmentCreateCard'
import {CreateShipmentAddress} from '../../../../../modules/custom/page-items/shipment/_modals/CreateShipmentAddress'
import {addReceiverAddress, getReceiverAddresses} from '../../../receivers-list/core/_requests'

interface Props extends SearchQueryProps {
  receiverId: string | number
}

export function ReceiverShipment({receiverId, searchQuery, setSearchQuery}: Props) {
  const queryClient = useQueryClient()
  const intl = useIntl()
  const [modalOpen, setModalOpen] = useState(false)
  const [tempDeliveryType, setTempDeliveryType] = useState(0)

  const {data: receiverAddresses} = useQuery('receiverAddresses', () => {
    return getReceiverAddresses(receiverId)
  })

  return (
    <div className='mb-5 mb-xl-10'>
      {receiverAddresses ? (
        <>
          {' '}
          {modalOpen && (
            <Portal
              children={
                <CreateShipmentAddress
                  setIsOpen={setModalOpen}
                  isOpen={modalOpen}
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                  profileAddresses={receiverAddresses}
                  tempType={tempDeliveryType}
                  apiMutation={{
                    apiRequest: addReceiverAddress,
                    toInvalidate: 'receiverAddresses',
                    id: receiverId,
                  }}
                />
              }
              className='modal-root'
            />
          )}
          <div className='d-flex flex-wrap flex-stack mb-6'>
            <h3 className='fw-bolder my-2'>
              <FormattedMessage id='RECEIVERS.REC_PAGE.ADDRESS.HEADING' />
            </h3>

            {/* <div className='d-flex align-items-center my-2'>
              <button
                className='btn btn-primary btn-sm'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_create_app'
                id='kt_toolbar_primary_button'
                onClick={() => setModalOpen(true)}
              >
                Добавить адрес{' '}
              </button>
            </div> */}
          </div>
          <div className='row g-6 g-xl-9  flex-gutter'>
            {receiverAddresses.map((item, index) => (
              <ShipmentCard
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                key={index}
                item={item}
                apiMutation={{
                  apiRequest: addReceiverAddress,
                  toInvalidate: 'receiverAddresses',
                  id: receiverId,
                }}
              />
            ))}

            {receiverAddresses.every((item) => item.post !== false) && (
              <ShipmentCreateCard
                addressType={{
                  id: 1,
                  label: intl.formatMessage({id: 'RECEIVERS.REC_PAGE.ADDRESS.HOME_ADDRESS_LABEL'}),
                }}
                key={1}
                setModalOpen={setModalOpen}
                setTempType={setTempDeliveryType}
              />
            )}

            {receiverAddresses.every((item) => item.post !== true) && (
              <ShipmentCreateCard
                addressType={{
                  id: 2,
                  label: intl.formatMessage({id: 'RECEIVERS.REC_PAGE.ADDRESS.NP_ADDRESS_LABEL'}),
                }}
                key={2}
                setModalOpen={setModalOpen}
                setTempType={setTempDeliveryType}
              />
            )}
          </div>
        </>
      ) : (
        <FormattedMessage id='GENERAL.BUTTONS.WAIT' />
      )}
    </div>
  )
}
