import {useEffect, useRef, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {Formik, Form, FormikValues} from 'formik'
import {useMutation, useQueryClient} from 'react-query'
import {StepperComponent} from '../../../../_metronic/assets/ts/components'
import {Modal} from 'react-bootstrap'
import {AddTrackForm, IInvoice, IRecentInvoiceRes} from '../core/_models/_tracks-models'
import {createTrackOnePageSchema, createTrackSchema} from '../core/yup/track'
import {InputTemplate} from '../../../modules/custom/form-elements/InputTemplate'
import {CheckboxSwitcher} from '../../../modules/custom/form-elements/checkboxes/CheckboxSwitcher'
import {Notification} from '../../../modules/custom/Notification'
import {TrackGoods} from '../tabs/goods/TrackGoods'
import {IBasicSelect} from '../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {addTrack} from '../core/_requests'
import {TrackCodeField} from '../tabs/general/TrackCodeField'
import {CreateLoading} from '../../../modules/custom/responseHandlers/CreateLoading'
import {CreateError} from '../../../modules/custom/responseHandlers/CreateError'
import {reformTrackProducts} from '../../../../_metronic/helpers/custom/funcs/reformProducts'
import {useAuth} from '../../../modules/auth'
import {FormattedMessage, useIntl} from 'react-intl'
import {useListView} from '../core/ListViewProvider'
import {Link} from 'react-router-dom'
import {TrackStepper} from './TrackStepper'
import {TrackModalHeader} from './TrackModalHeader'
import {TrackInvoicesTab} from '../tabs/invoices/TrackInvoicesTab'
import {TrackInspectionTab} from '../tabs/inspection/TrackInspectionTab'
import {TrackParcelType} from '../tabs/parcel/TrackParcelType'
import {checkForAnyCommercialProducts} from '../../../../_metronic/helpers/custom/funcs/checkForCommercial'
import {createPortal} from 'react-dom'

const modalsRoot = document.getElementById('root-modals') || document.body

const CreateTrackRawForm = () => {
  // service
  const intl = useIntl()
  const {currentUser} = useAuth()
  const {setItemIdForUpdate, itemIdForUpdate} = useListView()
  const queryClient = useQueryClient()
  const {refetch} = useQueryResponse()

  // form fields data
  const receiversListData: IBasicSelect[] = queryClient.getQueryData('receiversList') || []
  const recentInvoicesData: IRecentInvoiceRes[] = queryClient.getQueryData('recentInvoices') || []
  const [invoiceArray, setInvoiceArray] = useState<IInvoice[] | []>([])
  const goodsFields = [
    {
      group: {label: '', value: ''},
      name: {label: '', value: '', require_invoice: 0, brands: []},
      brand: {label: '', value: ''},
      qty: 1,
      cost: '',
      total: '',
      repack: false,
      weight: '',
    },
  ]
  const inits: AddTrackForm = {
    track_code: '',
    delivery_type: currentUser && currentUser.settings ? +currentUser?.settings?.delivery_type : 1,
    package_type: 0,
    insurance: false,
    prods: goodsFields,
    invoice_file: null,
    invoiceLinks: [''],
    orderInspection: false,
    inspectionDescription: '',
    receiverNeeded: false,
    receiver: receiversListData[0],
    require_invoice: 0,
    earlySubmitAllowed: false,
  }
  // useStates

  const [initValues] = useState<AddTrackForm>(inits)

  const [isLoading, setIsLoading] = useState(false)

  const [disabledCommercialSettings, setDisabledCommercialSettings] = useState(false)

  const [trackCode, setTrackCode] = useState('')

  const [trackCodeStatus, setTrackCodeStatus] = useState({success: false, msg: '', id: ''})

  //mutation
  const createTrackMutation = useMutation(addTrack, {
    onMutate: () => {
      setIsLoading(true)
      //   setCreateResponse('loading')
    },
    onSuccess: (data) => {
      //   setCreateResponse('success')
      setItemIdForUpdate(undefined)
    },
    onError: (error) => {
      //   setCreateResponse('error')
      console.log(error)
    },
    onSettled: () => {
      setIsLoading(false)
      refetch()
    },
  })

  const handleSubmit = (values: AddTrackForm) => {
    const {
      track_code,
      delivery_type,
      package_type,
      insurance,
      prods,
      invoiceLinks,
      orderInspection,
      inspectionDescription,
      receiver,
      description,
    } = values

    const repackFootwear = prods.some(({repack}) => repack === true)

    const trackData = {
      track_code,
      type_delive: delivery_type,
      mitnitsya: package_type,
      insurance: insurance === true ? 1 : 0,
      prods: reformTrackProducts(prods),
      invoice_file:
        invoiceArray.map((item) => {
          return {name: item.name, tmp_name: item.file.filename}
        }) || undefined,
      invoice: invoiceLinks,
      check_control: orderInspection === true ? 1 : 0,
      check_control_desc: inspectionDescription,
      receiver: receiver?.value ? +receiver.value : +receiversListData[0].value,
      description: description ? description : undefined,
      repack: repackFootwear === true ? 1 : 0,
    }

    console.log('trackDataToSend', trackData)

    createTrackMutation.mutate(trackData)
  }

  useEffect(() => {
    document.body.style.overflow = ''
  }, [])

  //   const submitStep = (values: AddTrackForm, actions: FormikValues) => {
  //     console.log('values', values)
  //     //check whether the track is a commercial one
  //     const isCommercialProd = checkForAnyCommercialProducts(values.prods)

  //     // setting invoice as required/not required
  //     const categoriesRequiringInvoice = values.prods.some(({name}) => +name.require_invoice === 1)

  //     if (+values.package_type === 1 || categoriesRequiringInvoice === true) {
  //       actions.setFieldValue('require_invoice', 1)
  //     } else {
  //       actions.setFieldValue('require_invoice', 0)
  //     }

  //     if (!stepper.current) {
  //       return
  //     }

  //     setCurrentSchema(createTrackSchema(intl)[stepper.current.currentStepIndex])

  //     if (stepper.current.currentStepIndex === stepper.current.totatStepsNumber) {
  //       handleFinalSubmit(values)

  //       stepper.current?.goNext()
  //     } else if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
  //       stepper.current.goNext()
  //       console.log(stepper.current.currentStepIndex)

  //       if (isCommercialProd === true) {
  //         if (stepper.current.currentStepIndex === 3) {
  //           setDisabledCommercialSettings(true)
  //           setCurrentSchema(createTrackSchema(intl)[stepper.current.currentStepIndex + 1])
  //           stepper.current?.goNext()
  //         }
  //       } else {
  //         setDisabledCommercialSettings(false)
  //       }
  //     } else {
  //       stepper.current.goto(1)
  //       actions.resetForm()
  //       setInvoiceArray([])
  //     }
  //   }

  //   const goToStep = (number: number) => {
  //     if (stepper.current && stepper.current.currentStepIndex > number) {
  //       setCurrentSchema(createTrackSchema(intl)[number - 1])
  //       stepper.current.goto(number)
  //     }
  //   }

  //   const handleStepperItemClick = (e: React.MouseEvent<HTMLSpanElement>, number: number) => {
  //     const stepperItem = e.currentTarget as Element

  //     const stepperElement = stepperItem.closest('.stepper-nav') as Element

  //     if (!stepperItem.classList.contains('current')) {
  //       goToStep(number)
  //     }
  //     const arrowSpan = stepperItem.querySelector('.arrow') as Element
  //     if (
  //       arrowSpan &&
  //       stepperElement &&
  //       stepper?.current &&
  //       number <= stepper?.current?.currentStepIndex
  //     ) {
  //       arrowSpan.classList.toggle('active')
  //       stepperElement.classList.toggle('active')
  //     }
  //   }

  //   useEffect(() => {
  //     if (!stepperRef.current) {
  //       return
  //     }

  //     loadStepper()
  //   }, [stepperRef])

  //   // useEffect(() => {
  //   //   console.log('recs use', receiversListData)
  //   // }, [receiversListData])

  //   useEffect(() => {
  //     const handleClickOutsideStepper = (e: MouseEvent) => {
  //       const stepperNav = document.querySelector('.stepper-nav.active')
  //       if (stepperNav && !stepperNav.contains(e.target as Node)) {
  //         const arrowSpan = stepperNav.querySelector('.arrow') as Element

  //         if (arrowSpan) {
  //           arrowSpan.classList.toggle('active')
  //           stepperNav.classList.toggle('active')
  //         }
  //       }
  //     }

  //     document.addEventListener('mousedown', handleClickOutsideStepper)

  //     return () => {
  //       document.removeEventListener('mousedown', handleClickOutsideStepper)
  //     }
  //   }, [])
  //   // console.log('w', window)

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog w-100 mw-md-auto mw-0 me-0 my-0'
      show={itemIdForUpdate === null}
      onHide={() => setItemIdForUpdate(undefined)}
      // onEntered={loadStepper}
      backdrop={false}
      className='OnePageTrackForm mw-md-auto mw-lg-auto mw-0 p-0 shadow-sm'
      style={{borderLeft: '2px solid var(--kt-primary)'}}
    >
      <div
        className='d-flex flex-column justify-content-between modal-content modal-content-container'
        style={{
          height: '100vh',
          borderRadius: 0,
          overflowY: 'auto',
        }}
      >
        <TrackModalHeader trackNumber={trackCode.toUpperCase() || ''} />

        <Formik
          validationSchema={createTrackOnePageSchema(intl)}
          initialValues={initValues}
          onSubmit={handleSubmit}
          enableReinitialize={true}
          validateOnBlur={false}
          validateOnChange={false}
        >
          {({values, setFieldValue, isValid, errors}) => {
            return (
              <Form className='form' noValidate id='kt_modal_create_track_form'>
                {/* {JSON.stringify(errors)} */}
                {/* {JSON.stringify(formValues)} */}
                <div
                  className='scroll-y bg-white modal-body'
                  style={{height: 'calc(100vh-200px)', overflowY: 'scroll'}}
                >
                  <div>
                    <div className='w-100'>
                      <div className='fv-row mb-10'>
                        <TrackCodeField
                          setFieldValue={setFieldValue}
                          value={values.track_code}
                          trackCodeStatus={trackCodeStatus}
                          setTrackCodeStatus={setTrackCodeStatus}
                          trackCode={trackCode}
                          setTrackCode={setTrackCode}
                        />
                      </div>
                      <div className='fv-row mb-10'>
                        <CheckboxSwitcher
                          inputName='delivery_type'
                          firstOption={intl.formatMessage({
                            id: 'GENERAL.DELIVERY_TYPE_NAME.AVIA',
                          })}
                          secondOption={intl.formatMessage({
                            id: 'GENERAL.DELIVERY_TYPE_NAME.SEA',
                          })}
                          // @ts-ignore
                          value={+values.delivery_type}
                          setFieldValue={setFieldValue}
                        />
                      </div>
                      {values.delivery_type === 1 && (
                        <div className='fv-row mb-5'>
                          <Notification
                            noteText={
                              <FormattedMessage
                                id='GENERAL.NOTIFICATIONS.SEA_TRACK_BUYOUT'
                                values={{
                                  // @ts-ignore
                                  b: (chunks: any) => <b style={{color: 'black'}}>{chunks}</b>,
                                  icon: <svg />,
                                }}
                              />
                            }
                          />
                        </div>
                      )}
                      {receiversListData && receiversListData.length > 1 && (
                        <div className='fv-row mb-5'>
                          <InputTemplate
                            inputName='receiverNeeded'
                            title={intl.formatMessage({
                              id: 'Добавить персонального получателя',
                            })}
                            type='checkbox'
                            checkboxProps={{
                              checked: values.receiverNeeded,
                              disabled: false,
                              setFieldValue: setFieldValue,
                              value: 1,
                              inputName: 'receiverNeeded',
                              type: 'checkbox',
                            }}
                            containerMarginBottom={false}
                          />
                        </div>
                      )}
                      {values.receiverNeeded === true && (
                        <>
                          {' '}
                          <div className='fv-row mb-10'>
                            {receiversListData && (
                              <InputTemplate
                                inputName='receiver'
                                required={true}
                                type='select'
                                value={values.receiver}
                                title={intl.formatMessage({
                                  id: 'TRACKS.ADD_MODAL.FORM.RECEIVERS_TITLE',
                                })}
                                titleFontSize='fs-5'
                                reactSelectprops={{
                                  options: receiversListData,
                                  defaultValue: receiversListData[0],
                                  selectName: 'receiver',
                                  mock: false,
                                  isLoading: false,
                                  onInputChange: () => {},
                                  setFieldValue: setFieldValue,
                                  setObject: true,
                                  placeholder: intl.formatMessage({
                                    id: 'TRACKS.ADD_MODAL.FORM.RECEIVERS_PLACEHOLDER',
                                  }),
                                  noOptionsMessage: intl.formatMessage({
                                    id: 'TRACKS.ADD_MODAL.FORM.NO_RECEIVERS_MESSAGE',
                                  }),
                                }}
                              />
                            )}
                          </div>
                          <div className='fv-row mb-10'>
                            <Notification
                              noteText={intl.formatMessage({
                                id: 'TRACKS.ADD_MODAL.FORM.DELIVERY_PROMPT',
                              })}
                              link={{
                                text: intl.formatMessage({
                                  id: 'TRACKS.ADD_MODAL.FORM.DELIVERY_PROMPT.LINK_WORD',
                                }),
                                url: '/receivers/list',
                              }}
                            />
                          </div>
                        </>
                      )}
                      <div className='fv-row'>
                        <InputTemplate
                          type='textarea'
                          inputName='description'
                          title={intl.formatMessage({
                            id: 'TRACKS.ADD_MODAL.FORM.COMMENT_TITLE',
                          })}
                          addPrompt={false}
                          required={false}
                        />
                      </div>
                    </div>
                  </div>{' '}
                  <div className='separator bg-primary h-3px mb-3'></div>
                  <div className='mb-6'>
                    <div className='w-100'>
                      <TrackGoods
                        prods={values.prods}
                        setFieldValue={setFieldValue}
                        values={values}
                        currentStep={1}
                        setDisabledCommercialSettings={setDisabledCommercialSettings}
                      />
                    </div>
                  </div>
                  <div className='separator bg-primary h-2px mb-3'></div>
                  <div>
                    <TrackParcelType
                      values={values}
                      disabledCommercialSettings={disabledCommercialSettings}
                      currentStep={1}
                    />
                  </div>
                  <div className='separator bg-primary h-3px mb-3'></div>
                  <div>
                    <TrackInvoicesTab
                      currentStep={1}
                      invoiceArray={invoiceArray}
                      recentInvoicesData={recentInvoicesData}
                      setInvoiceArray={setInvoiceArray}
                      setIsLoading={setIsLoading}
                      values={values}
                    />
                  </div>
                  <div className='separator bg-primary h-3px mb-3'></div>
                  <div>
                    <TrackInspectionTab values={values} />
                  </div>
                </div>
                <div
                  className='modal-footer bg-white d-flex flex-stack justify-content-start pt-10'
                  style={{position: 'sticky', bottom: '0'}}
                >
                  <div className={`d-flex justify-content-between`}>
                    <div>
                      {trackCodeStatus.id && trackCodeStatus.id !== '' ? (
                        <Link to={`/tracks/edit/overview/${trackCodeStatus.id}`}>
                          <button
                            type='button'
                            className='btn btn-lg btn-primary me-3  minify-button fs-7 fs-md-5'
                          >
                            <FormattedMessage id='GENERAL.BUTTONS.EDIT' />
                            <KTSVG
                              path='/media/icons/duotune/arrows/arr064.svg'
                              className='svg-icon-3 ms-2 me-0'
                            />
                          </button>
                        </Link>
                      ) : (
                        <button
                          type='submit'
                          disabled={
                            isLoading === true || trackCodeStatus.success === false || !isValid
                              ? true
                              : false
                          }
                          className='btn btn-lg btn-primary me-3 minify-button fs-7 fs-md-5'
                        >
                          <span className='indicator-label'>
                            {isLoading === true ? (
                              <span className='indicator-progress' style={{display: 'block'}}>
                                <FormattedMessage id='GENERAL.BUTTONS.WAIT' />
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                              </span>
                            ) : (
                              <FormattedMessage id='GENERAL.BUTTONS.CREATE' />
                            )}
                          </span>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
    </Modal>,
    modalsRoot
  )
}

export {CreateTrackRawForm}
