import {Formik, Form, Field, ErrorMessage} from 'formik'
import {profileContactsSchema} from '../../core/yup/user-profile'
import {profileNumber} from '../../../../../_metronic/helpers/custom/tsHelpers/profileHelpers'
import {ProfilePhoneNumbers} from './ProfilePhoneNumbers'
import {CardFooter} from '../../../../modules/custom/form-elements/CardFooter'
import {useState, Dispatch, SetStateAction} from 'react'
import {UserModel} from '../../../../modules/auth'
import {profileApi} from '../../../../../_metronic/helpers/custom/api/profileApi'
import {FormattedMessage, useIntl} from 'react-intl'

interface Props {
  user: UserModel
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
}

export function ProfileContacts({user, setCurrentUser}: Props) {
  const intl = useIntl()

  const initialValues = {
    phoneNumbers: user.phoneNumber,
    email: user.email || '',
  }
  const [loading, setLoading] = useState(false)
  const [disableSubmit, setDisableSubmit] = useState(false)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState(false)

  const handleSubmit = async (values: {phoneNumbers: profileNumber[]; email: string}) => {
    console.log('profile contacts values', values)
    setLoading(true)
    try {
      const response = await profileApi.editProfileOverview({
        email: values.email,
        phoneNumber: values.phoneNumbers,
      })

      setError('')
      setCurrentUser(response)
      setSuccess(true)

      setTimeout(() => {
        setSuccess(false)
      }, 3000)

      console.log('resp', response)
    } catch (error) {
      setError(intl.formatMessage({id: 'GENERAL.ERRORS.USED_EMAIL'}))
    }

    setLoading(false)
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div id='kt_account_profile_details ' className='collapse show'>
        <Formik
          validationSchema={profileContactsSchema(intl)}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({values, setFieldValue}) => (
            <Form noValidate className='form'>
              <div className='card-body  p-9'>
                <ProfilePhoneNumbers
                  phoneNumbers={values.phoneNumbers}
                  setFieldValue={setFieldValue}
                  setDisableSubmit={setDisableSubmit}
                />
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                    <FormattedMessage id='PROFILE.PAGE.CONTACTS.EMAIL' />
                  </label>
                  <div className='col-lg-8  w-50'>
                    <div className='row'>
                      <Field
                        type='email'
                        className='form-control form-control-lg form-control-solid'
                        name='email'
                        placeholder=''
                      />
                      <div className='text-danger'>
                        {error}
                        <ErrorMessage name='email' />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <CardFooter loading={loading} disabled={disableSubmit} success={success} />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}
