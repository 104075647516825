import {IBuyoutFormProds} from '../../../../app/pages/buyouts/core/_models/_buyout-models'
import {ITrackFormProds} from '../../../../app/pages/tracks/core/_models/_tracks-models'

export const checkForAnyCommercialProducts = (prods: ITrackFormProds[] | IBuyoutFormProds[]) => {
  const isCommercialBrand = prods.some(({brand}) => brand?.commercial && +brand.commercial === 1)

  const isCommercialQty = prods.some(
    ({name, qty}) => name.com_qty && +name.com_qty && +qty >= +name.com_qty
  )
  const isCommercialPrice = prods.some(
    ({name, cost}) => name.com_price && +name.com_price && +cost >= +name.com_price
  )

  const anyComProds = isCommercialBrand || isCommercialQty || isCommercialPrice
  return anyComProds
}

export const checkProductForCommercialValues = (prod: ITrackFormProds | IBuyoutFormProds) => {
  const isComProd =
    (prod?.brand?.commercial && prod.brand.commercial === 1) ||
    (prod.name.com_price && prod.cost >= prod.name.com_price) ||
    (prod.name.com_qty && prod.qty >= prod.name.com_qty)
  return isComProd
}
