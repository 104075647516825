/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import {useMutation} from 'react-query'
import {KTSVG} from '../../../../../_metronic/helpers'
import {Portal} from '../../../../../_metronic/partials'
import {ActionConfirmation} from '../../../../modules/custom/modals/ActionConfirmation'
import {ITrackProds, Track} from '../../core/_models/_tracks-models'
import {editTrack} from '../../core/_requests'
import {EditTrackContentCard} from './_modals/EditTrackContentCard'
import {FormattedMessage, useIntl} from 'react-intl'

type Props = {
  items: ITrackProds[]
  item: ITrackProds
  track: Track
  setCurrTrack: (obj: Track) => void
  isArchive: boolean
}

export function TrackItemCard({items, item, track, setCurrTrack, isArchive}: Props) {
  const intl = useIntl()
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const {track_package} = track
  const {name, qty, cost, total} = item

  const deleteMutation = useMutation<any, Error>(
    async () => {
      const filteredProducts = track.prods.filter((prod) => prod.id !== item.id)
      return await editTrack({
        ...track,
        receiver: track?.receiver?.id ? +track?.receiver?.id : null,
        prods: filteredProducts,
        invoice_file:
          track.invoice_file.map((item) => {
            return {name: item.name, tmp_name: item.file.filename}
          }) || undefined,
      })
    },
    {
      onSuccess: (data) => {
        setCurrTrack(data)
      },
      onSettled: () => {
        setDeleteModalOpen(false)
      },
      onError: (error) => {
        console.log(error)
      },
    }
  )

  return (
    <>
      {editModalOpen && (
        <Portal
          children={
            <EditTrackContentCard
              setIsOpen={setEditModalOpen}
              isOpen={editModalOpen}
              dataForEditing={item}
              track={track}
              readOnly={isArchive}
              setCurrTrack={setCurrTrack}
            />
          }
          className='modal-root'
        />
      )}

      {deleteModalOpen && (
        <Portal
          children={
            <ActionConfirmation
              actionName={intl.formatMessage({id: 'TRACK_PAGE.CONTENT_TAB.DELETE_CONF'})}
              setModalOpen={setDeleteModalOpen}
              handleAction={() => deleteMutation.mutate()}
              id={track.id}
              isOpen={deleteModalOpen}
            />
          }
          className='modal-root'
        />
      )}
      <div className='col-sm-6 col-xl-4'>
        <div className='card h-100 border border-2 border-gray-300 border-hover'>
          <div className='card-body p-9'>
            <div className='d-flex justify-content-between align-items-center mb-7'>
              <a
                href='#'
                className='fs-3 fw-bolder text-gray-700 text-hover-primary'
                onClick={() => setEditModalOpen(true)}
              >
                {name}
              </a>

              {isArchive === false && track_package.length === 0 && items.length > 1 && (
                <div className='card-toolbar'>
                  <button
                    type='button'
                    className='btn btn-sm btn-icon btn-color-gray-900 btn-active-light-primary'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                    onClick={() => setDeleteModalOpen(true)}
                  >
                    {' '}
                    <KTSVG
                      path='/media/icons/duotune/general/gen027.svg'
                      className='svg-icon-3 svg-icon-gray-900'
                    />
                  </button>
                </div>
              )}
            </div>
            {/* {track.mitnitsya == 1 && (
              <div>
                <div className={`badge badge-light-info fw-bolder px-2 py-2 mb-6`}>
                  <FormattedMessage id='TRACK_PAGE.CONTENT_TAB.COM_PROD' />
                </div>
              </div>
            )} */}
            <div className='d-flex flex-wrap mb-5'>
              <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3'>
                <span className='fs-6 text-gray-800 fw-bolder me-2'>{qty}</span>
                <span className='fw-bold text-gray-400'>
                  <FormattedMessage id='TRACK_PAGE.CONTENT_TAB.CARD_QTY' />
                </span>
              </div>

              <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3'>
                <span className='fs-6 text-gray-800 fw-bolder me-2'>${cost}</span>
                <span className='fw-bold text-gray-400'>
                  <FormattedMessage id='TRACK_PAGE.CONTENT_TAB.CARD_COST' />
                </span>
              </div>

              <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3'>
                <span className='fs-6 text-gray-800 fw-bolder me-2'>${total}</span>
                <span className='fw-bold text-gray-400'>
                  <FormattedMessage id='TRACK_PAGE.CONTENT_TAB.CARD_TOTAL' />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
