import * as Yup from 'yup'
import {trackPartials} from './partials'

const {
  trackCode,
  receiver,
  deliveryType,
  description,
  prodsItem,
  packageType,
  insurance,
  requireInvoice,
  invoiceFile,
  invoiceLinks,
  orderInspection,
  inspectionDescription,
  returnToStore,
} = trackPartials

const trackPageOverviewSchema = (intl: any) =>
  Yup.object({
    track_code: trackCode(intl),
    deliveryType,
    description,
    returnToStore,
  })

const trackPageParcelSchema = (intl: any) =>
  Yup.object({
    package_type: packageType,
    insurance,
  })

const trackPageInvoiceSchema = (intl: any) =>
  Yup.object().shape(
    {
      require_invoice: requireInvoice,
      invoice_file: invoiceFile(intl),
      invoiceLinks: invoiceLinks(intl),
    },
    [['invoice_file', 'invoiceLinks']]
  )

const trackPageInspectionSchema = (intl: any) =>
  Yup.object({
    orderInspection,
    inspectionDescription: inspectionDescription(intl),
  })

const trackPageReceiverSchema = (intl: any) =>
  Yup.object({
    receiver: receiver(intl),
  })

const сreateTrackPageContentCardSchema = (intl: any) => [prodsItem(intl)]

export {
  trackPageOverviewSchema,
  сreateTrackPageContentCardSchema,
  trackPageInvoiceSchema,
  trackPageInspectionSchema,
  trackPageReceiverSchema,
  trackPageParcelSchema,
}

// not tested yet

// const createTrackPageReceiverSchema = [
//   Yup.object({
//     lastName: Yup.string().required('Укажите фамилию').label('Фамилия'),
//     name: Yup.string().required('Укажите имя').label('Имя'),
//     patronymic: Yup.string().required('Укажите отчество').label('Отчество'),
//     phoneNumber: Yup.string()
//       .min(15, 'Поле заполнено неверно, введите все цифры')
//       .required('Укажите номер телефона')
//       .label('Номер телефона'),
//     email: Yup.string().required('Укажите почту').label('Почта'),
//   }),
//   Yup.object({
//     typeOfDelivery: Yup.number().required().label('Способ доставки'),
//     mainAddress: Yup.mixed().required().label('Основное отделение'),
//   }),
//   Yup.object({
//     houseNumber: Yup.number()
//       .typeError('Допустимы только цифры')
//       .when('typeOfDelivery', {
//         is: 1,
//         then: Yup.number()
//           .min(1, 'Нельзя выбрать ноль или отрицательное значение')
//           .required('Укажите номер дома')
//           .label('Номер дома'),
//       }),
//     houseLetter: Yup.string().when('typeOfDelivery', {
//       is: 1,
//       then: Yup.string()
//         .max(1, 'Поле может содержать только один символ')
//         .label('Буква дома')
//         .matches(/^[^a-zA-Za-åa-ö-w-я]+$/, 'Допустимы только буквы'),
//     }),
//     street: Yup.string().when('typeOfDelivery', {
//       is: 1,
//       then: Yup.string().required('Укажите название улицы').label('Название улицы'),
//     }),
//     apartment: Yup.string().when('typeOfDelivery', {
//       is: 1,
//       then: Yup.string().label('Номер квартиры'),
//     }),
//     office: Yup.string().when('typeOfDelivery', {
//       is: 1,
//       then: Yup.string().label('Номер офиса'),
//     }),
//     city: Yup.string().when('typeOfDelivery', {
//       is: 1,
//       then: Yup.string().required('Укажите населённый пункт').label('Населённый пункт'),
//     }),
//     mainOfficeCity: Yup.string().when('typeOfDelivery', {
//       is: 2,
//       then: Yup.string()
//         .required('Укажите населённый пункт основного отделения')
//         .label('Населённый пункт основного отделения'),
//     }),
//     mainOfficeNumber: Yup.string().when('typeOfDelivery', {
//       is: 2,
//       then: Yup.string()
//         .required('Укажите номер основного отделения')
//         .label('Номер основного отделения'),
//     }),
//     reserveOfficeCity: Yup.string().when('typeOfDelivery', {
//       is: 2,
//       then: Yup.string()
//         .required('Укажите населённый пункт резервного отделения')
//         .label('Населённый пункт резервного отделения'),
//     }),
//     reserveOfficeNumber: Yup.string().when('typeOfDelivery', {
//       is: 2,
//       then: Yup.string()
//         .required('Укажите резервное отделение')
//         .label('Номер резервного отделения'),
//     }),
//   }),
// ]
