/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {Formik, Form, FormikValues} from 'formik'
import {AddressShipment} from './AddressShipment'
import {PostShipment} from './PostShipment'
import {Modal} from 'react-bootstrap'
import {
  IShipmentAddress,
  IShipmentAddressForm,
} from '../../../../../../_metronic/helpers/custom/tsHelpers/profileHelpers'
import {editShipmentAddressSchema} from '../../../../../../_metronic/helpers/custom/yup/yup-schemas/addresses'
import {SearchQueryProps} from '../Filter'
import {useQueryClient, useMutation} from 'react-query'
import {locationsApi} from '../../../../../../_metronic/helpers/custom/api/locationsApi'
import {useIntl} from 'react-intl'

interface Props extends SearchQueryProps {
  setIsOpen: (bool: boolean) => void
  isOpen: boolean
  dataForEditing: IShipmentAddress
  id: number | string
  tempType: boolean
  apiMutation: {
    apiRequest: (data: any, id?: string | number) => Promise<any>
    toInvalidate: string
    id?: string | number
  }
}

const EditShipmentAddress = ({
  setIsOpen,
  isOpen,
  id,
  dataForEditing,
  tempType,
  apiMutation,
}: Props) => {
  const {address, mainOffice, reserveOffice, city, post, mainAddress} = dataForEditing

  const dynamicInits = post
    ? {
        mainOfficeCity: mainOffice?.city.id || '',
        mainOfficeNumber: mainOffice?.id,
        reserveOfficeCity: reserveOffice?.city.id || '',
        reserveOfficeNumber: reserveOffice?.id,
      }
    : {
        houseNumber: address?.houseNumber || '',
        houseLetter: address?.houseLetter || '',
        street: address?.street.id || '',
        premisesType: address?.apartment ? 'apartment' : 'office',
        apartment: address?.apartment || '',
        office: address?.office || '',
      }

  const inits: IShipmentAddressForm = {
    typeOfDelivery: post ? 2 : 1,
    city: city.id,
    mainAddress: mainAddress === true,
    ...dynamicInits,
  }

  const [initValues] = useState<IShipmentAddressForm>(inits)
  const [submittedValues, setSubmittedValues] = useState<IShipmentAddress>(dataForEditing)
  const [submittedForm, setSubmittedForm] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const intl = useIntl()
  const queryClient = useQueryClient()

  const editAddressMutation = useMutation<any, Error>(
    async () => {
      if (apiMutation.toInvalidate === 'receiverAddresses') {
        return await apiMutation.apiRequest({...submittedValues, receiver_id: apiMutation.id})
      } else {
        return await apiMutation.apiRequest(submittedValues)
      }
    },
    {
      onSuccess: () => {
        if (apiMutation.toInvalidate === 'receiverAddresses') {
          queryClient.invalidateQueries('receiverAddresses')
        } else {
          queryClient.invalidateQueries('profileAddresses')
        }

        setIsOpen(false)
      },
      onMutate: () => {},
      onError: (error) => {
        console.log(error)
      },
      onSettled: () => {
        setIsLoading(false)
      },
    }
  )

  const closeModal = () => {
    setIsOpen(false)
  }

  const handleSubmit = async (values: IShipmentAddressForm, actions: FormikValues) => {
    console.log('values', values)
    setIsLoading(true)

    let dataToSend

    if (+values.typeOfDelivery === 1) {
      const {apartment, city, houseLetter, houseNumber, mainAddress, office, street, premisesType} =
        values

      const cityObj = await locationsApi.getCityById(city)
      const streetObj = await locationsApi.getStreetById(street || '', city)

      dataToSend = {
        id,
        post: false,
        //@ts-ignore

        mainAddress: mainAddress[0] == 1 || mainAddress == 1 ? true : false,
        city: {name: cityObj.name.split(', ')[0], id: cityObj.id},
        region: cityObj.region,
        address: {
          apartment: apartment && premisesType === 'apartment' ? apartment : null,
          houseLetter: houseLetter || null,
          houseNumber: houseNumber,
          office: office && premisesType === 'office' ? office : null,
          street: streetObj ? {id: streetObj.ref, name: streetObj.name} : {id: null, name: street},
        },
        mainOffice: null,
        reserveOffice: null,
      }
    } else if (+values.typeOfDelivery === 2) {
      const {
        mainAddress,
        mainOfficeCity,
        mainOfficeNumber,
        reserveOfficeCity,
        reserveOfficeNumber,
      } = values

      const mainCityObj = await locationsApi.getCityById(mainOfficeCity || '')
      const resCityObj = await locationsApi.getCityById(reserveOfficeCity || '')

      dataToSend = {
        id,
        post: true,
        //@ts-ignore
        mainAddress: mainAddress[0] == 1 || mainAddress == 1 ? true : false,
        city: {name: mainCityObj.name.split(', ')[0], id: mainCityObj.id},
        region: mainCityObj.region,
        address: null,
        mainOffice: {
          city: {name: mainCityObj.name.split(', ')[0], id: mainCityObj.id},
          id: mainOfficeNumber,
        },
        reserveOffice: {
          city: {name: resCityObj.name.split(', ')[0], id: resCityObj.id},
          id: reserveOfficeNumber,
        },
      }
    }
    // @ts-ignore
    setSubmittedValues(dataToSend)
    setSubmittedForm(true)

    actions.resetForm()
  }

  useEffect(() => {
    if (submittedForm) {
      editAddressMutation.mutate()
    }
  }, [submittedValues])

  return (
    <Modal
      className='modal fade'
      show={isOpen}
      dialogClassName='modal-dialog-centered mw-1000px h-auto'
      id='kt_modal_edit_sh_address'
      aria-hidden='true'
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h2>Изменить адрес доставки</h2>

          <div
            className='btn btn-sm btn-icon btn-active-color-primary'
            data-bs-dismiss='modal'
            onClick={closeModal}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
          </div>
        </div>
        <div className='modal-body py-lg-10 px-0 px-lg-10'>
          <div className='flex-row-fluid py-lg-5 px-lg-15'>
            <Formik
              validationSchema={editShipmentAddressSchema(intl)}
              initialValues={initValues}
              enableReinitialize={true}
              onSubmit={handleSubmit}
              validateOnBlur={false}
              validateOnChange={false}
            >
              {({values, setFieldValue, errors}) => (
                <Form className='form' noValidate id='kt_modal_edit_sh_address_form'>
                  {/* {JSON.stringify(errors)} */}

                  <div className='w-100'>
                    {' '}
                    <>
                      {+values.typeOfDelivery === 1 && (
                        <AddressShipment
                          setFieldValue={setFieldValue}
                          values={values}
                          editing={true}
                          dataForEditing={dataForEditing}
                        />
                      )}
                      {+values.typeOfDelivery === 2 && (
                        <PostShipment
                          values={values}
                          editing={true}
                          dataForEditing={dataForEditing}
                        />
                      )}
                    </>
                  </div>
                  <div className='d-flex flex-stack pt-10'>
                    <div>
                      <button
                        type='submit'
                        className='btn btn-lg btn-primary me-3'
                        disabled={isLoading}
                      >
                        {isLoading === true ? (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            Подождите...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        ) : (
                          <span className='indicator-label'>Сохранить</span>
                        )}
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export {EditShipmentAddress}
