import {Field} from 'formik'
import {ISetFieldValue} from '../../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {InputTemplate} from '../../../../../modules/custom/form-elements/InputTemplate'
import {AddBuyoutForm, IBuyoutFormProds} from '../../../core/_models/_buyout-models'
import {InspectionConditions} from './InspectionConditions'
import {FormattedMessage, useIntl} from 'react-intl'
import {getBuyoutCategories} from '../../../core/_requests'
import {CategoriesWrapper} from '../../../../../modules/custom/page-items/categories/CategoriesWrapper'
import {TrackTarrifs} from '../../../../tracks/tabs/TrackTarrifs'
import {Notification} from '../../../../../modules/custom/Notification'
import {checkProductForCommercialValues} from '../../../../../../_metronic/helpers/custom/funcs/checkForCommercial'

interface Props {
  item: IBuyoutFormProds
  index: number
  arrayHelpers: any
  prods: IBuyoutFormProds[]
  setFieldValue: ISetFieldValue
  values: AddBuyoutForm
}

export function BuyoutGoodsItem({item, index, arrayHelpers, prods, setFieldValue, values}: Props) {
  const intl = useIntl()

  const isCommercialProduct = checkProductForCommercialValues(item)

  const weightIsNeeded = item.name.require_weight === 1

  return (
    <>
      <div className='d-flex flex-column fv-row w-100'>
        {' '}
        <InputTemplate
          inputName={`prods.${index}.link`}
          type='text'
          required={true}
          title={intl.formatMessage({id: 'BUYOUTS.FORM.GOODS_LINK'})}
          value={item.link}
          custom={
            <Field
              className={`form-control form-control-lg form-control-solid`}
              name={`prods.${index}.link`}
              value={item.link}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFieldValue(`prods.${index}.link`, e.target.value.replace(/\s/g, ''))
              }
            />
          }
        />
      </div>
      <div className='d-flex flex-column fv-row w-100'>
        {' '}
        <InputTemplate
          inputName={`prods.${index}.alt_link`}
          type='text'
          title={intl.formatMessage({id: 'BUYOUTS.FORM.GOODS_SUBST_LINK'})}
          value={item.alt_link || ''}
          custom={
            <Field
              className={`form-control form-control-lg form-control-solid`}
              name={`prods.${index}.alt_link`}
              value={item.alt_link || ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFieldValue(`prods.${index}.alt_link`, e.target.value.replace(/\s/g, ''))
              }
            />
          }
        />
      </div>
      <div className='d-flex flex-column fv-row w-100'>
        <CategoriesWrapper
          inputName={{
            groups: `prods.${index}.group`,
            categories: `prods.${index}.name`,
            brands: `prods.${index}.brand`,
          }}
          setFieldValue={setFieldValue}
          selectValues={{groups: item.group, categories: item.name, brands: item.brand}}
          apiRequest={getBuyoutCategories}
        />

        {item.group.value === 5 && (
          <InputTemplate
            inputName={`prods.${index}.repack`}
            type='checkbox'
            checkboxProps={{
              checked: item.repack || false,
              disabled: false,
              setFieldValue: setFieldValue,
              value: 1,
              inputName: `prods.${index}.repack`,
              type: 'checkbox',
            }}
            title={intl.formatMessage({id: 'TRACKS.ADD_MODAL.FORM.GOODS_ITEM.REMOVE_SHOE_BOXES'})}
          />
        )}
      </div>
      <div className='d-flex flex-gutter w-100'>
        <InputTemplate
          inputName={`prods.${index}.sku`}
          fieldWidth='w-50'
          type='text'
          title={intl.formatMessage({id: 'BUYOUTS.FORM.ART_LABEL'})}
          value={item.sku || ''}
        />
        <InputTemplate
          inputName={`prods.${index}.color`}
          fieldWidth='w-50'
          type='text'
          title={intl.formatMessage({id: 'BUYOUTS.FORM.COLOR_LABEL'})}
          value={item.color || ''}
        />
      </div>{' '}
      <div className='d-flex flex-gutter w-50'>
        <InputTemplate
          inputName={`prods.${index}.size`}
          type='text'
          title={intl.formatMessage({id: 'BUYOUTS.FORM.SIZE_LABEL'})}
          value={item.size || ''}
        />
      </div>
      <div className='d-flex flex-gutter w-100'>
        <InputTemplate
          inputName={`prods.${index}.qty`}
          fieldWidth='w-50'
          type='number'
          required={true}
          title={intl.formatMessage({id: 'TRACKS.ADD_MODAL.FORM.GOODS_ITEM.QTY'})}
          value={item.qty}
          handleChange={(val: number) =>
            setFieldValue(`prods.${index}.total`, (val * +item.cost).toFixed())
          }
        />

        <InputTemplate
          inputName={`prods.${index}.cost`}
          type='number'
          fieldWidth='w-50'
          required={true}
          title={intl.formatMessage({id: 'TRACKS.ADD_MODAL.FORM.GOODS_ITEM.COST'})}
          addSymbol={'$'}
          value={item.cost}
          handleChange={(val: number) =>
            setFieldValue(`prods.${index}.total`, (val * +item.qty).toFixed())
          }
        />
      </div>{' '}
      <div className='d-flex flex-gutter w-100'>
        {weightIsNeeded === true && (
          <InputTemplate
            inputName={`prods.${index}.weight`}
            type='number'
            fieldWidth='w-50'
            required={true}
            title={intl.formatMessage({id: 'TRACKS.ADD_MODAL.FORM.GOODS_ITEM.WEIGHT'})}
            addSymbol={'кг'}
            value={item.weight}
          />
        )}{' '}
        <InputTemplate
          inputName={`prods.${index}.total`}
          addSymbol={'$'}
          type='number'
          required={true}
          title={intl.formatMessage({id: 'TRACKS.ADD_MODAL.FORM.GOODS_ITEM.TOTAL_COST'})}
          value={item.total}
          fieldWidth='w-50'
          handleChange={(val: number) => {
            const newVal = (val / +item.qty).toFixed(1)
            setFieldValue(`prods.${index}.cost`, newVal.replace(/\.0+$/, ''))
          }}
        />
      </div>{' '}
      <InputTemplate
        inputName={`prods.${index}.check_control`}
        title={intl.formatMessage({id: 'GENERAL.ORDER_INSPECTION'})}
        type='checkbox'
        checkboxProps={{
          checked: item.check_control,
          disabled: false,
          setFieldValue: setFieldValue,
          value: 1,
          inputName: `prods.${index}.check_control`,
          type: 'checkbox',
        }}
        containerMarginBottom={false}
      />{' '}
      {item.check_control === true && (
        <>
          <InspectionConditions />
          <InputTemplate
            type='textarea'
            inputName={`prods.${index}.check_control_desc`}
            title={intl.formatMessage({id: 'TRACKS.ADD_MODAL.FORM.INSPECTION_DESC_TITLE'})}
            required={true}
          />
        </>
      )}
      {isCommercialProduct === true && (
        <div className='d-flex justify-content-center px-auto'>
          <Notification
            noteText={
              <>
                <h3 className='fs-6'>
                  <FormattedMessage id='BUYOUTS.ADD_MODAL.FORM.COMMERCIAL.TITLE' />
                </h3>
                <TrackTarrifs delivery_type={+values.delivery_type} parcel_type={1} />
              </>
            }
          />
        </div>
      )}
      {prods.length > 1 && (
        <div>
          {' '}
          <button
            className='btn btn-sm btn-secondary d-flex align-items-center'
            type='button'
            onClick={() => arrayHelpers.remove(index)}
          >
            <FormattedMessage id='GENERAL.BUTTONS.DELETE' />
          </button>
        </div>
      )}
    </>
  )
}
