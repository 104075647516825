/* eslint-disable jsx-a11y/anchor-is-valid */
import {useListView} from '../../../core/ListViewProvider'
import {TracksListToolbar} from './TracksListToolbar'
import {TracksListGrouping} from './TracksListGrouping'
import {TracksListSearchComponent} from './TracksListSearchComponent'
import {useState} from 'react'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {useMutation} from 'react-query'
import {setAllTracks} from '../../../core/_requests'
import {useQueryResponse} from '../../../core/QueryResponseProvider'
import {ActionConfirmation} from '../../../../../modules/custom/modals/ActionConfirmation'
import {Portal} from '../../../../../../_metronic/partials'
import {FormattedMessage, useIntl} from 'react-intl'

const TracksListHeader = () => {
  const {selected} = useListView()
  const intl = useIntl()
  const [isLoading, setIsLoading] = useState(false)
  const [warehouseTracks, setWarehouseTracks] = useState(false)
  const [unpackedTracks, setUnpackedTracks] = useState(false)

  const {refetch} = useQueryResponse()
  const [tracksToSet, setTracksToSet] = useState(2)
  const [tracksStatusToSet, setTracksStatusToSet] = useState(2)

  const [confirmModalOpen, setConfirmModalOpen] = useState(false)

  const setAllTracksMutation = useMutation(setAllTracks, {
    onMutate: () => {
      setIsLoading(true)
    },
    onSuccess: (data) => {
      if (data) {
        refetch()
      }
    },

    onError: (error) => {
      console.log(error)
    },
    onSettled: () => {
      setIsLoading(false)
      setConfirmModalOpen(false)
    },
  })

  return (
    <div className='card-header border-0 pt-6 justify-content-between'>
      {confirmModalOpen && (
        <Portal
          children={
            <ActionConfirmation
              actionName={`${
                tracksStatusToSet === 0
                  ? intl.formatMessage({id: 'TRACKS.PAGE.LIST.HEADER.PROH_ALL.ACTION'})
                  : intl.formatMessage({id: 'TRACKS.PAGE.LIST.HEADER.ALLOW_ALL.ACTION'})
              } ${
                tracksToSet === 0
                  ? intl.formatMessage({id: 'GENERAL.DELIVERY_TYPE_NAME.AVIA'})
                  : intl.formatMessage({id: 'GENERAL.DELIVERY_TYPE_NAME.SEA'})
              }`}
              setModalOpen={setConfirmModalOpen}
              handleAction={() => setAllTracksMutation.mutate({tracksToSet, tracksStatusToSet})}
              isOpen={confirmModalOpen}
            />
          }
          className='modal-root'
        />
      )}
      {warehouseTracks === true ? (
        <>
          <div className='d-flex flex-column'>
            {' '}
            <a
              href='#'
              className='btn btn-sm btn-active-color-success text-start ps-0'
              onClick={() => {
                setConfirmModalOpen(true)
                setTracksToSet(0)
                setTracksStatusToSet(1)
              }}
            >
              <KTSVG path='/media/icons/flight.svg' className='svg-icon-primary svg-icon-3 me-1' />
              <FormattedMessage id='TRACKS.PAGE.LIST.HEADER.ALLOW_ALL.AVIA' />
            </a>
            <a
              href='#'
              className='btn btn-sm btn-active-color-success text-start ps-0'
              onClick={() => {
                setConfirmModalOpen(true)
                setTracksToSet(1)
                setTracksStatusToSet(1)
              }}
            >
              <KTSVG path='/media/icons/anchor.svg' className='svg-icon-primary svg-icon-3 me-1' />
              <FormattedMessage id='TRACKS.PAGE.LIST.HEADER.ALLOW_ALL.SEA' />
            </a>
          </div>
          <div className='d-flex flex-column'>
            {' '}
            <a
              href='#'
              className='btn btn-sm btn-active-color-danger text-start ps-0'
              onClick={() => {
                setConfirmModalOpen(true)
                setTracksToSet(0)
                setTracksStatusToSet(0)
              }}
            >
              <KTSVG path='/media/icons/flight.svg' className='svg-icon-primary svg-icon-3 me-1' />
              <FormattedMessage id='TRACKS.PAGE.LIST.HEADER.PROH_ALL.AVIA' />
            </a>
            <a
              href='#'
              className='btn btn-sm btn-active-color-danger text-start ps-0'
              onClick={() => {
                setConfirmModalOpen(true)
                setTracksToSet(1)
                setTracksStatusToSet(0)
              }}
            >
              <KTSVG path='/media/icons/anchor.svg' className='svg-icon-primary svg-icon-3 me-1' />
              <FormattedMessage id='TRACKS.PAGE.LIST.HEADER.PROH_ALL.SEA' />
            </a>
          </div>
        </>
      ) : (
        <TracksListSearchComponent />
      )}

      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? (
          <TracksListGrouping />
        ) : (
          <TracksListToolbar
            warehouseTracks={warehouseTracks}
            setWarehouseTracks={setWarehouseTracks}
            unpackedTracks={unpackedTracks}
            setUnpackedTracks={setUnpackedTracks}
          />
        )}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {TracksListHeader}
