import {useListView} from '../../../core/ListViewProvider'
import {ParcelsListToolbar} from './ParcelsListToolbar'
import {ParcelsListGrouping} from './ParcelsListGrouping'
import {ParcelsListSearchComponent} from './ParcelsListSearchComponent'

const ParcelsListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6 justify-content-between'>
      <ParcelsListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <ParcelsListGrouping /> : <ParcelsListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {ParcelsListHeader}
