import {
  IBuyoutFormProds,
  IBuyoutProds,
} from '../../../../app/pages/buyouts/core/_models/_buyout-models'
import {
  ITrackFormProds,
  ITrackProds,
} from '../../../../app/pages/tracks/core/_models/_tracks-models'

export const reformTrackProducts = (prods: ITrackFormProds[]): ITrackProds[] => {
  const reformed = prods.map(({name, qty, cost, total, brand, weight, repack}) => {
    return {
      name: name.label,
      qty: qty,
      cost: cost,
      total: total,
      category: name.value,
      brand: brand.id || null,
      wgt: name.require_weight === 1 ? weight : null,
      repack: repack === true ? 1 : 0,
    }
  })
  // @ts-ignore

  return reformed
}

export const reformOneTrackProduct = (prod: ITrackFormProds): ITrackProds => {
  const {name, qty, cost, total, id, brand, weight, repack} = prod

  const reformed = {
    name: name.label,
    qty: qty,
    cost: cost,
    total: total,
    category: name.value,
    id: id || null,
    brand: brand.id || null,
    wgt: name.require_weight === 1 ? weight : null,
    repack: repack === true ? 1 : 0,
  }
  // @ts-ignore
  return reformed
}

export const reformBuyoutProducts = (prods: IBuyoutFormProds[]): IBuyoutProds[] => {
  const reformed = prods.map(
    ({
      name,
      qty,
      cost,
      total,
      check_control,
      check_control_desc,
      sku,
      color,
      size,
      link,
      alt_link,
      brand,
      repack,
      weight,
    }) => {
      return {
        name: name.label,
        qty,
        cost,
        total,
        category: name.value,
        check_control: check_control === true ? 1 : 0,
        check_control_desc: check_control_desc || '',
        link,
        alt_link: alt_link || null,
        sku: sku || null,
        color: color || null,
        size: size || null,
        unavaliable: 0,
        brand: brand.id || null,
        wgt: name.require_weight === 1 ? weight : null,
        repack: repack === true ? 1 : 0,
      }
    }
  )
  // @ts-ignore
  return reformed
}

export const reformOneBuyoutProduct = (prod: IBuyoutFormProds): IBuyoutProds => {
  const {
    name,
    qty,
    cost,
    total,
    check_control,
    check_control_desc,
    sku,
    color,
    size,
    link,
    alt_link,
    id,
    brand,
    weight,
    repack,
  } = prod

  const reformed = {
    name: name.label,
    qty: qty,
    cost: cost,
    total: total,
    category: name.value,
    check_control: check_control === true ? 1 : 0,
    check_control_desc: check_control_desc || '',
    link,
    alt_link: alt_link || null,
    sku: sku || null,
    color: color || null,
    size: size || null,
    unavaliable: 0,
    id: id || null,
    brand: brand.id || null,
    wgt: name.require_weight === 1 ? weight : null,
    repack: repack === true ? 1 : 0,
  }
  // @ts-ignore

  return reformed
}
