import {Notification} from '../../Notification'
import {ISetFieldValue} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {ImgThumb} from '../../images/ImgThumb'
import {ModalNotification} from '../../modals/ModalNotification'
import {FormattedMessage} from 'react-intl'

interface Props {
  inputName: string
  title: string
  setFieldValue: ISetFieldValue
  inputId: string
  loading: boolean
  linkEndpoint: string // e.g. '/passport'
  uploadedFile?: {base: string; link: string}
  uploadFunction?: (e: React.ChangeEvent<HTMLInputElement>, setFieldValue: ISetFieldValue) => void
  simpleDeleteFunction?: (val: {base_url: string; filename: string}) => void
  deleteFunction?: (setFieldValue: ISetFieldValue) => void
  placeholder?: string
  notification?: {bool: boolean; text?: string; borderTop?: boolean}
  required?: boolean
}

export function ImageFileInput({
  title,
  inputName,
  inputId,
  uploadedFile,
  loading,
  linkEndpoint,
  setFieldValue,
  uploadFunction = () => {
    return
  },
  deleteFunction,
  simpleDeleteFunction,
  placeholder,
  notification = {bool: false},
  required = false,
}: Props) {
  return (
    <div className='row mb-2'>
      <label
        className={`col-lg-4 p-0 col-form-label ${
          required === true ? 'required' : ''
        } fw-bold fs-6`}
      >
        {title}{' '}
      </label>
      <div className='col-lg-8'>
        <div className='row '>
          <div className='col-lg-6 fv-row mb-6'>
            {' '}
            <input
              name={inputName}
              className='form-control form-control-lg form-control-solid ImageFileInput'
              type='file'
              placeholder={placeholder ? placeholder : ''}
              id={inputId}
              style={{display: 'none'}}
              onChange={(e) => uploadFunction(e, setFieldValue)}
            />
            <label className='btn btn-lg btn-secondary btn-active-primary' htmlFor={inputId}>
              {uploadedFile && uploadedFile.link !== '' ? (
                <FormattedMessage id='GENERAL.BUTTONS.UPDATE_FILE' />
              ) : (
                <FormattedMessage id='GENERAL.BUTTONS.CHOOSE_FILE' />
              )}
            </label>
          </div>
          <div className='col-lg-6 fv-row mb-6 position-relative ProfileOverview__passport-image'>
            <label htmlFor={!uploadedFile || uploadedFile.link === '' ? inputId : ''}>
              <ImgThumb
                base={false}
                link={uploadedFile ? uploadedFile.base + uploadedFile.link : ''}
                alt={title}
                loading={loading}
                linkEndpoint={linkEndpoint}
              />
              {uploadedFile && uploadedFile.link !== '' && (
                <button
                  className='btn btn-icon btn-bg-white btn-active-color-primary btn-sm ProfileOverview__delete-image'
                  onClick={() => {
                    if (simpleDeleteFunction) {
                      simpleDeleteFunction({base_url: '', filename: ''})
                    } else if (deleteFunction) {
                      deleteFunction(setFieldValue)
                    } else {
                      return
                    }
                  }}
                >
                  <ModalNotification
                    svg='/media/icons/duotune/general/gen027.svg'
                    addSvgClass='svg-icon-3'
                    addClass='ModalNotification__button-position'
                    children={
                      <div className='fs-7 fw-normal text-black'>
                        <FormattedMessage id='GENERAL.BUTTONS.DELETE_IMAGE' />
                      </div>
                    }
                  />
                </button>
              )}
            </label>
          </div>
        </div>{' '}
      </div>{' '}
      {notification.bool === true && (
        <Notification noteText={notification.text || ''} borderTop={notification.borderTop} />
      )}
    </div>
  )
}
