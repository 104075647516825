import {FieldArray} from 'formik'
import {KTSVG} from '../../../../../_metronic/helpers'
import {profileNumber} from '../../../../../_metronic/helpers/custom/tsHelpers/profileHelpers'
import InputMask from 'react-input-mask'
import {
  IPhoneNumberError,
  ISetFieldValue,
} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {ProfileContactItem} from './ProfileContactItem'
import {Dispatch, SetStateAction, useEffect, useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'

interface Props {
  phoneNumbers: profileNumber[]
  setFieldValue: ISetFieldValue
  setDisableSubmit: Dispatch<SetStateAction<boolean>>
}
export function ProfilePhoneNumbers({phoneNumbers, setFieldValue, setDisableSubmit}: Props) {
  const intl = useIntl()

  useEffect(() => {
    if (phoneNumbers.some((item) => item.success === false)) {
      setDisableSubmit(true)
    } else {
      setDisableSubmit(false)
    }
  }, [phoneNumbers])

  return (
    <div className='row mb-6'>
      <label className='col-lg-4 col-form-label required fw-bold fs-6'>
        <FormattedMessage id='PROFILE.PAGE.CONTACTS.NUMBERS' />
      </label>
      <div className='col-lg-8'>
        <div className='d-flex flex-column mb-10'>
          {' '}
          <div className='w-100'>
            <FieldArray
              name='phoneNumbers'
              render={(arrayHelpers) => (
                <div className='d-flex flex-column flex-col-gutter'>
                  {phoneNumbers.map((item, index) => (
                    <ProfileContactItem
                      arrayHelpers={arrayHelpers}
                      key={index}
                      index={index}
                      item={item}
                      phoneNumbers={phoneNumbers}
                      setFieldValue={setFieldValue}
                    />
                  ))}

                  {phoneNumbers.length < 3 && (
                    <div className=''>
                      <button
                        className='btn btn-sm btn-secondary d-flex align-items-center'
                        type='button'
                        onClick={() => arrayHelpers.push({phoneNumberType: '', phoneNumber: ''})}
                      >
                        <span className='me-2'>
                          <FormattedMessage id='GENERAL.BUTTONS.ADD' values={{item: null}} />
                        </span>
                        <KTSVG
                          path='/media/icons/duotune/general/gen041.svg'
                          className='svg-icon-1x svg-icon-primary d-block my-2'
                        />
                      </button>
                    </div>
                  )}
                </div>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
