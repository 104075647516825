import {Route, Routes, Outlet, Navigate, useLocation} from 'react-router-dom'
import {TracksListWrapper} from './tracks-list/TracksList'
import TrackPage from './track-page/TrackPage'
import {generalApi} from '../../../_metronic/helpers/custom/api/generalApi'
import {useQuery} from 'react-query'
import {getRecentInvoices} from './core/_requests'
import {useIntl} from 'react-intl'

const TracksPage = () => {
  const location = useLocation()
  const {pathname} = location
  const intl = useIntl()

  const {data: receiversListData} = useQuery('receiversList', generalApi.getReceiversList)

  const {data} = useQuery('tariffsList', generalApi.getTariffs)

  const recentInvoicesData: any = useQuery('recentInvoices', getRecentInvoices)

  const pathnameValue = pathname.includes('/archived-tracks')
    ? {
        defaultLink: '/archived-tracks/list',
        pageTitle: intl.formatMessage({id: 'TRACKS.PAGE.PAGE_HEADING_ARCH'}),
        trackPagePath: 'info/*',
        creationIsAllowed: false,
      }
    : {
        defaultLink: '/tracks/list',
        pageTitle: intl.formatMessage({id: 'TRACKS.PAGE.PAGE_HEADING'}),
        trackPagePath: 'edit/*',
        creationIsAllowed: true,
      }

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list'
          element={
            <>
              <TracksListWrapper />
            </>
          }
        />

        {pathnameValue.creationIsAllowed === true && (
          <Route path='create' element={<Navigate to='/tracks/list' state={location.pathname} />} />
        )}

        <Route
          path={pathnameValue.trackPagePath}
          element={
            <TrackPage
              id={+location.pathname.split('/')[location.pathname.split('/').length - 1]}
            />
          }
        />
      </Route>
      <Route index element={<Navigate to={pathnameValue.defaultLink} />} />
    </Routes>
  )
}

export default TracksPage
