/* eslint-disable jsx-a11y/anchor-is-valid */
import {Field, ErrorMessage, useFormikContext} from 'formik'
import {profileNumber} from '../../../../../_metronic/helpers/custom/tsHelpers/profileHelpers'
import {
  IPhoneNumberError,
  ISetFieldValue,
} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {KTSVG} from '../../../../../_metronic/helpers'
import InputMask from 'react-input-mask'
import {Dispatch, SetStateAction, useEffect, useState} from 'react'
import {profileApi} from '../../../../../_metronic/helpers/custom/api/profileApi'
import {FormattedMessage, useIntl} from 'react-intl'

interface Props {
  index: number
  item: profileNumber
  setFieldValue: ISetFieldValue
  phoneNumbers: profileNumber[]
  arrayHelpers: any
}
function ProfileContactItem({index, item, phoneNumbers, setFieldValue, arrayHelpers}: Props) {
  // console.log('phoneNumbers', phoneNumbers)
  const intl = useIntl()

  const [phoneNumberStatus, setPhoneNumberStatus] = useState({success: true, err: ''})

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const res = await profileApi.checkPhone(e.target.value)

    setPhoneNumberStatus(res)
    setFieldValue(`phoneNumbers.${index}.success`, res.success)
  }
  return (
    <div className='d-flex ProfileContactItem'>
      <div className='fv-row'>
        {' '}
        <div className='d-flex'>
          <Field
            key={index}
            name={`phoneNumbers.${index}.phoneNumber`}
            render={() => (
              <InputMask
                name={`phoneNumbers.${index}.phoneNumber`}
                type='tel'
                maskChar={null}
                mask='(999) 999-99-99'
                className={`form-control form-control-lg form-control-solid`}
                value={item.phoneNumber}
                onChange={(e) => {
                  setFieldValue(`phoneNumbers.${index}.phoneNumber`, e.target.value)

                  handleChange(e)
                }}
              />
            )}
          />
          {phoneNumbers.length > 1 && (
            <button
              className='btn ProfileContactItem__remove-button'
              type='button'
              onClick={() => arrayHelpers.remove(index)}
            >
              <KTSVG
                path='/media/icons/duotune/general/gen040.svg'
                className='svg-icon-1x svg-icon-primary d-block my-2 CreateDeliveryType__button-icon'
              />
            </button>
          )}
        </div>{' '}
        {phoneNumberStatus.err && item.phoneNumber !== '' && (
          <div className='text-danger'>{phoneNumberStatus.err}</div>
        )}
        {!phoneNumberStatus.err && phoneNumberStatus.success === false && (
          <div className='text-danger'>
            <FormattedMessage id='GENERAL.ERRORS.USED_PHONE_NUMBER' />
          </div>
        )}
        {/* {phoneNumbers.find((number) => number.phoneNumber === item.phoneNumber) && (
          <div className='text-danger'>Номер уже добавлен</div>
        )} */}
        <div className='text-danger'>
          <ErrorMessage name={`phoneNumbers.${index}.phoneNumber`} />
        </div>
      </div>
    </div>
  )
}

export {ProfileContactItem}
