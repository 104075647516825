/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useRef, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {Formik, Form, FormikValues} from 'formik'
import {useMutation} from 'react-query'
import {StepperComponent} from '../../../../_metronic/assets/ts/components'
import {AddBuyoutForm} from '../core/_models/_buyout-models'
import {InputTemplate} from '../../../modules/custom/form-elements/InputTemplate'
import {createBuyoutSchema} from '../core/yup/buyout'
import {CheckboxSwitcher} from '../../../modules/custom/form-elements/checkboxes/CheckboxSwitcher'
import {SettingsRadio} from '../../../modules/custom/form-elements/radios/SettingsRadio'
import {BuyoutGoods} from './tabs/goods/BuyoutGoods'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {addBuyout} from '../core/_requests'
import {CreateLoading} from '../../../modules/custom/responseHandlers/CreateLoading'
import {CreateError} from '../../../modules/custom/responseHandlers/CreateError'
import {reformBuyoutProducts} from '../../../../_metronic/helpers/custom/funcs/reformProducts'
import {FormattedMessage, useIntl} from 'react-intl'
import {useAuth} from '../../../modules/auth'
import {useListView} from '../core/ListViewProvider'
import {createChatFromNewBuyoutComment} from '../../../modules/chat/_requests'
import {Notification} from '../../../modules/custom/Notification'

const CreateBuyout = () => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const {refetch} = useQueryResponse()
  const intl = useIntl()
  const {currentUser} = useAuth()
  const {setItemIdForUpdate} = useListView()

  const goodsFields = [
    {
      group: {value: '', label: ''},
      name: {label: '', value: '', brands: []},
      brand: {label: '', value: ''},
      qty: 1,
      cost: '',
      total: '',
      repack: false,
      check_control: false,
      check_control_desc: '',
      link: '',
      alt_link: '',
      sku: '',
      color: '',
      size: '',
      unavaliable: 0,
      weight: '',
    },
  ]

  const inits: AddBuyoutForm = {
    orderInspection: false,
    inspectionDescription: '',
    dispatch: false,
    delivery_type: currentUser && currentUser.settings ? +currentUser?.settings?.delivery_type : 0,
    conditionsAccepted: false,
    prods: goodsFields,
  }

  const [currentSchema, setCurrentSchema] = useState(createBuyoutSchema(intl)[0])
  const [initValues] = useState<AddBuyoutForm>(inits)

  const [createResponse, setCreateResponse] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const createBuyoutMutation = useMutation(addBuyout, {
    onMutate: () => {
      setIsLoading(true)
      setCreateResponse('loading')
    },
    onSuccess: (data) => {
      setCreateResponse('success')
      if (data?.description) {
        createChatFromNewBuyoutComment({
          object_id: data.id,
          type: 'buyout',
          message: data.description,
        })
      }
      setItemIdForUpdate(undefined)
    },

    onError: (error) => {
      setCreateResponse('error')
      console.log(error)
    },
    onSettled: () => {
      setIsLoading(false)
      refetch()
    },
  })

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()

    setCurrentSchema(createBuyoutSchema(intl)[stepper.current.currentStepIndex - 1])
  }

  const submitStep = (values: AddBuyoutForm, actions: FormikValues) => {
    console.log('values', values)

    if (!stepper.current) {
      return
    }

    setCurrentSchema(createBuyoutSchema(intl)[stepper.current.currentStepIndex])

    if (stepper.current.currentStepIndex === stepper.current.totatStepsNumber) {
      stepper.current?.goNext()

      const {delivery_type, dispatch, prods, description, discountNumber, conditionsAccepted} =
        values
      const repackFootwear = prods.some(({repack}) => repack === true)

      const buyoutData = {
        type_delive: delivery_type,
        dispatch: dispatch === true ? 0 : 1,
        prods: reformBuyoutProducts(prods),
        description: description ? description : undefined,
        repack: repackFootwear === true ? 1 : 0,
        coupon: discountNumber || null,
        conditionsAccepted: conditionsAccepted === true ? 1 : 0,
      }

      console.log('buyoutDataToSend', buyoutData)

      createBuyoutMutation.mutate(buyoutData)
      stepper.current?.goNext()
    } else if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      stepper.current.goNext()
    } else {
      stepper.current.goto(1)
      actions.resetForm()
    }
  }

  const goToStep = (number: number) => {
    if (stepper.current && stepper.current.currentStepIndex > number) {
      setCurrentSchema(createBuyoutSchema(intl)[number - 1])
      stepper.current.goto(number)
    }
  }

  const handleStepperItemClick = (e: React.MouseEvent<HTMLSpanElement>, number: number) => {
    const stepperItem = e.currentTarget as Element

    const stepper = stepperItem.closest('.stepper-nav') as Element

    if (!stepperItem.classList.contains('current')) {
      goToStep(number)
    }
    const arrowSpan = stepperItem.querySelector('.arrow') as Element
    if (arrowSpan && stepper) {
      arrowSpan.classList.toggle('active')
      stepper.classList.toggle('active')
    }
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  return (
    <div className='py-lg-10 px-0 px-lg-10'>
      <div
        ref={stepperRef}
        className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
        id='kt_modal_create_buyout_stepper'
      >
        <div className='d-flex justify-content-md-center justify-content-xl-start flex-row-auto w-100 w-xl-300px mb-3 mb-md-0'>
          <div className='stepper-nav ps-lg-10'>
            <div
              className='stepper-item current CustomStepper'
              data-kt-stepper-element='nav'
              onClick={(e: React.MouseEvent<HTMLSpanElement>) => handleStepperItemClick(e, 1)}
            >
              <div className='stepper-wrapper'>
                <div className='stepper-icon w-40px h-40px'>
                  <span className='stepper-number'>1</span>
                </div>

                <div className='stepper-label'>
                  <h3 className='stepper-title'>
                    <FormattedMessage id='BUYOUTS.ADD_MODAL.FORM.SETTINGS_TAB' />
                  </h3>
                </div>
                <span className='arrow'>
                  <span></span>
                  <span></span>
                </span>
              </div>
            </div>
            <div
              className='stepper-item CustomStepper'
              data-kt-stepper-element='nav'
              onClick={(e: React.MouseEvent<HTMLSpanElement>) => handleStepperItemClick(e, 2)}
            >
              <div className='stepper-line h-15px h-md-40px'></div>
              <div className='stepper-wrapper'>
                <div className='stepper-icon w-40px h-40px'>
                  <span className='stepper-number'>2</span>
                </div>
                <div className='stepper-label'>
                  <h3 className='stepper-title'>
                    <FormattedMessage id='BUYOUTS.ADD_MODAL.FORM.CONTENT_TAB' />
                  </h3>
                </div>
                <span className='arrow'>
                  <span></span>
                  <span></span>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className='flex-row-fluid py-lg-5 px-lg-15'>
          {createResponse === 'loading' && <CreateLoading />}
          {createResponse === 'error' && <CreateError />}
          {createResponse !== 'error' && createResponse !== 'loading' && (
            <Formik
              validationSchema={currentSchema}
              initialValues={initValues}
              onSubmit={submitStep}
              enableReinitialize={true}
              validateOnBlur={false}
              validateOnChange={false}
            >
              {({values, setFieldValue, errors}) => (
                <Form className='form' noValidate id='kt_modal_create_buyout_form'>
                  {/* {JSON.stringify(errors)} */}
                  <div className='current' data-kt-stepper-element='content'>
                    <div className='w-100'>
                      <div className='fv-row d-flex flex-gutter '>
                        {' '}
                        <div className='d-flex flex-column me-10'>
                          <label className={`position-relative fs-6 fw-bold mb-5 required`}>
                            <FormattedMessage id='BUYOUTS.FORM.DELIVERY_TYPE_LABEL' />
                          </label>{' '}
                          <CheckboxSwitcher
                            inputName='delivery_type'
                            firstOption={intl.formatMessage({
                              id: 'GENERAL.DELIVERY_TYPE_NAME.AVIA',
                            })}
                            secondOption={intl.formatMessage({
                              id: 'GENERAL.DELIVERY_TYPE_NAME.SEA',
                            })}
                            // @ts-ignore
                            value={+values.delivery_type}
                            setFieldValue={setFieldValue}
                          />
                        </div>
                        <InputTemplate
                          addPrompt={false}
                          inputName='discountNumber'
                          type='text'
                          title={intl.formatMessage({id: 'BUYOUTS.FORM.DISCOUNT_LABEL'})}
                        />
                      </div>

                      {values.delivery_type === 1 && (
                        <div className='fv-row mb-5'>
                          <Notification
                            noteText={
                              <FormattedMessage
                                id='GENERAL.NOTIFICATIONS.SEA_TRACK_BUYOUT'
                                values={{
                                  // @ts-ignore
                                  b: (chunks: any) => <b style={{color: 'black'}}>{chunks}</b>,
                                  icon: <svg />,
                                }}
                              />
                            }
                          />
                        </div>
                      )}

                      <div className='fv-row mb-5 d-flex'>
                        <div className='me-1'>
                          {' '}
                          <SettingsRadio
                            title={intl.formatMessage({id: 'BUYOUTS.FORM.SEND_IN_FULL'})}
                            description={
                              <p className='text-muted fs-6 m-0'>
                                <FormattedMessage id='BUYOUTS.FORM.SEND_IN_FULL.EXPL' />
                              </p>
                            }
                            inputName='dispatch'
                            formValues={values}
                            checkboxIsChecked={values.dispatch}
                            value={0}
                            type='checkbox'
                            setFieldValue={setFieldValue}
                          />
                        </div>
                      </div>

                      <div className='fv-row mb-5'>
                        <InputTemplate
                          type='textarea'
                          inputName='description'
                          title={intl.formatMessage({id: 'BUYOUTS.FORM.COMMENT_LABEL'})}
                          addPrompt={false}
                          required={false}
                        />
                      </div>
                    </div>
                  </div>
                  <div data-kt-stepper-element='content'>
                    <div className='w-100'>
                      <div className='fv-row mb-10'>
                        <BuyoutGoods
                          prods={values.prods}
                          setFieldValue={setFieldValue}
                          values={values}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='d-flex flex-stack pt-10'>
                    <div className='me-2'>
                      <button
                        onClick={prevStep}
                        type='button'
                        disabled={isLoading === true}
                        className='btn btn-lg btn-light-primary me-3'
                        data-kt-stepper-action='previous'
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr063.svg'
                          className='svg-icon-4 me-1'
                        />
                        <FormattedMessage id='GENERAL.BUTTONS.BACK' />
                      </button>
                    </div>

                    <div>
                      <button
                        type='submit'
                        disabled={isLoading === true}
                        className='btn btn-lg btn-primary me-3'
                      >
                        <span className='indicator-label'>
                          {isLoading === true ? (
                            <span className='indicator-progress' style={{display: 'block'}}>
                              <FormattedMessage id='GENERAL.BUTTONS.WAIT' />

                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          ) : (
                            <>
                              {stepper.current?.currentStepIndex !==
                                stepper.current?.totatStepsNumber! && (
                                <FormattedMessage id='GENERAL.BUTTONS.NEXT' />
                              )}
                              {stepper.current?.currentStepIndex ===
                                stepper.current?.totatStepsNumber! && (
                                <FormattedMessage id='GENERAL.BUTTONS.CREATE' />
                              )}
                              <KTSVG
                                path='/media/icons/duotune/arrows/arr064.svg'
                                className='svg-icon-3 ms-2 me-0'
                              />
                            </>
                          )}
                        </span>
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </div>
    </div>
  )
}

export {CreateBuyout}
