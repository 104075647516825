import {ISetFieldValue} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {InputTemplate} from '../../../../modules/custom/form-elements/InputTemplate'
import {AddTrackForm, ITrackFormProds} from '../../core/_models/_tracks-models'
import {FormattedMessage, useIntl} from 'react-intl'
import {getTrackCategories} from '../../core/_requests'
import {CategoriesWrapper} from '../../../../modules/custom/page-items/categories/CategoriesWrapper'
import {useEffect} from 'react'
import {Notification} from '../../../../modules/custom/Notification'
import {TrackTarrifs} from '../TrackTarrifs'
import {checkProductForCommercialValues} from '../../../../../_metronic/helpers/custom/funcs/checkForCommercial'

interface Props {
  item: ITrackFormProds
  index: number
  arrayHelpers: any
  prods: ITrackFormProds[]
  setFieldValue: ISetFieldValue
  values: AddTrackForm
}

export function TrackGoodsItem({item, index, arrayHelpers, prods, setFieldValue, values}: Props) {
  const intl = useIntl()
  const isCommercialProduct = checkProductForCommercialValues(item)

  const weightIsNeeded = item.name.require_weight === 1

  useEffect(() => {
    if (isCommercialProduct === true) {
      setFieldValue('package_type', 1)
      setFieldValue('require_invoice', 1)
    } else {
      setFieldValue('package_type', 0)
      if (item.name.require_invoice === 1) {
        setFieldValue('require_invoice', 1)
      } else {
        setFieldValue('require_invoice', 0)
      }
    }
  }, [isCommercialProduct])

  // useEffect(() => {}, [item.name])

  return (
    <>
      <div className='d-flex flex-column fv-row w-100'>
        <CategoriesWrapper
          inputName={{
            groups: `prods.${index}.group`,
            categories: `prods.${index}.name`,
            brands: `prods.${index}.brand`,
          }}
          setFieldValue={setFieldValue}
          selectValues={{groups: item.group, categories: item.name, brands: item.brand}}
          apiRequest={getTrackCategories}
        />

        {item.group.value === 5 && (
          <InputTemplate
            inputName={`prods.${index}.repack`}
            type='checkbox'
            checkboxProps={{
              checked: item.repack || false,
              disabled: false,
              setFieldValue: setFieldValue,
              value: 1,
              inputName: `prods.${index}.repack`,
              type: 'checkbox',
            }}
            title={intl.formatMessage({id: 'TRACKS.ADD_MODAL.FORM.GOODS_ITEM.REMOVE_SHOE_BOXES'})}
          />
        )}
      </div>
      <div className='d-flex flex-gutter w-100'>
        <InputTemplate
          inputName={`prods.${index}.qty`}
          fieldWidth='w-50'
          type='number'
          required={true}
          title={intl.formatMessage({id: 'TRACKS.ADD_MODAL.FORM.GOODS_ITEM.QTY'})}
          value={item.qty}
          handleChange={(val: number) =>
            setFieldValue(`prods.${index}.total`, (val * +item.cost).toFixed())
          }
        />

        <InputTemplate
          inputName={`prods.${index}.cost`}
          type='number'
          fieldWidth='w-50'
          required={true}
          title={intl.formatMessage({id: 'TRACKS.ADD_MODAL.FORM.GOODS_ITEM.COST'})}
          addSymbol={'$'}
          value={item.cost}
          handleChange={(val: number) =>
            setFieldValue(`prods.${index}.total`, (val * +item.qty).toFixed())
          }
        />
      </div>{' '}
      <div className='d-flex flex-gutter w-100'>
        {weightIsNeeded === true && (
          <InputTemplate
            inputName={`prods.${index}.weight`}
            type='number'
            fieldWidth='w-50'
            required={true}
            title={intl.formatMessage({id: 'TRACKS.ADD_MODAL.FORM.GOODS_ITEM.WEIGHT'})}
            addSymbol={'кг'}
            value={item.weight}
          />
        )}{' '}
        <InputTemplate
          inputName={`prods.${index}.total`}
          addSymbol={'$'}
          type='number'
          fieldWidth='w-50'
          required={true}
          title={intl.formatMessage({id: 'TRACKS.ADD_MODAL.FORM.GOODS_ITEM.TOTAL_COST'})}
          value={item.total}
          handleChange={(val: number) =>
            setFieldValue(`prods.${index}.cost`, (val / +item.qty).toFixed(1))
          }
        />
      </div>{' '}
      {isCommercialProduct === true && (
        <div className='d-flex justify-content-center px-auto'>
          <Notification
            noteText={
              <>
                <h3 className='fs-6'>
                  <FormattedMessage id='TRACKS.ADD_MODAL.FORM.COMMERCIAL.TITLE' />
                </h3>
                <TrackTarrifs delivery_type={+values.delivery_type} parcel_type={1} />
              </>
            }
          />
        </div>
      )}
      {prods.length > 1 && (
        <div>
          {' '}
          <button
            className='btn btn-sm btn-secondary d-flex align-items-center'
            type='button'
            onClick={() => arrayHelpers.remove(index)}
          >
            <FormattedMessage id='GENERAL.BUTTONS.DELETE' />
          </button>
        </div>
      )}
    </>
  )
}
