import {Formik, Form, FormikValues} from 'formik'
import {profileSettingsSchema} from '../../core/yup/user-profile'
import {useState} from 'react'
import {profileApi} from '../../../../../_metronic/helpers/custom/api/profileApi'
import {
  ISettings,
  ISettingsForm,
} from '../../../../../_metronic/helpers/custom/tsHelpers/profileHelpers'
import {useAuth} from '../../../../modules/auth'
import {CardFooter} from '../../../../modules/custom/form-elements/CardFooter'
import {InputTemplate} from '../../../../modules/custom/form-elements/InputTemplate'
import {SettingsRadio} from '../../../../modules/custom/form-elements/radios/SettingsRadio'
import {FormattedMessage, useIntl} from 'react-intl'
import {Notification} from '../../../../modules/custom/Notification'

interface Props {
  settings: ISettings
}
export function Settings({settings}: Props) {
  const {setCurrentUser} = useAuth()
  const [loading, setLoading] = useState(false)
  const intl = useIntl()

  const initialValues = {
    delivery_type: +settings.delivery_type,
    dispatch: settings.dispatch,
    dispatch_order: +settings.dispatch_order,
    notification_bm: +settings.notification_bm === 1 ? true : false,
    notification_email: +settings.notification_email === 1 ? true : false,
  }
  const [success, setSuccess] = useState(false)

  const handleSubmit = async (values: ISettingsForm) => {
    console.log('profile contacts values', values)
    setLoading(true)
    const data = {
      delivery_type: values.delivery_type,
      dispatch: values.dispatch,
      dispatch_order: values.dispatch_order.toString(),
      notification_bm: values.notification_bm === true ? 1 : 0,
      notification_email: values.notification_email === true ? 1 : 0,
    }
    console.log('sent', data)

    const response = await profileApi.updateSettings(data)

    if (response) {
      setCurrentUser(response)
      setLoading(false)
      setSuccess(true)

      setTimeout(() => {
        setSuccess(false)
      }, 3000)
    }
  }

  return (
    <div className='card mb-5 mb-xl-10 pt-10'>
      <Formik
        validationSchema={profileSettingsSchema(intl)}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({values, setFieldValue}) => (
          <Form noValidate className='form'>
            <div className='card-body py-9 px-20 cardBody'>
              <div>
                <span className='form-check-label d-flex flex-column align-items-start mb-5'>
                  <span className='fw-bolder fs-5 '>
                    <FormattedMessage id='PROFILE.PAGE.SETTINGS.DEFAULT_DELIVERY_TYPE' />:
                  </span>
                </span>{' '}
                <div className='d-flex mw-500px '>
                  <InputTemplate
                    inputName='delivery_type'
                    type='radio'
                    title={intl.formatMessage({id: 'GENERAL.DELIVERY_TYPE_NAME.AVIA'})}
                    checkboxProps={{
                      checked: values.delivery_type === 0 ? true : false,
                      disabled: false,
                      setFieldValue: setFieldValue,
                      value: 0,
                      inputName: 'delivery_type',
                      type: 'radio',
                    }}
                  />{' '}
                  <InputTemplate
                    inputName='delivery_type'
                    type='radio'
                    title={intl.formatMessage({id: 'GENERAL.DELIVERY_TYPE_NAME.SEA'})}
                    checkboxProps={{
                      checked: values.delivery_type === 1 ? true : false,
                      disabled: false,
                      setFieldValue: setFieldValue,
                      value: 1,
                      inputName: 'delivery_type',
                      type: 'radio',
                    }}
                  />
                </div>
              </div>
              <div className='separator separator-dashed mb-6'></div>
              {/* <div className='row'>
                <label className='col-xl-3'></label>
                <div className='col-lg-9 col-xl-6'>
                  <h5 className='fw-bold mb-6'>Activity Related Emails:</h5>
                </div>
              </div> */}

              <div>
                {' '}
                <span className='form-check-label d-flex flex-column align-items-start mb-5'>
                  <span className='fw-bolder fs-5 '>
                    <FormattedMessage id='PROFILE.PAGE.SETTINGS.DEFAULT_TRACK_STATUS.HEADING' />:
                  </span>
                </span>{' '}
                <div>
                  {' '}
                  <SettingsRadio
                    title={intl.formatMessage({
                      id: 'PROFILE.PAGE.SETTINGS.DEFAULT_TRACK_STATUS.SEND.TITLE',
                    })}
                    description={intl.formatMessage({
                      id: 'PROFILE.PAGE.SETTINGS.DEFAULT_TRACK_STATUS.SEND.DESCRIPTION',
                    })}
                    inputName='dispatch'
                    formValues={values}
                    value={1}
                    setFieldValue={setFieldValue}
                  />
                  <SettingsRadio
                    title={intl.formatMessage({
                      id: 'PROFILE.PAGE.SETTINGS.DEFAULT_TRACK_STATUS.HOLD.TITLE',
                    })}
                    description={intl.formatMessage({
                      id: 'PROFILE.PAGE.SETTINGS.DEFAULT_TRACK_STATUS.HOLD.DESCRIPTION',
                    })}
                    inputName='dispatch'
                    formValues={values}
                    value={0}
                    setFieldValue={setFieldValue}
                  />
                </div>
              </div>
              <div className='separator separator-dashed my-6'></div>
              <div>
                {' '}
                <span className='form-check-label d-flex flex-column align-items-start mb-5'>
                  <span className='fw-bolder fs-5 '>
                    <FormattedMessage id='PROFILE.PAGE.SETTINGS.NOTIFICATIONS' />
                  </span>
                </span>{' '}
                <div>
                  {' '}
                  <SettingsRadio
                    title={intl.formatMessage({
                      id: 'PROFILE.PAGE.SETTINGS.NOTIFICATIONS.EMAIL_OPTION',
                    })}
                    type='checkbox'
                    inputName='notification_email'
                    formValues={values}
                    value={1}
                    setFieldValue={setFieldValue}
                    checkboxIsChecked={values.notification_email}
                  />
                  <SettingsRadio
                    title={intl.formatMessage({
                      id: 'PROFILE.PAGE.SETTINGS.NOTIFICATIONS.TELEGRAM_OPTION',
                    })}
                    description={
                      <a
                        href='https://t.me/vectorDbot'
                        target='_blank'
                        rel='noreferrer noopener'
                        className='text-muted fw-bold text-hover-muted d-block fs-6 mb-6'
                      >
                        <FormattedMessage id='PROFILE.PAGE.SETTINGS.TELEGRAM.OPEN' />
                      </a>
                    }
                    inputName='notification_bm'
                    formValues={values}
                    value={1}
                    type='checkbox'
                    setFieldValue={setFieldValue}
                    checkboxIsChecked={values.notification_bm}
                  />
                  <Notification
                    noteText={intl.formatMessage({
                      id: 'PROFILE.PAGE.SETTINGS.TELEGRAM.NOTE',
                    })}
                  />
                </div>
              </div>

              {/* <div className='px-10'>
                <span className='form-check-label d-flex flex-column align-items-start mb-5'>
                  <span className='fw-bolder fs-5 '>
                    {intl.formatMessage({
                      id: 'PROFILE.PAGE.SETTINGS.DEFAULT_BUYOUT_STATUS.HEADING',
                    })}
                    :
                  </span>
                </span>{' '}
                <div>
                  <SettingsRadio
                    title={intl.formatMessage({
                      id: 'PROFILE.PAGE.SETTINGS.DEFAULT_BUYOUT_STATUS.SEND_PARTIALLY.TITLE',
                    })}
                    description={intl.formatMessage({
                      id: 'PROFILE.PAGE.SETTINGS.DEFAULT_BUYOUT_STATUS.SEND_PARTIALLY.DESCRIPTION',
                    })}
                    inputName='dispatch_order'
                    formValues={values}
                    value={1}
                    setFieldValue={setFieldValue}
                  />
                  <SettingsRadio
                    title={intl.formatMessage({
                      id: 'PROFILE.PAGE.SETTINGS.DEFAULT_BUYOUT_STATUS.SEND_FULLY.TITLE',
                    })}
                    description={intl.formatMessage({
                      id: 'PROFILE.PAGE.SETTINGS.DEFAULT_BUYOUT_STATUS.SEND_FULLY.DESCRIPTION',
                    })}
                    inputName='dispatch_order'
                    formValues={values}
                    value={0}
                    setFieldValue={setFieldValue}
                  />
                  <SettingsRadio
                    title={intl.formatMessage({
                      id: 'PROFILE.PAGE.SETTINGS.DEFAULT_BUYOUT_STATUS.HOLD.TITLE',
                    })}
                    description={intl.formatMessage({
                      id: 'PROFILE.PAGE.SETTINGS.DEFAULT_BUYOUT_STATUS.HOLD.DESCRIPTION',
                    })}
                    inputName='dispatch_order'
                    formValues={values}
                    value={2}
                    setFieldValue={setFieldValue}
                  />
                </div>
              </div> */}
              {/* begin::Form Group*/}
            </div>{' '}
            <CardFooter loading={loading} success={success} />
          </Form>
        )}
      </Formik>{' '}
    </div>
  )
}
