/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {ListViewProvider, useListView} from '../core/ListViewProvider'
import {QueryRequestProvider, useQueryRequest} from '../core/QueryRequestProvider'
import {QueryResponseProvider} from '../core/QueryResponseProvider'
import {
  getSavedPagination,
  initialQueryState,
  KTCard,
  KTSVG,
  LS_KEYS,
} from '../../../../_metronic/helpers'
import {TracksTable} from './table/TracksTable'
import {TracksListHeader} from './components/header/TracksListHeader'
import {TrackModal} from '../_modals/TrackModal'
import {useLocation} from 'react-router-dom'
import {FormattedMessage} from 'react-intl'
import {usePageData} from '../../../../_metronic/layout/core'
import {OnePageWrapper} from '../_modals/OnePageWrapper'

const TracksList = () => {
  const {itemIdForUpdate, setItemIdForUpdate, isArchive, setIsArchive} = useListView()
  const location = useLocation()
  const {pathname, state} = location
  const {updateState} = useQueryRequest()
  const {setPageCustomData, pageCustomData} = usePageData()

  const openAddTrackModal = () => {
    setItemIdForUpdate(null)
    setPageCustomData({trackFormSimplified: false})
  }

  useEffect(() => {
    // console.log('arc', setIsArchive, pathname === '/archived-tracks/list')
    const retrievedData = getSavedPagination()

    updateState({
      ...initialQueryState,
      itemsPerPage: retrievedData,
    })
    if (setIsArchive && pathname === '/archived-tracks/list') {
      setIsArchive(true)
      updateState({
        ...initialQueryState,
        filter: {archive: 1},
      })
    }
  }, [])

  useEffect(() => {
    if (state === '/tracks/create') {
      setItemIdForUpdate(null)
      setPageCustomData({trackFormSimplified: true})
    } else {
      setPageCustomData({trackFormSimplified: false})
    }
  }, [state])

  return (
    <>
      <div
        style={{marginTop: '-4%', zIndex: '60'}}
        className='d-flex justify-content-between align-items-center mb-8 position-relative'
      >
        <h3>
          <FormattedMessage
            id={isArchive === true ? 'TRACKS.PAGE.PAGE_HEADING_ARCH' : 'TRACKS.PAGE.PAGE_HEADING'}
          />
        </h3>
        {isArchive === false && (
          <button type='button' className='btn btn-primary' onClick={openAddTrackModal}>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            <FormattedMessage id='TRACKS.PAGE.LIST.ADD_BUTTON' />
          </button>
        )}
      </div>
      <KTCard>
        <TracksListHeader />
        <TracksTable />

        {itemIdForUpdate !== undefined && pageCustomData?.trackFormSimplified === false && (
          <TrackModal />
        )}
        {itemIdForUpdate !== undefined && pageCustomData?.trackFormSimplified === true && (
          <OnePageWrapper />
        )}
      </KTCard>
    </>
  )
}

const TracksListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <TracksList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {TracksListWrapper}
