/* eslint-disable jsx-a11y/anchor-is-valid */
import {useIntl} from 'react-intl'
import {Track} from '../../../../core/_models/_tracks-models'

type Props = {
  track: Track
}

const TrackItemsInfoCell = ({track}: Props) => {
  const {height, width, length, weight, prods} = track
  const intl = useIntl()
  return (
    <div>
      <p className='text-dark fw-bold text-hover-primary d-block fs-6 mb-0'>
        {!weight && !height && !length && !width
          ? ''
          : `${weight ? `${weight} ${intl.formatMessage({id: 'GENERAL.MEASURES.KG'})}` : '-'}, ${
              height ? `${height}${intl.formatMessage({id: 'GENERAL.MEASURES.CM'})}` : '-'
            } x ${width ? `${width}${intl.formatMessage({id: 'GENERAL.MEASURES.CM'})}` : '-'} x ${
              length ? `${length}${intl.formatMessage({id: 'GENERAL.MEASURES.CM'})}` : '-'
            }`}
      </p>
      {prods.length > 2 ? (
        <>
          {prods?.slice(0, 2).map(({name, qty, cost}, index) => (
            <span key={index} className='text-muted fw-semobold text-muted d-block fs-7'>
              {!name && !cost
                ? ''
                : `${name ? `${name}` : '-'} x ${qty ? `${qty}` : '-'} x ${
                    cost ? `$${cost}` : '-'
                  }`}
            </span>
          ))}
          <span className='text-muted fw-semobold text-muted d-block fs-3 line-height-10px'>
            ...
          </span>
        </>
      ) : (
        prods?.map(({name, qty, cost}, index) => (
          <span key={index} className='text-muted fw-semobold text-muted d-block fs-7'>
            {!name && !cost
              ? ''
              : `${name ? `${name}` : '-'} x ${qty ? `${qty}` : '-'} x ${cost ? `$${cost}` : '-'}`}
          </span>
        ))
      )}
    </div>
  )
}

export {TrackItemsInfoCell}
