import {useIntl} from 'react-intl'

export function InspectionConditions() {
  const intl = useIntl()

  return (
    <div className='mb-2'>
      <div className='form-check-label d-flex flex-column align-items-start m-0'>
        <p
          className='text-muted fs-6 m-0'
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage({id: 'COMPONENTS.InspectionConditions.CHECK_BEFORE'}),
          }}
        ></p>
        <p
          className='text-muted fs-6 m-0'
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage({id: 'COMPONENTS.InspectionConditions.CHECK_AFTER'}),
          }}
        ></p>
        <p
          className='text-muted fs-6 m-0'
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage({id: 'COMPONENTS.InspectionConditions.CHECK_GOODS'}),
          }}
        ></p>
      </div>
    </div>
  )
}
