import {useEffect, useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {KTSVG} from '../../../../../_metronic/helpers'
import {getTOS} from '../../../../modules/auth/core/_requests'

interface Props {
  handleClick: (isOpen: boolean) => void
  accepted: boolean
  acceptTos: () => void
}
const TermsOfServiceModal = ({handleClick, acceptTos, accepted}: Props) => {
  const [tos, setTOS] = useState('')
  const intl = useIntl()

  useEffect(() => {
    document.body.classList.add('modal-open')
    getTOS().then((response) => {
      setTOS(response.data)
    })
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  const closeOverlay = (e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
    if (accepted) {
      if (e.currentTarget === e.target) {
        handleClick(false)
      }
    } else {
      return
    }
  }

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
        onClick={closeOverlay}
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog-centered modal-xl'>
          {/* begin::Modal content */}
          <div className='modal-content'>
            <div className='modal-header'>
              {/* begin::Modal title */}
              <h4 className='fw-bolder'>
                <FormattedMessage id='PROFILE.PAGE.OVERVIEW.TERMS_OF_SERVICE' />
              </h4>
              {/* end::Modal title */}

              {/* begin::Close */}
              {accepted && (
                <div
                  className='btn btn-icon btn-sm btn-active-icon-primary'
                  data-kt-users-modal-action='close'
                  onClick={() => handleClick(false)}
                  style={{cursor: 'pointer'}}
                >
                  <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                </div>
              )}
              {/* end::Close */}
            </div>
            <div className='modal-body scroll-y mx-5 mx-xl-15 my-7 text-center'>
              <div className='' dangerouslySetInnerHTML={{__html: tos}}></div>{' '}
              {accepted ? (
                <></>
              ) : (
                <button
                  type='button'
                  className='btn btn-lg btn-primary w-100px'
                  onClick={() => {
                    acceptTos()
                    handleClick(false)
                  }}
                >
                  <FormattedMessage id='GENERAL.BUTTONS.ACCEPT' />
                </button>
              )}
            </div>

            {/* end::Modal body */}
          </div>

          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

export {TermsOfServiceModal}
