export const statusColor = (status: boolean | 'on hold' | number) => {
  if (status === 'on hold') {
    return 'StatusBar_on-hold'
  } else if (status === true || status === 1) {
    return 'StatusBar_active'
  } else if (status === false || status === 0) {
    return 'StatusBar_blocked'
  } else {
    return
  }
}
