import * as Yup from 'yup'
import {cyrillicOnly} from '../../../../../_metronic/helpers/custom/regexs'

const profileOverviewSchema = (intl: any) =>
  Yup.object().shape({
    firstName: Yup.string()
      .min(3, intl.messages.YUP_VALIDATION_GENERAL_MIN_SYMBOLS)
      .max(50, intl.messages.YUP_VALIDATION_GENERAL_MAX_SYMBOLS)
      .matches(cyrillicOnly, intl.messages.YUP_VALIDATION_GENERAL_ENTER_CYRILLIC_NO_SYMBOLS)
      .required(intl.messages.YUP_VALIDATION_GENERAL_ENTER_NAME),
    lastName: Yup.string()
      .min(3, intl.messages.YUP_VALIDATION_GENERAL_MIN_SYMBOLS)
      .max(50, intl.messages.YUP_VALIDATION_GENERAL_MAX_SYMBOLS)
      .matches(cyrillicOnly, intl.messages.YUP_VALIDATION_GENERAL_ENTER_CYRILLIC_NO_SYMBOLS)
      .required(intl.messages.YUP_VALIDATION_GENERAL_ENTER_LAST_NAME),
    patronymic: Yup.string()
      .matches(cyrillicOnly, intl.messages.YUP_VALIDATION_GENERAL_ENTER_CYRILLIC_NO_SYMBOLS)
      .when('noPatronymic', {
        is: false,
        then: Yup.string()
          .min(3, intl.messages.YUP_VALIDATION_GENERAL_MIN_SYMBOLS)
          .max(50, intl.messages.YUP_VALIDATION_GENERAL_MAX_SYMBOLS)
          .required(intl.messages.YUP_VALIDATION_GENERAL_ENTER_PATRONYMIC),
      }),
    passport: Yup.mixed(),
  })

const profileContactsSchema = (intl: any) =>
  Yup.object().shape({
    phoneNumbers: Yup.array()
      .of(
        Yup.object().shape({
          phoneNumberType: Yup.string(),
          phoneNumber: Yup.string().min(15, intl.messages.YUP_VALIDATION_GENERAL_MISSING_NUMBERS),
          // .matches(PHONE_REGEX, 'Недопустимый формат'),
        })
      )
      .label(intl.messages.YUP_VALIDATION_PROFILE_LINKS),
    email: Yup.string(),
  })

const profileSettingsSchema = (intl: any) =>
  Yup.object({
    delivery_type: Yup.number().required().label(intl.messages.YUP_VALIDATION_DEL_SETTINGS_TYPE),
    dispatch: Yup.number().required().label(intl.messages.YUP_VALIDATION_DEL_SETTINGS_TRACK),
    dispatch_order: Yup.number().required().label(intl.messages.YUP_VALIDATION_DEL_SETTINGS_BUYOUT),
    notification_email: Yup.boolean(),
    notification_bm: Yup.boolean(),
  })

export {profileOverviewSchema, profileContactsSchema, profileSettingsSchema}
