import {FC, useEffect, useState} from 'react'
import {getConfig, useLang} from './Metronici18n'
import {IntlProvider} from 'react-intl'
import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/locale-data/en'
import '@formatjs/intl-relativetimeformat/locale-data/ru'
import '@formatjs/intl-relativetimeformat/locale-data/uk'
import ukMessages from './messages/uk.json'
import ruMessages from './messages/ru.json'
import {WithChildren} from '../helpers'
import {getTranslationData} from '../../app/modules/general/core/_requests'

const allMessages = {
  uk: ukMessages,
  ru: ruMessages,
}

const I18nProvider: FC<WithChildren> = ({children}) => {
  const locale = useLang()
  const [translationData, setTranslationData] = useState(ukMessages)
  const enableSplashScreen = () => {
    const splashScreen = document.getElementById('splash-screen')
    if (splashScreen) {
      splashScreen.style.setProperty('display', 'flex')
    }
  }
  useEffect(() => {
    enableSplashScreen()
  }, [])

  const getTD = async () => {
    const url = new URL(window.location.href)
    const host = url.hostname.replace(/((www.)?my.)/, '')
    let owner = 1
    switch (host) {
      case 'usa-vector.ndv.net.ua':
        owner = 2
        break
      case 'vectorchina.ndv.net.ua':
        owner = 3
        break
      case 'vectorlogistic.net':
        owner = 6
        break
      case 'vector-usa.biz':
      case 'vector.org':
        owner = 1
        break
      default:
        break
    }
    await getTranslationData(owner)
      .then(({data}) => {
        setTranslationData(data)
      })
      .catch((e) => console.log(e))
  }

  useEffect(() => {
    getTD()
  }, [])

  // @ts-ignore
  const messages = allMessages[locale]

  return (
    <IntlProvider locale={locale} messages={translationData || messages}>
      {children}
    </IntlProvider>
  )
}

export {I18nProvider}
