import {Fragment} from 'react'
import {IDashboardItemRow} from './core/_models'

interface Props {
  index: number
  row: IDashboardItemRow
  rowsLength: number
  type?: string
}

export function DashboardItemStat({row, index, rowsLength, type}: Props) {
  const date = row.date?.split(' ')[0].split('-').reverse().join('-')
  const finalDateAndTime = date ? date + ' ' + row.date?.split(' ')[1] : ''
  return (
    <Fragment key={`lw26-rows-${index}`}>
      <div className='d-flex flex-stack'>
        <span className='text-primary fw-semibold fs-6 me-2'>{row.title}</span>
        <div className='d-flex flex-column'>
          {' '}
          <span className='btn btn-icon btn-sm h-auto btn-color-black-400 justify-content-start text-start'>
            {row.stat || 0}
          </span>
          {type === 'balance' && (
            <span className='btn btn-icon btn-sm h-auto btn-color-gray-400 fs-9 w-auto fw-normal text-justify justify-content-end'>
              {finalDateAndTime}
            </span>
          )}
          {type === 'parcel' && row.add && row.stat > 0 ? (
            <span className='btn btn-icon btn-sm h-auto btn-color-gray-400 fs-9 w-auto fw-normal text-justify justify-content-end'>
              ${row.add}
            </span>
          ) : (
            <></>
          )}
        </div>
      </div>
      {rowsLength - 1 > index && <div className='separator separator-dashed my-3' />}
    </Fragment>
  )
}
