import {Dispatch} from 'react'
import {AddTrackForm, IInvoice} from '../../../../app/pages/tracks/core/_models/_tracks-models'
import {ISetFieldValue} from '../tsHelpers/generalHelpers'
type Props = {
  currentStep: number
  setFieldValue: ISetFieldValue
  values: AddTrackForm
  noFormErrors: boolean
  anyComProds: boolean
  allInvoices?: IInvoice[]
  invoiceLinks?: string[]
  setCurrentSchema?: Dispatch<React.SetStateAction<any>>
  intl?: any
}
export function earlySubmit(obj: Props) {
  const {currentStep, setFieldValue, values, noFormErrors, anyComProds} = obj
  const {require_invoice, package_type} = values
  const steps = [2, 3, 4]
  const oneOfApplicableSteps = steps.some((item) => currentStep === item)
  const allowEarlySubmit = () => {
    if (oneOfApplicableSteps && noFormErrors === true) {
      if (currentStep === 2) {
        return require_invoice === 0 && anyComProds === false
      } else if (currentStep === 3) {
        return package_type === 0 && require_invoice === 0
      } else if (currentStep === 4 && obj.allInvoices && obj.invoiceLinks) {
        console.log('objallinv', obj.allInvoices, obj.invoiceLinks)
        if (require_invoice === 1) {
          return (
            obj.allInvoices.length > 0 ||
            (obj.invoiceLinks.length > 0 && obj.invoiceLinks[0] !== '')
          )
        } else {
          return true
        }
      } else {
        return false
      }
    } else {
      return false
    }
  }

  const booleanResult = allowEarlySubmit()

  setFieldValue('earlySubmitAllowed', booleanResult)
}
