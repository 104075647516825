/* eslint-disable jsx-a11y/anchor-is-valid */
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {Track} from '../../core/_models/_tracks-models'
import {FormattedMessage, useIntl} from 'react-intl'
import {TrackHeaderShipment} from './TrackHeaderShipment'
import {TrackHeaderInfo} from './TrackHeaderInfo'
import {HeaderMessage} from '../../../../modules/custom/page-items/header-elements/HeaderMessage'
import {ImageLightbox} from '../../../../modules/custom/images/ImageLightbox'
import {useState} from 'react'
import {scrollToTabsStart} from '../../../../../_metronic/helpers/custom/funcs/scrollToTabsStart'

interface Props {
  track: Track
  isArchive: boolean
}

const TrackHeader = ({track, isArchive}: Props) => {
  const location = useLocation()
  const intl = useIntl()

  const pagePath = isArchive === true ? '/archived-tracks/info' : '/tracks/edit'

  const [currentImage, setCurrentImage] = useState(0)

  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const openLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(true)
  }

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  const {prods, admin_comment, admin_comment_files, description, preview_files, track_code} = track

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3 justify-content-center justify-content-md-between overflow-hidden overflow-md-auto'>
          <div className='d-flex flex-row w-100'>
            <div className='me-7 mb-4 cursor-zoom-in'>
              <div className='symbol symbol-75px symbol-lg-160px symbol-fixed position-relative'>
                {preview_files && preview_files?.length > 0 ? (
                  <div className='me-7 mb-4'>
                    <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                      <img
                        src={preview_files[0]}
                        width='45'
                        height='45'
                        alt='track'
                        onClick={openLightbox}
                        style={{objectFit: 'contain'}}
                      />

                      <button
                        type='button'
                        className='btn btn-sm position-absolute'
                        style={{top: '75%', right: '10px'}}
                        onClick={openLightbox}
                      >
                        <KTSVG
                          path='/media/icons/duotune/files/fil012.svg'
                          className='svg-icon-1 svg-icon-gray-900 me-1 position-absolute'
                        />
                        <span className='position-absolute top-75 end-0 text-white fs-8'>
                          {preview_files.length}
                        </span>
                      </button>
                      <ImageLightbox
                        viewerIsOpen={viewerIsOpen}
                        closeLightbox={closeLightbox}
                        currentImage={currentImage}
                        images={preview_files.map((item, index) => {
                          return {src: item, title: `${track_code}-${index + 1}`}
                        })}
                      />
                    </div>
                  </div>
                ) : (
                  <img
                    src={toAbsoluteUrl('/media/icons/duotune/ecommerce/ecm010.svg')}
                    alt='Track'
                  />
                )}
              </div>
            </div>
            <div className='d-flex flex-column'>
              <TrackHeaderInfo track={track} isArchive={isArchive} />{' '}
              {/* {(description || admin_comment) && (
                <div className='d-none d-md-block  mw-md-450px'>
                  {' '}
                  <HeaderMessage
                    comment={{
                      author: intl.formatMessage({id: 'TRACK_PAGE.STATUSES.CLIENT'}),
                      messageText: description,
                    }}
                    answer={{
                      messageText: admin_comment,
                      files: admin_comment_files,

                      author: intl.formatMessage({id: 'COMPONENTS.HeaderMessage.AUTHOR'}),
                    }}
                  />
                </div>
              )} */}
            </div>
          </div>
          {/* {(description || admin_comment) && (
            <div className='d-md-none'>
              {' '}
              <HeaderMessage
                comment={{
                  author: intl.formatMessage({id: 'TRACK_PAGE.STATUSES.CLIENT'}),
                  messageText: description,
                }}
                answer={{
                  messageText: admin_comment,
                  files: admin_comment_files,
                  author: intl.formatMessage({id: 'COMPONENTS.HeaderMessage.AUTHOR'}),
                }}
              />
            </div>
          )} */}

          <TrackHeaderShipment track={track} />
        </div>

        <div className='d-flex overflow-auto h-55px mb-5 mb-md-0' id='item-page-header'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
            <li className='nav-item' onClick={scrollToTabsStart}>
              <Link
                className={
                  `nav-link text-active-primary me-6 text-center ` +
                  (location.pathname === `${pagePath}/overview/${track.id}` && 'active')
                }
                to={`${pagePath}/overview/${track.id}`}
              >
                <FormattedMessage id='TRACK_PAGE.GEN_TAB' />
              </Link>
            </li>
            <li className='nav-item' onClick={scrollToTabsStart}>
              <Link
                className={
                  `nav-link text-active-primary me-6 text-center ` +
                  (location.pathname === `${pagePath}/content/${track.id}` && 'active')
                }
                to={`${pagePath}/content/${track.id}`}
              >
                <FormattedMessage id='TRACK_PAGE.CONTENT_TAB' values={{qty: prods.length}} />
              </Link>
            </li>
            <li className='nav-item' onClick={scrollToTabsStart}>
              <Link
                className={
                  `nav-link text-active-primary me-6 text-center ` +
                  (location.pathname === `${pagePath}/parcel/${track.id}` && 'active')
                }
                to={`${pagePath}/parcel/${track.id}`}
              >
                <FormattedMessage id='TRACK_PAGE.TYPE_TAB' />
              </Link>
            </li>
            <li className='nav-item' onClick={scrollToTabsStart}>
              <Link
                className={
                  `nav-link text-active-primary me-6 text-center ` +
                  (location.pathname === `${pagePath}/invoice/${track.id}` && 'active')
                }
                to={`${pagePath}/invoice/${track.id}`}
              >
                <span className='me-2'>
                  <FormattedMessage id='TRACK_PAGE.INVOICE_TAB' />
                </span>
              </Link>
            </li>{' '}
            <li className='nav-item' onClick={scrollToTabsStart}>
              <Link
                className={
                  `nav-link text-active-primary me-6 text-center ` +
                  (location.pathname === `${pagePath}/control/${track.id}` && 'active')
                }
                to={`${pagePath}/control/${track.id}`}
              >
                <span className='me-2'>
                  <FormattedMessage id='TRACK_PAGE.INSPECTION_TAB' />
                </span>
                <KTSVG
                  path='/media/icons/duotune/technology/teh002.svg'
                  className={
                    `svg-icon-4 svg-icon-grey me-1 ` +
                    (location.pathname === `${pagePath}/control/${track.id}` && 'svg-icon-success')
                  }
                />
              </Link>
            </li>
            <li className='nav-item' onClick={scrollToTabsStart}>
              <Link
                className={
                  `nav-link text-active-primary me-6 text-center ` +
                  (location.pathname === `${pagePath}/status/${track.id}` && 'active')
                }
                to={`${pagePath}/status/${track.id}`}
              >
                <FormattedMessage id='TRACK_PAGE.STATUSES_TAB' />
              </Link>
            </li>
            <li className='nav-item' onClick={scrollToTabsStart}>
              <Link
                className={
                  `nav-link text-active-primary me-6 text-center ` +
                  (location.pathname === `${pagePath}/all-parcels/${track.id}` && 'active')
                }
                to={`${pagePath}/all-parcels/${track.id}`}
              >
                <FormattedMessage id='TRACK_PAGE.PARCELS_TAB' />
              </Link>
            </li>
            <li className='nav-item' onClick={scrollToTabsStart}>
              <Link
                className={
                  `nav-link text-active-primary me-6 text-center ` +
                  (location.pathname === `${pagePath}/receiver/${track.id}` && 'active')
                }
                to={`${pagePath}/receiver/${track.id}`}
              >
                <span className='me-2'>
                  <FormattedMessage id='TRACK_PAGE.RECEIVER_TAB' />
                </span>
                <KTSVG
                  path='/media/icons/duotune/communication/com006.svg'
                  className={
                    `svg-icon-4 svg-icon-grey me-1 ` +
                    (location.pathname === `${pagePath}/receiver/${track.id}` && 'svg-icon-success')
                  }
                />
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export {TrackHeader}
