import {IWD} from './_Models'

export const DefaultWD: IWD = {
  id: false,
  host: '',
  name: '',
  directions: [],
  description: '',
  links: null,
  mainColor: '#ff0000',
  logoUrl: '/media/icons/vector-full-default.svg',
  logoDarkUrl: '/media/icons/vector-full-default.svg',
  faviconUrl: '/media/icons/vector-full-default.svg',
  logoPrep: '',
  authParams: {},
  gtmId: '',
}
