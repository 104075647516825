import axios from 'axios'
import {getConfig} from '../../../i18n/Metronici18n'
import {ISettings, IShipmentAddress, IShipmentAddressToSend} from '../tsHelpers/profileHelpers'
import {AuthModel} from '../../../../app/modules/auth'
import {FACEBOOK_LOGIN_URL} from '../../../../app/modules/auth/core/_requests'
axios.defaults.baseURL = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_VEC_URL}`

const API = process.env.REACT_APP_API_PATH

const PAGE = '/profile'

const BASE_URL = `${API}${PAGE}`

const getProfileAddresses = async () => {
  const {selectedLang} = getConfig()

  const {data} = await axios.get(`${API}${PAGE}/addresses`, {
    headers: {
      'Accept-Language': selectedLang,
    },
  })

  return data
}

const addAddress = async (data: IShipmentAddressToSend | IShipmentAddress) => {
  const {data: response} = await axios.post(`${API}${PAGE}/address_update`, data)

  return response
}

const editProfileOverview = async (data: any) => {
  const {data: response} = await axios.post(`${API}${PAGE}/update`, data)

  return response
}

const addPassportScan = async (name: string, file: File) => {
  let formData = new FormData()
  formData.append(name, file as any)

  try {
    const {data} = await axios.post(`${API}${PAGE}/upload_passport`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return data
  } catch (err) {
    console.log(err)
  }
}

const updateSettings = async (settings: Partial<ISettings>) => {
  try {
    const {data} = await axios.post(`${API}${PAGE}/update_settings`, settings)
    return data
  } catch (err) {
    console.log(err)
  }
}

const fetchReferals = async () => {
  const {data} = await axios.get(`${API}${PAGE}/referal`)

  return data
}
export function linkSocial(token: string, social: string) {
  return axios.post(`${API}${PAGE}/link_social`, {token, social})
}

export function removeSocial(social: string) {
  return axios.post(`${API}${PAGE}/remove_social`, {social})
}

const checkPhone = async (phone: string) => {
  const {selectedLang} = getConfig()

  const {data} = await axios.post(`${BASE_URL}/check_phone`, {
    phone,
    headers: {
      'Accept-Language': selectedLang,
    },
  })

  return data
}

// success = true - ok
// success = false - phone used
// err? in case of invalid phone format

export const profileApi = {
  editProfileOverview,
  addPassportScan,
  getProfileAddresses,
  updateSettings,
  addAddress,
  checkPhone,
  fetchReferals,
}
