import {useFormikContext} from 'formik'
import {Dispatch, SetStateAction} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {IBasicSelect} from '../../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {IShipmentAddressForm} from '../../../../../../_metronic/helpers/custom/tsHelpers/profileHelpers'
import {InputTemplate} from '../../../form-elements/InputTemplate'
import {ModalNotification} from '../../../modals/ModalNotification'
import {Notification} from '../../../Notification'

export interface IPostShimpentProps {
  citiesLoading: boolean
  citiesResults: IBasicSelect[]
  offLoading: boolean
  offResults: IBasicSelect[]
  values: IShipmentAddressForm
  filterCities: (query: string) => Promise<void>
  setQuery: Dispatch<SetStateAction<string>>
}

export const PostReserve = ({
  citiesLoading,
  citiesResults,
  offLoading,
  offResults,
  values,
  filterCities,
  setQuery,
}: IPostShimpentProps) => {
  const intl = useIntl()
  const {setFieldValue} = useFormikContext()

  return (
    <>
      <div className='d-flex'>
        {' '}
        <h3 className='me-1'>
          <FormattedMessage id='COMPONENTS.PostShipment.RESERVE_NP.TITLE' />
        </h3>{' '}
        <ModalNotification
          svg='/media/icons/duotune/general/gen046.svg'
          addClass='ModalNotification__position'
          children={
            <div className='fs-7 fw-normal'>
              <FormattedMessage id='COMPONENTS.PostShipment.RESERVE_NP.EXPLANATION' />
            </div>
          }
        />{' '}
      </div>
      <div className='d-flex justify-content-around flex-column'>
        <InputTemplate
          addPrompt={false}
          inputName='reserveOfficeCity'
          required={true}
          type='select'
          value={values.reserveOfficeCity}
          title={intl.formatMessage({id: 'COMPONENTS.PostShipment.CITY'})}
          marginClass='me-4'
          reactSelectprops={{
            options: citiesResults,
            isLoading: citiesLoading,
            onInputChange: filterCities,
            isClearable: true,
            selectName: 'reserveOfficeCity',
            setFieldValue: setFieldValue,
            placeholder: intl.formatMessage({id: 'COMPONENTS.PostShipment.CITY_PLACEHOLDER'}),
            currentValue: citiesResults.find(
              (item: any) => item.value === values.reserveOfficeCity
            ),
          }}
        />
        <InputTemplate
          addPrompt={false}
          inputName='reserveOfficeNumber'
          required={true}
          type='select'
          title={intl.formatMessage({id: 'COMPONENTS.PostShipment.NP'})}
          value={values.reserveOfficeNumber}
          reactSelectprops={{
            options: offResults,
            isLoading: offLoading,
            onInputChange: setQuery,
            isClearable: true,
            selectName: 'reserveOfficeNumber',
            setFieldValue: setFieldValue,
            placeholder: intl.formatMessage({id: 'COMPONENTS.PostShipment.NP_PLACEHOLDER'}),
            currentValue: offResults.find((item: any) => item.value === values.reserveOfficeNumber),
          }}
        />
      </div>
      <Notification
        noteText={intl.formatMessage({id: 'COMPONENTS.PostShipment.RESERVE_NP.PROMPT'})}
      />
    </>
  )
}
