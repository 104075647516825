import axios, {AxiosResponse} from 'axios'
import {getConfig} from '../../../../_metronic/i18n/Metronici18n'
import {BalanceQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_PATH
const BALANCE_URL = `/balance`
const BASE_URL = `${API_URL}${BALANCE_URL}`

const getBalance = (query: string): Promise<BalanceQueryResponse> => {
  const {selectedLang} = getConfig()

  return axios
    .get(`${BASE_URL}?${query}`, {
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((d: AxiosResponse<BalanceQueryResponse>) => {
      console.log('balance', d.data)
      return d.data
    })
}
type TCheckoutObj = {
  sum: number
  comment: string
  payment_token: string
  provider: string
  debts?: number
}

const checkout = (
  amount: number,
  comment: string,
  payment_token: string,
  debts?: number
): Promise<any> => {
  const obj: TCheckoutObj = {
    sum: amount,
    comment,
    payment_token,
    provider: 'nmi',
  }

  if (debts !== undefined) {
    obj.debts = debts
  }
  console.log('obj', obj, 'debts', debts)

  return axios.post(`${BASE_URL}/add`, obj).then((d: AxiosResponse) => {
    console.log('add', d.data)
    return d.data
  })
}

const getCurrBalance = async () => {
  return await axios.get(`${BASE_URL}/current`).then((d) => d.data)
}

export {checkout, getBalance, getCurrBalance}
