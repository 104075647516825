import {getChatToken} from '../../chat/ChatHelpers'
import {AuthModel} from './_models'

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'

const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    (config: {
      headers: {
        Owner: string
        Authorization: string
      }
      url: string
    }) => {
      const auth = getAuth()
      const chat = getChatToken()
      const includesCompanyCabUrl =
        config.url.includes('https://crauzer.com') ||
        config.url.includes('https://logistic.ndv.net.ua')
      if (includesCompanyCabUrl) {
        if (chat) {
          config.headers.Authorization = `Bearer ${chat}`
        } else {
          const url = new URL(window.location.href)
          const host = url.hostname.replace(/((www.)?my.)/, '')
          let owner = 1
          switch (host) {
            case 'usa-vector.ndv.net.ua':
              owner = 2
              break
            case 'vectorchina.ndv.net.ua':
              owner = 3
              break
            case 'vectorlogistic.net':
              owner = 6
              break
            case 'vector-usa.biz':
            case 'vector.org':
              owner = 1
              break

            default:
              break
          }
          config.headers.Owner = String(owner)
        }
      } else {
        if (auth && auth.token) {
          config.headers.Authorization = `Bearer ${auth.token}`
        } else {
          const url = new URL(window.location.href)
          const host = url.hostname.replace(/((www.)?my.)/, '')
          let owner = 1
          switch (host) {
            case 'usa-vector.ndv.net.ua':
              owner = 2
              break
            case 'vectorchina.ndv.net.ua':
              owner = 3
              break
            case 'vectorlogistic.net':
              owner = 6
              break
            case 'vector-usa.biz':
            case 'vector.org':
              owner = 1
              break

            default:
              break
          }
          config.headers.Owner = String(owner)
        }
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )
}

export {getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY}
