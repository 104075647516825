export const handleFileUpload = async (
  event: React.ChangeEvent<HTMLInputElement>,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
  fieldName: string,
  fileName: string,
  apiRequest: (name: string, file: File) => any | null
) => {
  const fileObj = event.target.files && event.target.files[0]

  if (!fileObj || !apiRequest) {
    return
  }

  const response = await apiRequest(fileName, fileObj)
  if (response.filename === '') {
    setFieldValue(fieldName, [response])
  } else {
    setFieldValue(fieldName, response.filename)
  }

  event.target.value = ''

  return response
}
