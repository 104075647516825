import {Notification} from '../../Notification'
import {ISetFieldValue} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {FormattedMessage} from 'react-intl'

interface Props {
  inputName: string
  title?: string
  setFieldValue: ISetFieldValue
  inputId: string
  loading?: boolean
  uploadedFiles?: any
  uploadFunction?: (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: ISetFieldValue,
    val: string
  ) => void
  placeholder?: string
  notification?: {bool: boolean; text?: string; borderTop?: boolean}
  required?: boolean
  disabled?: boolean
}
export function DocumentFileInput({
  title,
  inputName,
  inputId,
  loading = false,
  disabled = false,
  setFieldValue,
  uploadFunction = () => {
    return
  },
  placeholder,
  notification = {bool: false},
  required = false,
}: Props) {
  return (
    <div className='mb-2 d-flex'>
      {title && (
        <label className={`${required === true ? 'required' : ''} w-auto fw-bold fs-5`}>
          {title}
        </label>
      )}

      <div className='w-140px ms-7'>
        <input
          name={inputName}
          accept='.jpg, .png, .pdf, .jpeg'
          className='form-control form-control-lg form-control-solid DocumentFileInput'
          type='file'
          disabled={disabled}
          placeholder={placeholder ? placeholder : ''}
          id={inputId}
          style={{display: 'none'}}
          onChange={(e) => {
            const localFileName = e.target.value.split('\\')[e.target.value.split('\\').length - 1]
            uploadFunction(e, setFieldValue, e.target.value)
            setFieldValue('invoice_file', {
              name: localFileName,
              file: {base_url: '', filename: ''},
            })
          }}
        />
        <div className=''>
          <label
            className={`btn btn-sm btn-secondary btn-active-primary mb-3 fs-6 ${
              disabled === true ? 'disabled' : ''
            }`}
            htmlFor={inputId}
          >
            {loading === true ? (
              <span className='indicator-progress' style={{display: 'block'}}>
                <span className='spinner-border spinner-border-sm align-middle'></span>
              </span>
            ) : (
              <FormattedMessage id='COMPONENTS.DocumentFileInput.ADD_LABEL' />
            )}
          </label>
        </div>
      </div>

      {notification.bool === true && (
        <Notification noteText={notification.text || ''} borderTop={notification.borderTop} />
      )}
    </div>
  )
}
