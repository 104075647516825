export const getDateAndTime = (date: Date | string) => {
  const time = date
    .toLocaleString('uk-UA')
    .split(' ')[1]
    .replace(/(.*)\D\d+/, '$1')
  const formattedDate = JSON.stringify(date.toLocaleString('uk-UA'))
    .split(' ')[0]
    .split('"')[1]
    .split('-')
    .reverse()
    .join('.')

  return `${time}, ${formattedDate}`
}
