/* eslint-disable jsx-a11y/anchor-is-valid */
import {Parcel} from '../../../../core/_models/_parcels-models'
import {FormattedMessage, useIntl} from 'react-intl'

type Props = {
  parcel: Parcel
}

const ParcelItemsInfoCell = ({parcel}: Props) => {
  const intl = useIntl()
  const {height, width, length, weight, prods} = parcel
  return (
    <div>
      <span className='text-dark fw-bold d-block fs-6'>
        {!weight && !height && !length && !width
          ? ''
          : `${weight ? `${weight} ${intl.formatMessage({id: 'GENERAL.MEASURES.KG'})}` : '-'}, ${
              height ? `${height}${intl.formatMessage({id: 'GENERAL.MEASURES.CM'})}` : '-'
            } x ${width ? `${width}${intl.formatMessage({id: 'GENERAL.MEASURES.CM'})}` : '-'} x ${
              length ? `${length}${intl.formatMessage({id: 'GENERAL.MEASURES.CM'})}` : '-'
            }`}
      </span>

      {prods && prods.length > 4 ? (
        <>
          {prods?.slice(0, 4).map(({name, qty}, index) => (
            <span key={index} className='text-muted fw-semobold text-muted d-block fs-7'>
              {!name ? '' : `${name ? `${name}` : '-'} x ${qty ? `${qty}` : '-'} `}
            </span>
          ))}
          <span className='text-muted fw-semobold text-muted d-block fs-7'>
            <FormattedMessage id='GENERAL.COUNTABLES.MORE.GOODS' values={{ itemCount: prods.length - 4}} />
         
          </span>
        </>
      ) : (
        prods?.map(({name, qty}, index) => (
          <span key={index} className='text-muted fw-semobold text-muted d-block fs-7'>
            {!name ? '' : `${name ? `${name}` : '-'} x ${qty ? `${qty}` : '-'}`}
          </span>
        ))
      )}
    </div>
  )
}

export {ParcelItemsInfoCell}
