import {Column} from 'react-table'
import {BuyoutCustomHeader} from './BuyoutCustomHeader'
import {Buyout} from '../../../core/_models/_buyout-models'
import {BuyoutItemsInfoCell} from './cells/BuyoutItemsInfoCell'
import {BuyoutNumberCell} from './cells/BuyoutNumberCell'
import {BuyoutStatusCell} from './cells/BuyoutStatusCell'
import {BuyoutActionsCell} from './cells/BuyoutActionsCell'

const buyoutsColumns: ReadonlyArray<Column<Buyout>> = [
  {
    Header: (props) => (
      <BuyoutCustomHeader
        tableProps={props}
        title={'BUYOUTS_COLUMNS_BUYOUT_NUMBER'}
        className='min-w-50px'
      />
    ),
    id: 'buyoutNumber',
    Cell: ({...props}) => <BuyoutNumberCell buyout={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <BuyoutCustomHeader
        tableProps={props}
        title={'BUYOUTS_COLUMNS_CONTENT'}
        className='min-w-125px'
      />
    ),
    id: 'items',
    Cell: ({...props}) => <BuyoutItemsInfoCell buyout={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <BuyoutCustomHeader
        tableProps={props}
        title={'BUYOUTS_COLUMNS_STATUS'}
        className='min-w-125px text-center'
      />
    ),
    id: 'status',
    Cell: ({...props}) => <BuyoutStatusCell buyout={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <BuyoutCustomHeader
        tableProps={props}
        title={'COLUMNS_ACTIONS'}
        className='min-w-120px text-end'
      />
    ),
    id: 'actions',
    Cell: ({...props}) => <BuyoutActionsCell id={props.data[props.row.index].id} />,
  },
]

export {buyoutsColumns}
