import {useFormikContext} from 'formik'
import {useIntl} from 'react-intl'
import {InputTemplate} from '../../../../modules/custom/form-elements/InputTemplate'
import {Notification} from '../../../../modules/custom/Notification'
import {InspectionConditions} from '../../../buyouts/_modals/tabs/goods/InspectionConditions'
import {AddTrackForm} from '../../core/_models/_tracks-models'

interface Props {
  values: AddTrackForm
}

export function TrackInspectionTab({values}: Props) {
  const {setFieldValue} = useFormikContext()
  const intl = useIntl()

  // const sendErrors = () => {
  //   const obj = {
  //     formikContext,
  //     window,
  //     currentUser,
  //   }
  //   console.log('formikContext', formikContext)
  //   console.log('rec', values.receiver, receiversListData)

  //   // JSON.stringify(obj)
  // }

  // useEffect(() => {
  //   console.log('js', JSON.stringify(formikContext))

  //   const sendStuffToServer = () => {
  //     sendErrors()
  //     console.log('w', window)
  //   }

  //   window.onerror = sendStuffToServer

  //   return () => {
  //     window.onerror = null
  //   }
  // }, [])

  return (
    <div className='w-100'>
      {values.invoiceLinks && values.invoiceLinks[0] === '' && !values.invoice_file && (
        <div className='mb-3'>
          <Notification
            noteText={intl.formatMessage({
              id: 'TRACK_PAGE.INSPECTION_TAB.UPL_INVOICE',
            })}
          />
        </div>
      )}
      <div className='fv-row mb-10'>
        <InputTemplate
          inputName='orderInspection'
          title={intl.formatMessage({
            id: 'TRACKS.ADD_MODAL.FORM.INSPECTION_TITLE',
          })}
          type='checkbox'
          checkboxProps={{
            checked: values.orderInspection,
            disabled:
              values.invoiceLinks && values?.invoiceLinks[0] === '' && !values.invoice_file
                ? true
                : false,
            setFieldValue: setFieldValue,
            value: 1,
            inputName: 'orderInspection',
            type: 'checkbox',
          }}
          containerMarginBottom={false}
        />

        <InspectionConditions />
      </div>
      {values.orderInspection === true && (
        <div className='fv-row mb-10'>
          <InputTemplate
            type='textarea'
            inputName='inspectionDescription'
            title={intl.formatMessage({
              id: 'TRACKS.ADD_MODAL.FORM.INSPECTION_DESC_TITLE',
            })}
            required={true}
          />
        </div>
      )}
      {((values.invoiceLinks && values.invoiceLinks[0] !== '') || values.invoice_file) && (
        <div className='fv-row mb-10'>
          <Notification
            noteText={intl.formatMessage({
              id: 'TRACKS.ADD_MODAL.FORM.INSPECTION_PROMPT',
            })}
          />
        </div>
      )}
    </div>
  )
}
