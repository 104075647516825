const QUERIES = {
  USERS_LIST: 'users-list',
  RECEIVERS_LIST: 'receivers-list',
  BALANCE_LIST: 'balance-list',
  BALANCE_CURRENT: 'balance-current',
  TRACKS_LIST: 'tracks-list',
  ARCHIVED_TRACKS_LIST: 'archived-tracks-list',
  BUYOUTS_LIST: 'buyouts-list',
  ARCHIVED_BUYOUTS_LIST: 'archived-buyouts-list',
  PARCELS_LIST: 'parcels-list',
  DASHBOARD_INFO: 'dashboard-info',
  PRODUCTS_LIST: 'fulfillment-products-list',
}

const LS_KEYS = {
  PAGINATION_KEY: 'tablePagination',
}

const GTM_SCRIPTS = (id: string) => {
  return {
    dateTime: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${id}');`,

    iframe: `<iframe src='https://www.googletagmanager.com/ns.html?id=${id}'height='0' width='0' style='display:none;visibility:hidden'></iframe>`,
  }
}
export {QUERIES, LS_KEYS, GTM_SCRIPTS}
