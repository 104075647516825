import {FieldArray, useFormikContext} from 'formik'
import {ISetFieldValue} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {AddTrackForm, ITrackFormProds} from '../../core/_models/_tracks-models'
import {TrackGoodsItem} from './TrackGoodsItem'
import {FormattedMessage} from 'react-intl'
import {Dispatch, SetStateAction, useEffect} from 'react'
import {checkForAnyCommercialProducts} from '../../../../../_metronic/helpers/custom/funcs/checkForCommercial'
import {earlySubmit} from '../../../../../_metronic/helpers/custom/funcs/earlySubmit'
import {usePageData} from '../../../../../_metronic/layout/core'

interface Props {
  prods: ITrackFormProds[]
  setFieldValue: ISetFieldValue
  values: AddTrackForm
  currentStep: number
  setDisabledCommercialSettings?: Dispatch<SetStateAction<boolean>>
}

export function TrackGoods({
  prods,
  setFieldValue,
  values,
  currentStep,
  setDisabledCommercialSettings,
}: Props) {
  const formikContext = useFormikContext()
  const {pageCustomData} = usePageData()

  const {validateForm, setTouched, isValid} = useFormikContext()

  useEffect(() => {
    const anyComProds = checkForAnyCommercialProducts(values.prods)

    validateForm()
      .then((errs) => {
        setTouched({})

        values.prods.forEach((prod, index) => {
          Object.keys(prod).forEach((key) => {
            setTouched({
              [`prods[${index}].${key}`]: false,
            })
          })
        })
        return errs
      })
      .then((errors) => {
        if (pageCustomData?.trackFormSimplified === true && setDisabledCommercialSettings) {
          setDisabledCommercialSettings(anyComProds)
        } else {
          earlySubmit({
            currentStep,
            setFieldValue,
            values,
            noFormErrors: Object.keys(errors).length === 0,
            anyComProds,
          })
        }
      })
  }, [values.prods])

  return (
    <div className='d-flex flex-column'>
      {pageCustomData?.trackFormSimplified === true && (
        <div className='mb-3'>
          <h3 className='fw-bolder'>
            Товари треку
            {/* <FormattedMessage id={'Товари треку'} /> */}
          </h3>
        </div>
      )}{' '}
      <div className='w-100'>
        <FieldArray
          name='prods'
          render={(arrayHelpers) => (
            <div className='d-flex flex-column flex-col-gutter'>
              {prods.map((item, index) => (
                <TrackGoodsItem
                  key={index}
                  index={index}
                  item={item}
                  arrayHelpers={arrayHelpers}
                  prods={prods}
                  setFieldValue={setFieldValue}
                  values={values}
                />
              ))}
              <div className='separator mb-3'></div>

              <div>
                <button
                  className='btn btn-sm btn-secondary d-flex align-items-center'
                  type='button'
                  onClick={() => {
                    arrayHelpers.push({
                      group: {label: '', value: ''},
                      name: {label: '', value: '', require_invoice: 0, brands: []},
                      qty: 1,
                      cost: '',
                      total: '',
                      repack: false,
                    })
                  }}
                >
                  <FormattedMessage id='GENERAL.BUTTONS.ADD_GOODS' />
                </button>
              </div>
            </div>
          )}
        />
      </div>
      {/* solve the issue of the object of errors for each property instead of the minimum number of items */}
      {/* <div className='text-danger'>
        <ErrorMessage name='prods' />
      </div> */}
    </div>
  )
}
