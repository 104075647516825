import axios from 'axios'
import {Receiver} from '../../../../app/pages/receivers/receivers-list/core/_models'
import {getConfig} from '../../../i18n/Metronici18n'
import {ITariffs} from '../tsHelpers/generalHelpers'

const API_URL = process.env.REACT_APP_API_PATH
const PUBLIC_URL = `/public`
const BASE_URL = `${API_URL}${PUBLIC_URL}`

const getSpecificCountryByCode = async (code: string) => {
  const {data} = await axios.get<any>(`${BASE_URL}/directions/countries?code=${code}`)

  return data[Object.keys(data)[0]]
}

const getSymbolByCode = async (code: string) => {
  const {data} = await axios.get<any>(`${BASE_URL}/directions/currencies?code=${code}&symbol=true`)
  return data[Object.keys(data)[0]]
}

const getListOfCurrencies = async () => {
  const {data} = await axios.get<any>(`${BASE_URL}/directions/currencies`)

  return data
}

const getWeightUnits = async () => {
  const {data} = await axios.get<any>(`${BASE_URL}/directions/weight-units`)

  return data
}
const getSizeUnits = async () => {
  const {data} = await axios.get<any>(`${BASE_URL}/directions/size-units`)

  return data
}

const getReceiversList = async () => {
  const {selectedLang} = getConfig()

  const {data} = await axios.get(`${API_URL}/track/receivers`, {
    headers: {
      'Accept-Language': selectedLang,
    },
  })

  const reformedData = data.map((item: Receiver) => {
    return {
      value: item.id,
      label: `${item.lastName || ''} ${item.firstName || ''} ${item.patronymic || ''}`,
    }
  })

  return reformedData
}

const getTariffs = async () => {
  const {data} = await axios.get<ITariffs | undefined>(`${API_URL}/track/tariffs`)

  return data
}

export const generalApi = {
  getSpecificCountryByCode,
  getSymbolByCode,
  getListOfCurrencies,
  getWeightUnits,
  getSizeUnits,
  getReceiversList,
  getTariffs,
}
