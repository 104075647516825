const CHAT_KEY = 'kt-chat-react'

export const setChatTokenForAxios = (token: string) => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.setItem(CHAT_KEY, token)
  } catch (error) {
    console.error('CHAT TOKEN LOCAL STORAGE SAVE ERROR', error)
  }
}

export const getChatToken = (): string | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(CHAT_KEY)
  if (!lsValue) {
    return
  }

  if (lsValue) {
    return lsValue
  }
}

export const removeChatToken = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(CHAT_KEY)
  } catch (error) {
    console.error('CHAT TOKEN LOCAL STORAGE REMOVE ERROR', error)
  }
}
