import {
  GoodsCategoriesQueryResponse,
  IBuyoutCategorySelectOption,
  ICatBrandSelectOption,
  IGoodsCategoryItem,
  IGoodsGroupSelectOption,
  ISetFieldValue,
  ITrackCategorySelectOption,
} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {IBasicSelect} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {FormattedMessage, useIntl} from 'react-intl'
import Select, {components, createFilter} from 'react-select'
import AsyncSelect from 'react-select/async'

import {customStyles} from '../../form-elements/select/ReactSelectTemplate'
import {ErrorMessage, FormikTouched, useFormikContext} from 'formik'
import {Dispatch, SetStateAction} from 'react'

interface Props {
  setFieldValue: ISetFieldValue
  value: IBuyoutCategorySelectOption | ITrackCategorySelectOption
  options: IBuyoutCategorySelectOption[] | ITrackCategorySelectOption[]
  inputName: string
  isLoading: boolean
  noOptionsMessage?: string
  disabled?: boolean
  onInputChange: (query: string) => void
  apiRequest: (cat: string, groupId: string | number) => Promise<GoodsCategoriesQueryResponse>
  selectValues: {
    groups: IGoodsGroupSelectOption
    categories: IBuyoutCategorySelectOption | ITrackCategorySelectOption
    brands: ICatBrandSelectOption
  }
  setCategoriesResults: Dispatch<
    SetStateAction<IBuyoutCategorySelectOption[] | ITrackCategorySelectOption[]>
  >
  setResultsLoading: Dispatch<SetStateAction<boolean>>
}

export function SearchCategorySelect({
  setFieldValue,
  value,
  inputName,
  isLoading,
  noOptionsMessage = 'Не найдено',
  disabled = false,
  options,
  onInputChange,
  apiRequest,
  selectValues,
  setCategoriesResults,
  setResultsLoading,
}: Props) {
  const intl = useIntl()
  const {touched, errors} = useFormikContext()

  const NoOptionsMessage = (props: any) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className='custom-css-class'>{noOptionsMessage}</span>
      </components.NoOptionsMessage>
    )
  }

  const {groups} = selectValues

  const filterCategories = (inputValue: string) => {
    console.log('i', inputValue, 'coptipns', options)

    return options.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()))
  }

  // const filterCategories = (inputValue: string) => {
  //   const lowerCasedInput = inputValue.toLowerCase()

  //   const filteredOptions = options.filter((i) => i.label.toLowerCase().includes(lowerCasedInput))
  //   const remainingOptions = options.filter((i) => !i.label.toLowerCase().includes(lowerCasedInput))
  //   console.log('i', [...filteredOptions, ...remainingOptions])

  //   return [...filteredOptions, ...remainingOptions]
  // }

  const loadOptions = (inputValue: string, callback: any) => {
    if (!inputValue) {
      callback(options)
      return
    }

    setResultsLoading(true)
    onInputChange(inputValue)

    apiRequest(inputValue, selectValues.groups.value || '')
      .then((res) => {
        let reformedCategories: IBuyoutCategorySelectOption[] | ITrackCategorySelectOption[]
        if (groups.value === '') {
          const items = res
            .flatMap((group) =>
              group.items.map((item) => {
                return {...item, group: {value: group.id, label: group.name}}
              })
            )
            .filter((item) => item !== null)

          reformedCategories = items.map((item: IGoodsCategoryItem) => {
            return {
              ...item,
              value: item.id,
              label:
                item.synonym && inputValue ? item.translate + ` (${item.synonym})` : item.translate,
              brands: item.brands.map((item) => {
                return {...item, label: item.name, value: item.id}
              }),
            }
          })
        } else {
          const group = res.find((item) => groups.value === item.id)

          if (group) {
            reformedCategories = group.items.map((item: IGoodsCategoryItem) => {
              return {
                ...item,
                value: item.id,
                label:
                  item.synonym && inputValue
                    ? item.translate + ` (${item.synonym})`
                    : item.translate,
                group: {value: group.id, label: group.name},
                brands: item.brands.map((item) => {
                  return {...item, label: item.name, value: item.id}
                }),
              }
            })
          } else {
            reformedCategories = []
          }
        }
        setCategoriesResults(reformedCategories)
        callback(reformedCategories)
      })
      .catch((err) => {
        console.log(err)
        callback([])
      })
      .finally(() => setResultsLoading(false))
  }

  const handleOnChange = (selectedValue: IBasicSelect | null) => {
    if (selectedValue !== null) {
      setFieldValue(inputName, selectedValue)
    } else {
      setFieldValue(inputName, {value: '', label: ''})
    }
  }
  return (
    <>
      <div className={`fv-row d-flex w-100 mb-5 flex-column`}>
        <label
          className={`d-flex position-relative  fs-5 flex-column fw-bold mb-2 w-100`}
          htmlFor={inputName}
        >
          <div className='mb-2'>
            {' '}
            <span className={`fs-6 required`}>
              <FormattedMessage id='COMPONENTS.SearchCategorySelect.CATEGORY' />
            </span>
          </div>
          <AsyncSelect
            formatOptionLabel={({value, label, descr, image}) => (
              <div className='tagify__dropdown__item d-flex align-items-center' tabIndex={0}>
                <div className='tagify__dropdown__item__avatar-wrap me-2'>
                  <img
                    alt=''
                    className='rounded-circle w-50px me-2'
                    src={image || '/media/icons/duotune/ecommerce/ecm002.svg'}
                  />
                </div>

                <div className='d-flex flex-column text-gray-800'>
                  <strong>{label}</strong>
                  {descr && <span className='fs-6 fw-normal'>{descr}</span>}
                </div>
              </div>
            )}
            styles={customStyles}
            loadOptions={loadOptions}
            defaultOptions={options}
            form={inputName}
            isClearable={value.value === '' ? false : true}
            isDisabled={disabled}
            components={{NoOptionsMessage}}
            loadingMessage={() => intl.formatMessage({id: 'GENERAL.BUTTONS.WAIT'})}
            placeholder={intl.formatMessage({
              id: 'COMPONENTS.SearchCategorySelect.PLACEHOLDER',
            })}
            isLoading={isLoading}
            value={value}
            onInputChange={(e) => {
              onInputChange(e)
              console.log('e', e)
            }}
            onChange={handleOnChange}
          />
        </label>
        {/* {touched[inputName as keyof FormikTouched<unknown>] &&
          errors[inputName as keyof FormikTouched<unknown>] && ( */}
        <div className='text-danger'>
          <ErrorMessage name={`${inputName}.value`} />
        </div>
        {/* )} */}
      </div>
    </>
  )
}
