import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../_metronic/helpers'
import {GoodsCategoriesQueryResponse} from '../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {getConfig} from '../../../../_metronic/i18n/Metronici18n'
import {
  ICreateNewTrack,
  IEditTrack,
  Track,
  TracksQueryResponse,
} from '../core/_models/_tracks-models'

const API_URL = process.env.REACT_APP_API_PATH
const TRACKS_URL = `/track`
const BASE_URL = `${API_URL}${TRACKS_URL}`

const getTracks = (query: string): Promise<TracksQueryResponse> => {
  const {selectedLang} = getConfig()

  return axios
    .get(`${BASE_URL}?${query}`, {
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((d: AxiosResponse<TracksQueryResponse>) => d.data)
}

const getTrackById = (id: ID): Promise<Track | undefined> => {
  const {selectedLang} = getConfig()

  return axios
    .get(`${BASE_URL}/getById`, {
      params: {id},
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((response: AxiosResponse<Track>) => response.data)
}

const checkTrack = async (code: string | number) => {
  const {selectedLang} = getConfig()

  const {data} = await axios.post(`${BASE_URL}/check_track`, {
    code,
    headers: {
      'Accept-Language': selectedLang,
    },
  })
  // console.log('check track res', data)

  return data
}

const addTrack = (track: ICreateNewTrack): Promise<Track | undefined> => {
  return axios
    .post(`${BASE_URL}/add`, track)
    .then((response: AxiosResponse<Response<Track>>) => response.data)
    .then((response: Response<Track>) => response.data)
}

const editTrack = (receiver: IEditTrack): Promise<Track | undefined> => {
  return axios
    .post(`${BASE_URL}/edit`, receiver)
    .then((response: AxiosResponse<Response<Track>>) => response.data)
    .then((response: Response<Track>) => response.data)
}

const archiveTrack = async (id: ID, status: number) => {
  const res = await axios.get(`${BASE_URL}/archive?id=${id}&state=${status}`, {
    params: {id},
  })
  return res
}

const setAllTracks = async (obj: {tracksToSet: number; tracksStatusToSet: number}) => {
  const {selectedLang} = getConfig()

  const {data} = await axios.post(
    `${BASE_URL}/set_all`,
    {
      type_delive: obj.tracksToSet,
      send: obj.tracksStatusToSet,
    },
    {
      headers: {
        'Accept-Language': selectedLang,
      },
    }
  )
  console.log('check track set all res', data)

  return data
}

// type_delive - тип доставки
// send - 1|0 відправити|заборонити відправку
// відповідь
// success true|false результат операції

// invoices

const uploadInvoice = async (name: string, file: File) => {
  let formData = new FormData()
  formData.append(name, file as any)

  try {
    const {data} = await axios.post(`${BASE_URL}/upload_invoice`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    // console.log('uploadInvoice response', data)
    return data
  } catch (err) {
    console.log(err)
  }
}

const getRecentInvoices = async () => {
  try {
    const {data} = await axios.get(`${BASE_URL}/recent_invoices`)
    // console.log('getRecentInvoices response', data)
    return data
  } catch (err) {
    console.log(err)
  }
}
const getTrackCategories = async (
  category: string,
  group: string | number
): Promise<GoodsCategoriesQueryResponse> => {
  const {selectedLang} = getConfig()

  return axios
    .post(
      `${BASE_URL}/nomcats`,
      {
        value: category,
        group,
      },
      {
        headers: {
          'Accept-Language': selectedLang,
        },
      }
    )
    .then((d: AxiosResponse<GoodsCategoriesQueryResponse>) => d.data)
}

export {
  getTracks,
  uploadInvoice,
  getRecentInvoices,
  checkTrack,
  addTrack,
  editTrack,
  getTrackById,
  setAllTracks,
  archiveTrack,
  getTrackCategories,
}
