import {useFormikContext} from 'formik'
import {ISetFieldValue} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {IInvoice, IRecentInvoiceReformed} from '../../core/_models/_tracks-models'
import {FormattedMessage, useIntl} from 'react-intl'

interface Props {
  item: IRecentInvoiceReformed
  handleInvoiceArray: (item: IInvoice, itemIsAdded: boolean, setFieldValue: ISetFieldValue) => void
  addedInvoices: IInvoice[]
  id: string
}

export function RecentInvoiceRow({item, handleInvoiceArray, addedInvoices, id}: Props) {
  const intl = useIntl()

  const {track_code, file, name} = item
  const itemIsAdded = addedInvoices.find((inv) => inv.file.filename === item.file.filename)
  const {setFieldValue} = useFormikContext()
  return (
    <tr>
      <td>
        <p className='p-0'> {track_code}</p>
      </td>
      <td>
        <img key={file.filename} src={file.base_url + file.filename} alt={name} width='100px' />
      </td>
      <td className='text-center'>{name}</td>
      <td>
        <button
          type='button'
          className='btn btn-sm btn-secondary me-3'
          onClick={() => {
            handleInvoiceArray({file, name}, itemIsAdded ? true : false, setFieldValue)
          }}
        >
          <FormattedMessage
            id={itemIsAdded ? 'GENERAL.BUTTONS.DELETE' : 'GENERAL.BUTTONS.ADD'}
            values={{item: null}}
          />
        </button>
      </td>
    </tr>
  )
}
