import {KTSVG} from '../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
import {FormattedMessage, useIntl} from 'react-intl'

const BuyoutModalHeader = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const intl = useIntl()

  return (
    <div className='modal-header'>
      {/* begin::Modal title */}
      <h2 className='fw-bolder'>
        {itemIdForUpdate ? (
          <FormattedMessage id='BUYOUTS.ADD_MODAL.HEADER.EDIT' />
        ) : (
          <FormattedMessage id='BUYOUTS.ADD_MODAL.HEADER.CREATE' />
        )}
      </h2>
      {/* end::Modal title */}

      {/* begin::Close */}
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-users-modal-action='close'
        onClick={() => setItemIdForUpdate(undefined)}
        style={{cursor: 'pointer'}}
      >
        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
      </div>
      {/* end::Close */}
    </div>
  )
}

export {BuyoutModalHeader}
