import {ITrackPackageItem} from '../../core/_models/_tracks-models'

interface Props {
  track_package: ITrackPackageItem
}

function AllTrackParcelsItem({track_package}: Props) {
  return (
    <tr>
      <td>
        <span className='text-dark text-hover-primary d-block fs-6'>{track_package.c_code}</span>
      </td>
    </tr>
  )
}

export {AllTrackParcelsItem}
