import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {FormattedMessage} from 'react-intl'
import {KTSVG} from '../../../../../_metronic/helpers'
import {statusColor} from '../../../../../_metronic/helpers/custom/funcs/statusColor'
import {Track} from '../../core/_models/_tracks-models'

interface Props {
  track: Track
  isArchive: boolean
}
export function TrackHeaderInfo({track, isArchive}: Props) {
  const {track_code, type_delive, dispatch, weight, length, width, height, cost, prods} = track
  return (
    <div className='flex-grow-1 w-100'>
      <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
        <div className='d-flex flex-column overflow-auto overflow-md-unset overflow-lg-unset'>
          <div className='d-flex align-items-md-center mb-4 align-items-start flex-column flex-md-row justify-content-between justify-content-md-start'>
            <div className='d-flex align-items-center mb-2 mb-md-none '>
              {+type_delive === 0 ? (
                <KTSVG
                  path='/media/icons/flight.svg'
                  className='svg-icon-primary svg-icon-3 me-1'
                />
              ) : (
                <KTSVG
                  path='/media/icons/anchor.svg'
                  className='svg-icon-primary svg-icon-3 me-1'
                />
              )}
              <p className='text-gray-800 m-0 fs-6 fs-md-2 fw-bolder me-3 d-none d-md-block'>
                {track_code}
              </p>
              <div className='d-md-none d-lg-none'>
                <OverlayTrigger placement={'top'} overlay={<Tooltip>{track_code}</Tooltip>}>
                  <p className='text-gray-800 m-0 fs-6 fs-md-2 fw-bolder me-3'>{track_code}</p>
                </OverlayTrigger>
              </div>
            </div>
            {isArchive === false && (
              <span
                className={`p-1 fs-8 fs-md-7 me-2 fw-semobold  StatusBar__status d-block ${statusColor(
                  dispatch
                )}`}
              >
                {dispatch === 1 ? (
                  <FormattedMessage id='GENERAL.STATUSES.SHIPMENT_ALLOWED' />
                ) : (
                  <FormattedMessage id='GENERAL.STATUSES.SHIPMENT_PROHIBITED' />
                )}
              </span>
            )}
          </div>

          <div className='d-flex flex-wrap fw-bold fs-7 mb-4 pe-2'>
            <span className='d-flex align-items-center text-gray-400 me-5 mb-2'>
              <KTSVG
                path='/media/icons/duotune/electronics/elc008.svg'
                className='svg-icon-4 me-1'
              />
              {weight ? `${weight} кг` : '--,'}
            </span>

            <span className='d-flex align-items-center text-gray-400 me-5 mb-2'>
              {`${height ? `${height}см` : '--'} x ${width ? `${width}см` : '--'} x ${
                length ? `${length}см` : '--'
              }`}
            </span>

            {prods.length > 0 ? (
              <span className='d-flex align-items-center text-gray-400 me-5 mb-2'>
                <span className='me-1'>$</span>
                <span>{cost}</span>
              </span>
            ) : (
              <></>
            )}
          </div>
        </div>{' '}
      </div>
    </div>
  )
}
