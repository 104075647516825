/* eslint-disable jsx-a11y/anchor-is-valid */
// import {KTSVG} from '../../../../../../../_metronic/helpers'
import {Parcel} from '../../../../core/_models/_parcels-models'

type Props = {
  parcel: Parcel
}

const ParcelActionsCell = ({parcel}: Props) => {
  return (
    <div className='d-flex flex-gutter justify-content-end flex-shrink-0'>
      <a href='#' className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm show-chat' data-id={parcel.id} data-type="Parcel" data-name={parcel.c_code}>
        {/*<KTSVG path='/media/icons/duotune/communication/com003.svg' className='svg-icon-3' />*/}
      </a>
    </div>
  )
}

export {ParcelActionsCell}
