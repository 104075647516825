import {useIntl} from 'react-intl'
import {useQueryClient} from 'react-query'
import {ITariffs} from '../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'

interface Props {
  delivery_type: number // 1 - sea, 0 -avia
  parcel_type: 1 | 0 // 1 - com, 0 - non-com
}

export function TrackTarrifs({delivery_type, parcel_type}: Props) {
  const intl = useIntl()
  const queryClient = useQueryClient()

  const tariffsListData: ITariffs | undefined = queryClient.getQueryData('tariffsList')
  return (
    <>
      {parcel_type === 1 && (
        <>
          <p className='text-muted fs-6 m-0'>
            <b>
              {` $${
                tariffsListData
                  ? delivery_type === 0
                    ? tariffsListData.avia_com
                    : tariffsListData.sea_com
                  : '0'
              }
                                /${intl.formatMessage({id: 'GENERAL.MEASURES.KG'})}`}
            </b>
            {intl.formatMessage({id: 'TRACKS.ADD_MODAL.FORM.TARIFF_DESC'})}
          </p>
          <p
            className='text-muted fs-6 m-0'
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: 'TRACKS.ADD_MODAL.FORM.COMMERCIAL.DESC',
              }),
            }}
          ></p>
        </>
      )}

      {parcel_type === 0 && (
        <>
          <p className='text-muted fs-6 m-0'>
            <b>{` $${
              tariffsListData
                ? delivery_type === 0
                  ? tariffsListData.avia
                  : tariffsListData.sea
                : '0'
            }/${intl.formatMessage({id: 'GENERAL.MEASURES.KG'})}`}</b>{' '}
            {intl.formatMessage({id: 'TRACKS.ADD_MODAL.FORM.TARIFF_DESC'})}
          </p>
          <p
            className='text-muted fs-6 m-0'
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: 'TRACKS.ADD_MODAL.FORM.NON_COMM_DESC',
              }),
            }}
          ></p>
        </>
      )}
    </>
  )
}
