import {IDashboardInfo, IDashboardItem} from './core/_models'

export const dashboardData = (
  dashboard: IDashboardInfo,
  userBalance: number | string,
  intl: any
): IDashboardItem[][] => {
  const {buyout, parcel, track, balance} = dashboard

  return [
    [
      {
        title: intl.messages.DASHBOARD_BUYOUTS_TITLE,
        number: Object.values(buyout).reduce((a, b) => a + b, 0),
        linkTo: 'buyouts',
        blank: false,
        type: 'buyout',
        rows: [
          {title: intl.messages.DASHBOARD_B_COMP_TO_CONF_ROW_TITLE, stat: buyout.created},
          {title: intl.messages.DASHBOARD_B_CLIENT_TO_CONF_ROW_TITLE, stat: buyout.approved},
          {title: intl.messages.DASHBOARD_B_IN_USA_WH_ROW_TITLE, stat: buyout.in_storage},
        ],
      },
      {
        title: intl.messages.DASHBOARD_TRACKS_TITLE,
        number: track.await + track.in_storage + track.in_storage_stop,
        linkTo: 'tracks',
        blank: false,
        type: 'track',
        rows: [
          {title: intl.messages.DASHBOARD_T_EXPECTED_ROW_TITLE, stat: track.await},
          {title: intl.messages.DASHBOARD_T_ARRIVED_ROW_TITLE, stat: track.in_storage},
          {title: intl.messages.DASHBOARD_T_SHIPMENT_PROH_ROW_TITLE, stat: track.in_storage_stop},
        ],
      },
    ],
    [
      {
        title: intl.messages.DASHBOARD_PARCELS_TITLE,
        number: parcel.sent + parcel.arrived + parcel.sent_in_here,
        linkTo: 'parcels',
        blank: false,
        type: 'parcel',
        rows: [
          {title: intl.messages.DASHBOARD_P_COMING_ROW_TITLE, stat: parcel.sent},
          {title: intl.messages.DASHBOARD_P_ARRIVED_ROW_TITLE, stat: parcel.arrived},
          {
            title: intl.messages.DASHBOARD_P_TO_BE_PAID_ROW_TITLE,
            stat: parcel.detained,
            add: parcel.to_release,
          },
        ],
      },
      {
        title: intl.messages.DASHBOARD_BALANCE_TITLE,
        number: +userBalance,
        linkTo: 'balance',
        blank: false,
        type: 'balance',
        rows: [
          {
            title: intl.messages.DASHBOARD_BAL_LAST_PLUS_ROW_TITLE,
            stat: balance.plus?.sum ? `$${balance.plus.sum}` : '-',
            date: `${balance.plus?.time ? balance.plus.time : ''}`,
          },
          {
            title: intl.messages.DASHBOARD_BAL_LAST_MINUS_ROW_TITLE,
            stat: balance.minus?.sum ? `$${balance.minus.sum}` : '-',
            date: `${balance.minus?.time ? balance.minus.time : ''}`,
          },
        ],
      },
    ],
  ]
}
