import {useCallback, useState} from 'react'
import {Formik, Form} from 'formik'
import {InputTemplate} from '../../../../modules/custom/form-elements/InputTemplate'
import {CardFooter} from '../../../../modules/custom/form-elements/CardFooter'
import {ITrackPageInspectionTab} from '../../core/_models/_track-page-models'
import {trackPageInspectionSchema} from '../../core/yup/track-page'
import {Track} from '../../core/_models/_tracks-models'
import {editTrack} from '../../core/_requests'
import {ImageLightbox} from '../../../../modules/custom/images/ImageLightbox'
import Gallery from 'react-photo-gallery'
import {InspectionConditions} from '../../../buyouts/_modals/tabs/goods/InspectionConditions'
import {FormattedMessage, useIntl} from 'react-intl'
import {Notification} from '../../../../modules/custom/Notification'

interface Props {
  track: Track
  setCurrTrack: (obj: Track) => void
  isArchive: boolean
}

export function TrackInspection({track, setCurrTrack, isArchive}: Props) {
  const intl = useIntl()
  const initialValues = {
    orderInspection: track.check_control === 1 ? true : false,
    inspectionDescription: track.check_control_desc,
  }

  const {check_control_answer, check_answer_files, cost, invoice_empty} = track

  const [loading, setLoading] = useState(false)

  const [currentImage, setCurrentImage] = useState(0)

  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  interface ILightbox {
    index: number
  }
  const [success, setSuccess] = useState(false)

 
  const openLightbox = useCallback((event: any, {index}: ILightbox) => {
    setCurrentImage(index)
    setViewerIsOpen(true)
  }, [])

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  const cancelInspection = async () => {
    setLoading(true)
    const response = await editTrack({
      ...track,
      receiver: track?.receiver?.id ? +track?.receiver?.id : null,
      check_control: 0,
      check_control_desc: '',
      invoice_file:
        track.invoice_file.map((item) => {
          return {name: item.name, tmp_name: item.file.filename}
        }) || undefined,
    })

    if (response) {
      setCurrTrack(response)
      setLoading(false)
      setSuccess(true)

  setTimeout(() => {
    setSuccess(false)
  }, 3000)
    }
  }

  const handleSubmit = async (values: ITrackPageInspectionTab) => {
    const {orderInspection, inspectionDescription} = values

    setLoading(true)
    const response = await editTrack({
      ...track,
      receiver: track?.receiver?.id ? +track?.receiver?.id : null,
      check_control: orderInspection === true ? 1 : 0,
      check_control_desc: inspectionDescription,
      invoice_file:
        track.invoice_file.map((item) => {
          return {name: item.name, tmp_name: item.file.filename}
        }) || undefined,
    })

    if (response) {
      setCurrTrack(response)
      setLoading(false)

        setSuccess(true)

        setTimeout(() => {
          setSuccess(false)
        }, 3000)
    }
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <Formik
        validationSchema={trackPageInspectionSchema(intl)}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({values, setFieldValue}) => (
          <Form noValidate className='form'>
            <div className='card-body border-top p-9'>
              <div className='row mb-6 d-flex align-items-start'>
                <label className='col-lg-4 col-form-label  fw-bold fs-6 pt-0'>
                  <FormattedMessage id='TRACK_PAGE.INSPECTION_TAB.ORDER' />
                </label>

                <div className='col-lg-8'>
                  <div className='row'>
                    {invoice_empty === true && (
                      <div className='col-lg-8 mb-3'>
                        <Notification
                          noteText={intl.formatMessage({
                            id: 'TRACK_PAGE.INSPECTION_TAB.UPL_INVOICE',
                          })}
                        />
                      </div>
                    )}
                    <div className='col-lg-6 fv-row'>
                      <InputTemplate
                        inputName='orderInspection'
                        type='checkbox'
                        checkboxProps={{
                          checked: values.orderInspection,
                          disabled:
                            isArchive === true || invoice_empty === true
                              ? true
                              : track.check_control_answer || track.track_package.length > 0
                              ? true
                              : false,
                          setFieldValue: setFieldValue,
                          value: 1,
                          inputName: 'orderInspection',
                          type: 'checkbox',
                        }}
                        containerMarginBottom={false}
                      />
                    </div>

                    <div className='fv-row text-danger  mt-3'>
                      <InspectionConditions />
                    </div>
                  </div>
                </div>
              </div>
              {values.orderInspection === true && (
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label  fw-bold fs-6'>
                    <FormattedMessage id='TRACK_PAGE.INSPECTION_TAB.DETAILS' />
                  </label>

                  <div className='col-lg-8'>
                    <div className='row'>
                      <InputTemplate
                        type='textarea'
                        disabled={
                          isArchive === true
                            ? true
                            : track.check_control_answer || track.track_package.length > 0
                            ? true
                            : false
                        }
                        inputName='inspectionDescription'
                        title={intl.formatMessage({
                          id: 'TRACKS.ADD_MODAL.FORM.INSPECTION_DESC_TITLE',
                        })}
                        required={true}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            {isArchive === false &&
              invoice_empty !== true &&
              !track.check_control &&
              !track.check_control_answer &&
              track.track_package.length < 1 && (
                <CardFooter
                  buttonText={intl.formatMessage({id: 'TRACK_PAGE.INSPECTION_TAB.ORDER_BUTTON'})}
                  loading={loading} success={success}
                />
              )}
            {track.check_control === 1 && !track.check_control_answer && (
              <CardFooter
                buttonText={intl.formatMessage({id: 'TRACK_PAGE.INSPECTION_TAB.CANCEL_BUTTON'})}
                loading={loading} success={success}
                handleApi={cancelInspection}
              />
            )}
          </Form>
        )}
      </Formik>{' '}
      {track.check_control_answer && (
        <div className='card-body border-top p-9'>
          <div className='row mb-6 '>
            <label className='col-lg-4 col-form-label  fw-bold fs-6'>
              <p>
                <FormattedMessage id='TRACK_PAGE.INSPECTION_TAB.RESULTS' />
              </p>
            </label>

            <div className='col-lg-8'>
              {' '}
              <div className='col-lg-6 fv-row mb-6'>
                <FormattedMessage
                  id='TRACK_PAGE.INSPECTION_TAB.FULL_COST'
                  values={{cost, currency: '$'}}
                />
              </div>
              <div className='row '>
                <div className='fv-row'>
                  {check_control_answer && <p>{check_control_answer}</p>}
                  {check_answer_files && (
                    <>
                      <Gallery
                        margin={10}
                        photos={check_answer_files.map((item, index) => {
                          return {
                            src: item,
                            alt: `photo-${index}`,
                            width: 1.5,
                            height: 1,
                          }
                        })}
                        onClick={openLightbox}
                      />
                      <ImageLightbox
                        viewerIsOpen={viewerIsOpen}
                        closeLightbox={closeLightbox}
                        currentImage={currentImage}
                        images={check_answer_files.map((item, index) => {
                          return {src: item, title: `photo-${index}`}
                        })}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
