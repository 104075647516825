import {useListView} from '../../core/ListViewProvider'
import {ReceiversListToolbar} from './ReceiversListToolbar'
import {ReceiversListGrouping} from './ReceiversListGrouping'
import {ReceiversListSearchComponent} from './ReceiversListSearchComponent'

const ReceiversListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <ReceiversListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <ReceiversListGrouping /> : <ReceiversListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {ReceiversListHeader}
