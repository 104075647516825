import {Dispatch, SetStateAction} from 'react'
import {TracksListFilter} from './TracksListFilter'

interface Props {
  warehouseTracks: boolean
  setWarehouseTracks: Dispatch<SetStateAction<boolean>>
  unpackedTracks: boolean
  setUnpackedTracks: Dispatch<SetStateAction<boolean>>
}

const TracksListToolbar = ({
  warehouseTracks,
  setWarehouseTracks,
  unpackedTracks,
  setUnpackedTracks,
}: Props) => {
  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      <TracksListFilter
        warehouseTracks={warehouseTracks}
        setWarehouseTracks={setWarehouseTracks}
        unpackedTracks={unpackedTracks}
        setUnpackedTracks={setUnpackedTracks}
      />

      {/* <button type='button' className='btn btn-light-primary me-3'>
        <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
        Export
      </button> */}
    </div>
  )
}

export {TracksListToolbar}
