import {nanoid} from 'nanoid'
import {useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useAuth} from '../../modules/auth'
import {UsaWarehouseItem} from './UsaWarehouseItem'

export function UsaWarehouse() {
  const {currentUser} = useAuth()
  const [copied, setCopied] = useState<string[]>([])
  const intl = useIntl()

  const data = [
    {
      title: intl.formatMessage({id: 'USA_WAREHOUSE.PAGE.LAST_NAME'}),
      value: `${intl.formatMessage({id: 'USA_WAREHOUSE.PAGE.VECTOR_LLC'})}/${
        currentUser?.externalId
      }`,
      smaller: intl.formatMessage({id: 'USA_WAREHOUSE.PAGE.ADDRESS_2'}),
    },
    {title: intl.formatMessage({id: 'USA_WAREHOUSE.PAGE.ADDRESS_1'}), value: '609 Carson Dr'},
    {title: intl.formatMessage({id: 'USA_WAREHOUSE.PAGE.STATE'}), value: 'DE'},
    {title: intl.formatMessage({id: 'USA_WAREHOUSE.PAGE.CITY'}), value: 'Bear'},

    {title: intl.formatMessage({id: 'USA_WAREHOUSE.PAGE.ZIP'}), value: '19701'},
    {title: intl.formatMessage({id: 'USA_WAREHOUSE.PAGE.COUNTRY'}), value: 'United States'},
    {title: intl.formatMessage({id: 'USA_WAREHOUSE.PAGE.PHONE'}), value: '+1 302-287-5271'},
  ]

  return (
    <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
      <div className='card-header cursor-pointer'>
        <div className='card-title m-0'>
          <h3 className='fw-bold m-0'>
            <FormattedMessage id='USA_WAREHOUSE.PAGE.PAGE_TITLE' />
          </h3>
        </div>
      </div>
      <div className='card-body p-9'>
        {data.map(({title, value, smaller}) => (
          <UsaWarehouseItem
            title={title}
            value={value}
            smaller={smaller}
            key={nanoid()}
            copied={copied}
            setCopied={setCopied}
          />
        ))}
      </div>
    </div>
  )
}
