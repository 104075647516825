import {useFormikContext} from 'formik'
import {useEffect} from 'react'
import {useIntl} from 'react-intl'
import {checkForAnyCommercialProducts} from '../../../../../_metronic/helpers/custom/funcs/checkForCommercial'
import {earlySubmit} from '../../../../../_metronic/helpers/custom/funcs/earlySubmit'
import {usePageData} from '../../../../../_metronic/layout/core'
import {InputTemplate} from '../../../../modules/custom/form-elements/InputTemplate'
import {SettingsRadio} from '../../../../modules/custom/form-elements/radios/SettingsRadio'
import {Notification} from '../../../../modules/custom/Notification'
import {AddTrackForm} from '../../core/_models/_tracks-models'
import {TrackTarrifs} from '../TrackTarrifs'

interface Props {
  values: AddTrackForm
  disabledCommercialSettings: boolean
  currentStep: number
}

export function TrackParcelType({values, disabledCommercialSettings, currentStep}: Props) {
  const {setFieldValue, validateForm} = useFormikContext()
  const {pageCustomData} = usePageData()
  const intl = useIntl()

  useEffect(() => {
    const anyComProds = checkForAnyCommercialProducts(values.prods)

    validateForm().then((errors) => {
      if (pageCustomData?.trackFormSimplified === true) {
      } else {
        earlySubmit({
          currentStep,
          setFieldValue,
          values,
          noFormErrors: Object.keys(errors).length === 0,
          anyComProds,
        })
      }
    })
  }, [values.package_type])

  return (
    <div className='w-100'>
      <div className='fv-row mb-10'>
        <SettingsRadio
          title={intl.formatMessage({
            id: 'TRACKS.ADD_MODAL.FORM.COMMERCIAL_TITLE',
          })}
          disabled={disabledCommercialSettings}
          description={<TrackTarrifs delivery_type={+values.delivery_type} parcel_type={1} />}
          inputName='package_type'
          formValues={values}
          value={1}
          setFieldValue={setFieldValue}
        />
        <SettingsRadio
          title={intl.formatMessage({
            id: 'TRACKS.ADD_MODAL.FORM.NON_COMM_TITLE',
          })}
          disabled={disabledCommercialSettings}
          description={<TrackTarrifs delivery_type={+values.delivery_type} parcel_type={0} />}
          inputName='package_type'
          formValues={values}
          value={0}
          setFieldValue={setFieldValue}
        />
        {+values?.package_type === 0 && (
          <InputTemplate
            inputName='insurance'
            type='checkbox'
            checkboxProps={{
              checked: values.insurance,
              disabled: false,
              setFieldValue: setFieldValue,
              value: 1,
              inputName: 'insurance',
              type: 'checkbox',
            }}
            title={intl.formatMessage({
              id: 'TRACKS.ADD_MODAL.FORM.INSURANCE_TITLE',
            })}
          />
        )}
      </div>
      <div className='fv-row mb-10'>
        <Notification
          noteText={intl.formatMessage({
            id: 'TRACKS.ADD_MODAL.FORM.TARIFFS_EXPL',
          })}
          link={{
            url: 'https://www.vector-usa.biz/tarify-na-dostavku-v-ukrainu/',
            text: intl.formatMessage({
              id: 'TRACKS.ADD_MODAL.FORM.TARIFFS_EXPL.LINK_WORD',
            }),
          }}
        />
      </div>
    </div>
  )
}
