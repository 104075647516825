import CopyToClipboard from 'react-copy-to-clipboard'
import {KTSVG} from '../../../_metronic/helpers'

interface Props {
  title: string
  value: string
  smaller?: string
  copied: string[]
  setCopied: (arr: string[]) => void
}

export function UsaWarehouseItem({title, value, smaller, copied, setCopied}: Props) {
  return (
    <div className='row mb-7 d-flex align-items-center'>
      <label className='col-lg-3 fw-semibold text-muted'>
        <p className='m-0'>{title}</p>{' '}
        {smaller && <p className='col-lg-8 m-0 fw-semibold text-muted fs-7'>{smaller}</p>}
      </label>

      <div className='col-lg-8'>
        <span className='fw-bold fs-6 text-gray-800'>{value}</span>
        <CopyToClipboard
          text={value}
          onCopy={() => {
            setCopied([value])
          }}
        >
          <button type='button' className='highlight-copy btn'>
            <KTSVG
              path='/media/icons/duotune/general/gen054.svg'
              className={`svg-icon-3 ${
                copied.includes(value) ? 'svg-icon-dark' : 'svg-icon-gray-300'
              }`}
            />
          </button>
        </CopyToClipboard>
      </div>
    </div>
  )
}
