/* eslint-disable jsx-a11y/anchor-is-valid */
import {ListViewProvider} from '../core/ListViewProvider'
import {QueryRequestProvider, useQueryRequest} from '../core/QueryRequestProvider'
import {QueryResponseProvider} from '../core/QueryResponseProvider'
import {getSavedPagination, initialQueryState, KTCard, LS_KEYS} from '../../../../_metronic/helpers'
import {ParcelsTable} from './table/ParcelsTable'
import {ParcelsListHeader} from './components/header/ParcelsListHeader'
import {useEffect} from 'react'

const ParcelsList = () => {
  const {updateState} = useQueryRequest()

  useEffect(() => {
    const retrievedData = getSavedPagination()

    updateState({
      ...initialQueryState,
      itemsPerPage: retrievedData,
    })
  }, [])
  return (
    <>
      <KTCard>
        <ParcelsListHeader />
        <ParcelsTable />
      </KTCard>
    </>
  )
}

const ParcelsListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <ParcelsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {ParcelsListWrapper}
