import * as Yup from 'yup'
import {latinAndNumbersOnly} from '../../../../../_metronic/helpers/custom/regexs'
import {ICatBrandSelectOption} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {object_shape_basic_select} from '../../../../../_metronic/helpers/custom/yup/partials/generalPartials'
import {IInvoice} from '../_models/_tracks-models'

const trackCode = (intl: any) =>
  Yup.string()
    .matches(latinAndNumbersOnly, intl.messages.YUP_VALIDATION_GENERAL_LATIN_AND_NUMBERS_ONLY)
    .required(intl.messages.YUP_VALIDATION_TRACK_ENTER_TRACK_CODE)
    .label('Трекинг-код')

const receiverNeeded = Yup.boolean().required()

const receiver = (intl: any) =>
  object_shape_basic_select
    .required(intl.messages.YUP_VALIDATION_TRACK_CHOOSE_REC)
    .label('Получатель')

const deliveryType = Yup.number().required().label('Тип доставки')
const description = Yup.string().label('Коментар')

const returnToStore = Yup.boolean().label('Повернути в магазин')

const prodsItem = (intl: any) =>
  Yup.object({
    group: object_shape_basic_select.label('Группа товара'),
    name: Yup.object()
      .shape({
        label: Yup.string()
          .required(intl.messages.YUP_VALIDATION_TRACK_CHOOSE_CATEGORY)
          .notOneOf([''], intl.messages.YUP_VALIDATION_TRACK_CHOOSE_CATEGORY),
        value: Yup.string()
          .required(intl.messages.YUP_VALIDATION_TRACK_CHOOSE_CATEGORY)
          .notOneOf([''], intl.messages.YUP_VALIDATION_TRACK_CHOOSE_CATEGORY),
        brands: Yup.array(),
        require_weight: Yup.number(),
      })
      .required(intl.messages.YUP_VALIDATION_TRACK_CHOOSE_CATEGORY)
      .label('Категория товара'),
    brand: object_shape_basic_select
      .when('name.brands', {
        is: (brands: ICatBrandSelectOption[]) => brands?.length > 0,
        then: Yup.object().shape({
          label: Yup.string()
            .required(intl.messages.YUP_VALIDATION_TRACK_CHOOSE_BRAND)
            .notOneOf([''], intl.messages.YUP_VALIDATION_TRACK_CHOOSE_BRAND),
          value: Yup.string()
            .required(intl.messages.YUP_VALIDATION_TRACK_CHOOSE_BRAND)
            .notOneOf([''], intl.messages.YUP_VALIDATION_TRACK_CHOOSE_BRAND),
        }),
        otherwise: object_shape_basic_select,
      })
      .label('Бренд товару'),
    qty: Yup.number()
      .required(intl.messages.YUP_VALIDATION_TRACK_ENTER_QTY)
      .min(1, intl.messages.YUP_VALIDATION_TRACK_AT_LEAST_ONE_GOODS_ITEM)
      .label('Количество позиций'),
    cost: Yup.number()
      .required(intl.messages.YUP_VALIDATION_TRACK_ENTER_PRICE)
      .min(0.1, intl.messages.YUP_VALIDATION_GENERAL_NO_ZERO_OR_NEG)
      .label('Стоимость одной позиции'),
    weight: Yup.number().when('name.require_weight', {
      is: 1,
      then: Yup.number()
        .min(0.1, intl.messages.YUP_VALIDATION_GENERAL_NO_ZERO_OR_NEG)
        .required(intl.messages.YUP_VALIDATION_TRACK_ENTER_WEIGHT)
        .label('Вага'),
    }),
    total: Yup.number()
      .required(intl.messages.YUP_VALIDATION_TRACK_ENTER_TOTAL)
      .min(0.1, intl.messages.YUP_VALIDATION_GENERAL_NO_ZERO_OR_NEG)
      .label('Суммарная стоимость'),
  })

const prods = (intl: any) =>
  Yup.array()
    .of(prodsItem(intl))
    .required(intl.messages.YUP_VALIDATION_TRACK_AT_LEAST_A_GOODS)
    .min(1, intl.messages.YUP_VALIDATION_TRACK_AT_LEAST_A_GOODS)
    .label('Товары')

const packageType = Yup.number().required().label('Тип посылки')
const insurance = Yup.boolean().label('Страховка')

const requireInvoice = Yup.number()
const invoiceFile = (intl: any) =>
  Yup.object()
    .nullable()
    .when(['require_invoice', 'invoiceLinks'], {
      is: (require_invoice: number, invoiceLinks: string[]) =>
        require_invoice === 1 && (!invoiceLinks || invoiceLinks[0] == ''),
      then: Yup.object()
        .shape({
          name: Yup.string(),
          file: Yup.object().shape({base_url: Yup.string(), filename: Yup.string()}),
        })
        .required(intl.messages.YUP_VALIDATION_TRACK_UPLOAD_INV)
        .label('Инвойс'),
    })
const invoiceLinks = (intl: any) =>
  Yup.array()
    .of(Yup.string().url(intl.messages.YUP_VALIDATION_URL))
    .when(['require_invoice', 'invoice_file'], {
      is: (require_invoice: number, invoice_file: IInvoice | null) =>
        require_invoice === 1 && (!invoice_file || invoice_file === null),
      then: Yup.array()
        .of(
          Yup.string()
            .notOneOf([''])
            .url(intl.messages.YUP_VALIDATION_URL)
            .required(intl.messages.YUP_VALIDATION_TRACK_UPLOAD_INV)
        )
        .label('Ссылка на товар'),
    })

const earlySubmitAllowed = Yup.boolean().required()
const orderInspection = Yup.boolean().label('Проверка трека')
const inspectionDescription = (intl: any) =>
  Yup.string().when('orderInspection', {
    is: true,
    then: Yup.string().required(intl.messages.YUP_VALIDATION_TRACK_ENTER_INSPECTION_DESC),
  })

export const trackPartials = {
  trackCode,
  receiverNeeded,
  receiver,
  deliveryType,
  description,
  returnToStore,
  prods,
  prodsItem,
  packageType,
  insurance,
  requireInvoice,
  invoiceFile,
  invoiceLinks,
  earlySubmitAllowed,
  orderInspection,
  inspectionDescription,
}
