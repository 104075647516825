/* eslint-disable react/jsx-no-target-blank */
import {FormattedMessage, useIntl} from 'react-intl'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'DASHBOARD.PAGE.PAGE_TITLE'})}
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/profile'
        icon='/media/icons/duotune/communication/com013.svg'
        title={intl.formatMessage({id: 'PROFILE.PAGE.PAGE_TITLE'})}
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/receivers'
        icon='/media/icons/duotune/communication/com014.svg'
        title={intl.formatMessage({id: 'RECEIVERS.PAGE.PAGE_TITLE'})}
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/news'
        icon='/media/icons/duotune/general/gen045.svg'
        title={intl.formatMessage({id: 'NEWS.PAGE.PAGE_TITLE'})}
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/usa-warehouse'
        icon='/media/icons/duotune/maps/map008.svg'
        title={intl.formatMessage({id: 'USA_WAREHOUSE.PAGE.PAGE_TITLE'})}
        fontIcon='bi-layers'
      />

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
            <FormattedMessage id='SIDEBAR.BUYOUTS_TITLE' />
          </span>
        </div>
      </div>
      <SidebarMenuItem
        to='/buyouts'
        title={intl.formatMessage({id: 'SIDEBAR.GENERAL.LIST'})}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/ecommerce/ecm005.svg'
      />

      <SidebarMenuItem
        to='/buyouts/create'
        title={intl.formatMessage({id: 'SIDEBAR.GENERAL.ADD'})}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen041.svg'
      />
      <SidebarMenuItem
        to='/archived-buyouts'
        title={intl.formatMessage({id: 'SIDEBAR.GENERAL.TRASH'})}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen027.svg'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
            <FormattedMessage id='TRACKS.PAGE.PAGE_TITLE' />
          </span>
        </div>
      </div>
      <SidebarMenuItem
        to='/tracks'
        title={intl.formatMessage({id: 'SIDEBAR.GENERAL.LIST'})}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/ecommerce/ecm010.svg'
      />

      <SidebarMenuItem
        to='/tracks/create'
        title={intl.formatMessage({id: 'SIDEBAR.GENERAL.ADD'})}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen041.svg'
      />
      <SidebarMenuItem
        to='/archived-tracks'
        title={intl.formatMessage({id: 'SIDEBAR.GENERAL.TRASH'})}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen027.svg'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
            <FormattedMessage id='PARCELS.PAGE.PAGE_TITLE' />
          </span>
        </div>
      </div>
      <SidebarMenuItem
        to='/parcels'
        title={intl.formatMessage({id: 'SIDEBAR.GENERAL.LIST'})}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen017.svg'
      />

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Фулфилмент</span>
        </div>
      </div>

      <SidebarMenuItem
        to='/fulfillment/goods'
        title='Товары'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/ecommerce/ecm005.svg'
      />

      {/* <SidebarMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='/media/icons/duotune/communication/com006.svg'
        fontIcon='bi-person'
      >
        <SidebarMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <SidebarMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/error'
        title='Errors'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/general/gen040.svg'
      >
        <SidebarMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <SidebarMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>
      <SidebarMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com012.svg'
      >
        <SidebarMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItem
        to='/apps/user-management/users'
        icon='/media/icons/duotune/general/gen051.svg'
        title='User management'
        fontIcon='bi-layers'
      /> */}
      {/* <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
  )
}

export {SidebarMenuMain}
