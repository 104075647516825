import {Accordion} from 'react-bootstrap'
import {ISetFieldValue} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {
  IInvoice,
  IRecentInvoiceReformed,
  IRecentInvoiceRes,
} from '../../core/_models/_tracks-models'
import {RecentInvoiceRow} from './RecentInvoiceRow'

import {FormattedMessage, useIntl} from 'react-intl'

interface Props {
  recentInvoicesData: IRecentInvoiceRes[]
  addedInvoices: IInvoice[]
  handleInvoiceArray: (item: IInvoice, itemIsAdded: boolean, setFieldValue: ISetFieldValue) => void
}

export function ListOfRecentInvoices({
  recentInvoicesData,
  handleInvoiceArray,
  addedInvoices,
}: Props) {
  const intl = useIntl()
  const transformedRecentInvoicesData: IRecentInvoiceReformed[] = recentInvoicesData
    .map(({invoices, track_code}) =>
      invoices.map((item) => {
        return {...item, track_code}
      })
    )
    .flat(2)

  return (
    <Accordion>
      <Accordion.Item eventKey='0'>
        <Accordion.Header>
          <FormattedMessage id='TRACKS.COMPONENTS.RECENT_INVOICES.CHOOSE_BUTTON' />
        </Accordion.Header>
        <Accordion.Body>
          <div className='table-responsive'>
            {' '}
            <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
              <thead>
                <tr>
                  <th className='fw-bold'>
                    <FormattedMessage id='TRACKS.COMPONENTS.RECENT_INVOICES.TRACK_COLUMN' />
                  </th>
                  <th className='fw-bold text-center'>
                    <FormattedMessage id='TRACKS.COMPONENTS.RECENT_INVOICES.PREVIEW_COLUMN' />
                  </th>
                  <th className='fw-bold  text-center min-w-150px'>
                    <FormattedMessage id='TRACKS.COMPONENTS.RECENT_INVOICES.NAME_COLUMN' />
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {transformedRecentInvoicesData.map(
                  (item: IRecentInvoiceReformed, index: number) => (
                    <RecentInvoiceRow
                      item={item}
                      id={item.file.filename + index}
                      key={item.file.filename + index}
                      handleInvoiceArray={handleInvoiceArray}
                      addedInvoices={addedInvoices}
                    />
                  )
                )}
              </tbody>
            </table>
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}
