import AsyncCreatableSelect from 'react-select/async-creatable'
import AsyncSelect from 'react-select/async'
import {components} from 'react-select'
import {IReactSelectProps} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {useIntl} from 'react-intl'

interface Props {
  props: IReactSelectProps
}

export const customStyles = {
  // @ts-ignore
  control: (provided, state) => ({
    ...provided,
    border: 'none',
    backgroundColor: 'var(--kt-input-solid-bg)',
    minHeight: 'calc(1.5em + 1.65rem + 2px)',
  }),
}

export function ReactSelectTemplate({props}: Props) {
  const {
    options,
    isLoading,
    onInputChange,
    setFieldValue,
    isClearable,
    placeholder,
    selectName,
    currentValue,
    isCreatable = false,
    setObject = false,
    noOptionsMessage,
    defaultValue,
    handleChange,
    disabled = false,
  } = props
  const intl = useIntl()
  const NoOptionsMessage = (props: any) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className='custom-css-class'>
          {noOptionsMessage ? noOptionsMessage : 'Не найдено'}
        </span>
      </components.NoOptionsMessage>
    )
  }

  const loadOptions = (inputValue: string, callback: any) => {
    setTimeout(() => {
      callback(options)
    }, 1000)
  }

  const loadOptionsCreatable = (inputValue: string, callback: any) => {
    setTimeout(() => {
      const newOption = {label: inputValue, value: inputValue}
      const newOptions = [...options, newOption]
      callback(newOptions)
    }, 1000)
  }

  return (
    <>
      {isCreatable === true ? (
        <AsyncCreatableSelect
          styles={customStyles}
          loadOptions={loadOptionsCreatable}
          defaultOptions={options}
          form={selectName}
          isDisabled={disabled}
          components={{NoOptionsMessage}}
          loadingMessage={() => intl.formatMessage({id: 'GENERAL.BUTTONS.WAIT'})}
          formatCreateLabel={(userInput) => `Добавить "${userInput}"`}
          placeholder={placeholder}
          isClearable={isClearable}
          isLoading={isLoading}
          value={currentValue}
          onInputChange={(e) => {
            onInputChange(e)
          }}
          onChange={(e) => {
            setFieldValue(selectName, e?.value)

            if (handleChange) {
              handleChange()
            }
          }}
        />
      ) : (
        <AsyncSelect
          styles={customStyles}
          loadOptions={loadOptions}
          defaultOptions={options}
          form={selectName}
          isDisabled={disabled}
          components={{NoOptionsMessage}}
          loadingMessage={() => 'Подождите...'}
          placeholder={placeholder}
          isClearable={isClearable}
          isLoading={isLoading}
          value={currentValue}
          defaultValue={defaultValue ? defaultValue : undefined}
          onInputChange={(e) => {
            onInputChange(e)
          }}
          onChange={(e) => {
            if (setObject === true) {
              setFieldValue(selectName, e)
            } else {
              setFieldValue(selectName, e?.value)
            }

            if (handleChange) {
              handleChange()
            }
          }}
        />
      )}
    </>
  )
}
