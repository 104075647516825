/* eslint-disable jsx-a11y/anchor-is-valid */

import {IBuyoutComment} from '../../core/_models/_buyout-models'
import {FormattedDate, FormattedMessage, FormattedTime, useIntl} from 'react-intl'
import {ImageLightbox} from '../../../../modules/custom/images/ImageLightbox'
import {useState} from 'react'

interface Props {
  row: IBuyoutComment
}

function BuyoutCommentsItem({row}: Props) {
  const {admin_comment, user_comment, admin_img} = row
  const intl = useIntl()
  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)
  const openLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(true)
  }

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  return (
    <tr>
      <td>
        <div className='d-flex flex-column'>
          <span className='text-dark fw-bold fs-6'>
            <FormattedDate year='numeric' month='numeric' day='numeric' value={row.create_at} />
          </span>
          <span className='text-muted fw-normal fs-6'>
            <FormattedTime value={row.create_at} hour='numeric' minute='numeric' second='numeric' />
          </span>
        </div>
      </td>

      <td>
        <div className='d-flex flex-wrap flex-column mb-1'>
          {user_comment && (
            <div className='min-w-100px py-3 px-4 me-20 mb-3'>
              <div className='d-flex align-items-center justify-content-between'>
                <div className='fs-5 fw-bolder'>
                  <FormattedMessage id='TRACK_PAGE.STATUSES.CLIENT' />
                </div>
              </div>

              <div
                className='fw-bold fs-7 text-gray-400'
                dangerouslySetInnerHTML={{__html: user_comment}}
              ></div>
            </div>
          )}
          {user_comment && admin_comment && <div className='separator separator-dashed'></div>}

          {admin_comment && (
            <div className='min-w-100px pt-3 px-4 mb-3'>
              <div>
                <div className='d-flex align-items-center'>
                  <div className='fs-5 fw-bolder'>
                    {' '}
                    <FormattedMessage id='COMPONENTS.HeaderMessage.AUTHOR' />
                  </div>
                </div>

                <div
                  className='fw-bold fs-7 text-gray-400'
                  dangerouslySetInnerHTML={{__html: admin_comment}}
                ></div>
                {admin_img && (
                  <>
                    {' '}
                    <a
                      href='#'
                      className='fs-9 text-hover-primary text-gray-500'
                      onClick={openLightbox}
                    >
                      <FormattedMessage id='GENERAL.BUTTONS.SEE_FILES' />{' '}
                    </a>
                    <ImageLightbox
                      viewerIsOpen={viewerIsOpen}
                      closeLightbox={closeLightbox}
                      currentImage={currentImage}
                      images={[admin_img].map((item, index) => {
                        return {title: `answer-${index}`, src: item}
                      })}
                    />
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </td>
    </tr>
  )
}

export {BuyoutCommentsItem}
