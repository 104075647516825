import {ErrorMessage, Field, FieldArray, useFormikContext} from 'formik'
import {KTSVG} from '../../../../../_metronic/helpers'
import {FormattedMessage, useIntl} from 'react-intl'
import {usePageData} from '../../../../../_metronic/layout/core'

interface Props {
  invoiceLinks: string[]
  required: boolean
  withTitle?: boolean
}
export function TrackInvoiceLinks({invoiceLinks, required, withTitle = true}: Props) {
  const {pageCustomData} = usePageData()
  const {setFieldValue} = useFormikContext()

  return (
    <>
      {withTitle === true && (
        <label
          className={`d-flex align-items-center fs-5 fw-bold mb-2 ${
            required === true ? 'required' : ''
          }`}
        >
          <FormattedMessage id='TRACKS.COMPONENTS.TrackInvoiceLinks.LABEL' />
        </label>
      )}
      <div className='d-flex flex-column mb-10'>
        {' '}
        <div className='w-100'>
          <FieldArray
            name='invoiceLinks'
            render={(arrayHelpers) => (
              <div className='d-flex flex-column flex-col-gutter'>
                {invoiceLinks.map((item, index) => (
                  <div key={index}>
                    <div className='d-flex'>
                      <Field
                        className={`form-control form-control-lg form-control-solid`}
                        name={`invoiceLinks.${index}`}
                        value={item}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setFieldValue(`invoiceLinks.${index}`, e.target.value.replace(/\s/g, ''))
                        }
                        autoFocus={pageCustomData?.trackFormSimplified === false ? true : false}
                      />
                      {invoiceLinks.length > 1 && (
                        <button
                          className='btn'
                          type='button'
                          onClick={() => arrayHelpers.remove(index)}
                        >
                          <KTSVG
                            path='/media/icons/duotune/general/gen040.svg'
                            className='svg-icon-1x svg-icon-primary d-block my-2 '
                          />
                        </button>
                      )}
                    </div>{' '}
                    {/* <div className='text-danger'>
                      <ErrorMessage name={`invoiceLinks.${index}`} />
                    </div> */}
                  </div>
                ))}
                <div className='text-danger'>
                  <ErrorMessage name='invoiceLinks' />
                </div>
                <div className=''>
                  <button
                    className='btn btn-sm btn-secondary d-flex align-items-center'
                    type='button'
                    onClick={() => {
                      arrayHelpers.push('')
                    }}
                  >
                    <span className='me-2'>
                      <FormattedMessage id='GENERAL.BUTTONS.ADD' values={{item: null}} />
                    </span>
                    <KTSVG
                      path='/media/icons/duotune/general/gen041.svg'
                      className='svg-icon-1x svg-icon-primary d-block my-2'
                    />
                  </button>
                </div>
              </div>
            )}
          />
        </div>
      </div>
    </>
  )
}
