/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../../../../_metronic/helpers'
import {Parcel} from '../../../../core/_models/_parcels-models'
import {FormattedMessage, useIntl} from 'react-intl'
import {ImageLightbox} from '../../../../../../modules/custom/images/ImageLightbox'

type Props = {
  parcel: Parcel
}

const ParcelNumberCell: FC<Props> = ({parcel}) => {
  const intl = useIntl()
  const {c_code, code, flow, type_delive, id, preview_files} = parcel

  const [currentImage, setCurrentImage] = useState(0)

  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const openLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(true)
  }

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  return (
    <div className='d-flex align-items-center'>
      <div className='symbol symbol-45px me-5'>
        {preview_files && preview_files.length > 0 ? (
          <div className='cursor-zoom-in'>
            <div className='symbol symbol-fixed position-relative'>
              <img
                src={preview_files[0]}
                width='45'
                height='45'
                alt='track'
                onClick={openLightbox}
                style={{objectFit: 'contain'}}
              />

              <ImageLightbox
                viewerIsOpen={viewerIsOpen}
                closeLightbox={closeLightbox}
                currentImage={currentImage}
                images={preview_files.map((item, index) => {
                  return {src: item, title: `${c_code}-${index + 1}`}
                })}
              />
            </div>
          </div>
        ) : (
          <img src={toAbsoluteUrl('/media/icons/duotune/general/gen017.svg')} alt='parcel' />
        )}
      </div>
      <div className='d-flex justify-content-start flex-column'>
        <Link
          to={`/parcels/info/content/${id}`}
          className='text-dark fw-bold text-hover-primary fs-6 mb-1'
        >
          {type_delive === 1 ? (
            <KTSVG path='/media/icons/anchor.svg' className='svg-icon-4 me-1' />
          ) : (
            <KTSVG path='/media/icons/flight.svg' className='svg-icon-4 me-1' />
          )}
          <span> {c_code || code}</span>
        </Link>
        {flow && flow.sum.debt > 0 && (
          <span className='text-muted fw-semobold text-muted d-block fs-7'>
            <span className={`p-1 fs-8 fw-semobold  StatusBar__status StatusBar_blocked`}>
              <FormattedMessage id='PARCELS.LIST.STATUSES.TO_BE_PAID' />
            </span>
          </span>
        )}
      </div>
    </div>
  )
}

export {ParcelNumberCell}
