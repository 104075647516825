import {useQuery} from 'react-query'
import {isNotEmpty, QUERIES} from '../../../../_metronic/helpers'
import {usePageData} from '../../../../_metronic/layout/core'
import {useListView} from '../core/ListViewProvider'
import {CreateTrack} from './CreateTrack'

const TrackModalFormWrapper = () => {
  const {itemIdForUpdate} = useListView()
  const {pageCustomData} = usePageData()

  if (!itemIdForUpdate && pageCustomData?.trackFormSimplified === false) {
    return <CreateTrack />
  }

  return null
}

export {TrackModalFormWrapper}
