import {FormattedMessage, useIntl} from 'react-intl'
import {Track} from '../../core/_models/_tracks-models'
import {AllTrackParcelsItem} from './AllTrackParcelsItem'

interface Props {
  track: Track
}

export function AllTrackParcels({track}: Props) {
  const {track_package} = track
  const intl = useIntl()
  return (
    <div className='card mb-5 mb-xl-10 pt-10'>
      <div className='card-body py-9 px-20 cardBody'>
        <div className='table-responsive'>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-100px '>
                  <FormattedMessage id='TRACK_PAGE.PARCELS_TAB.PARCEL_COLUMN' />

                </th>
              </tr>
            </thead>

            <tbody>
              {track_package.length > 0 ? (
                track_package?.map((track) => (
                  <AllTrackParcelsItem key={track.c_code} track_package={track} />
                ))
              ) : (
                <tr>
                  <td colSpan={7}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      <FormattedMessage id='PARCELS.LIST.NO_RESULTS' />
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
