import {useIntl} from 'react-intl'

interface Props {
  addressType: {id: number; label: string}
  setModalOpen: (bool: boolean) => void
  setTempType: (val: number) => void
}

export function ShipmentCreateCard({addressType, setModalOpen, setTempType}: Props) {
  const intl = useIntl()
  return (
    <div className='col-sm-6 col-xl-4 '>
      <div className='card h-100'>
        {' '}
        <button
          className='btn bg-white btn-primary text-center align-items-center ProfileShipmentCreateCard py-13 h-100'
          onClick={() => {
            setModalOpen(true)
            setTempType(addressType.id)
          }}
        >
          <h2 className='fw-bold fs-6'>
            {intl.formatMessage({id: 'GENERAL.BUTTONS.ADD'}, {item: null})} {addressType.label}
          </h2>
          <span className='fs-5x mb-3'>+</span>
        </button>
      </div>
    </div>
  )
}
