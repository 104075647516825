import * as Yup from 'yup'
import {trackPartials} from './partials'
const {
  trackCode,
  receiverNeeded,
  receiver,
  deliveryType,
  description,
  prods,
  packageType,
  insurance,
  requireInvoice,
  invoiceFile,
  invoiceLinks,
  earlySubmitAllowed,
  orderInspection,
  inspectionDescription,
} = trackPartials

export const createTrackSchema = (intl: any) => [
  Yup.object({
    track_code: trackCode(intl),
    receiverNeeded,
    receiver: Yup.object().when('receiverNeeded', {
      is: true,
      then: receiver(intl),
    }),
    delivery_type: deliveryType,
    description,
  }),

  Yup.object({
    prods: prods(intl),
  }),
  Yup.object({
    package_type: packageType,
    insurance,
  }),
  Yup.object().shape(
    {
      require_invoice: requireInvoice,
      invoice_file: invoiceFile(intl),
      invoiceLinks: invoiceLinks(intl),
    },
    [['invoice_file', 'invoiceLinks']]
  ),
  Yup.object({
    earlySubmitAllowed,
    orderInspection,
    inspectionDescription: inspectionDescription(intl),
  }),
]

export const createTrackOnePageSchema = (intl: any) =>
  Yup.object({
    track_code: trackCode(intl),
    receiverNeeded,
    receiver: Yup.object().when('receiverNeeded', {
      is: true,
      then: receiver(intl),
    }),
    delivery_type: deliveryType,
    description,

    prods: prods(intl),

    package_type: packageType,
    insurance,

    orderInspection,
    inspectionDescription: inspectionDescription(intl),
    require_invoice: requireInvoice,
    invoice_file: Yup.object()
      .nullable()
      .shape({
        name: Yup.string(),
        file: Yup.object().shape({base_url: Yup.string(), filename: Yup.string()}),
      }),
    invoiceLinks: Yup.array().of(Yup.string().url(intl.messages.YUP_VALIDATION_URL)),
  }).when(['require_invoice'], (require_invoice, schema) => {
    return require_invoice
      ? schema
          .required(intl.messages.YUP_VALIDATION_TRACK_UPLOAD_INV)
          .test('file-or-links', intl.messages.YUP_VALIDATION_TRACK_UPLOAD_INV, function (value) {
            const {invoice_file, invoiceLinks} = this.parent
            return Boolean(invoice_file) || Boolean(invoiceLinks[0] !== '')
          } as (this: Yup.TestContext, value: any) => boolean)
      : schema
  })
