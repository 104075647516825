import React, {useState, Dispatch, SetStateAction} from 'react'
import {Formik, Form, FormikValues} from 'formik'
import {useAuth, UserModel} from '../../../../modules/auth'
import {InputTemplate} from '../../../../modules/custom/form-elements/InputTemplate'
import {profileApi} from '../../../../../_metronic/helpers/custom/api/profileApi'
import {profileOverviewSchema} from '../../core/yup/user-profile'
import {getDateAndTime} from '../../../../../_metronic/helpers/custom/funcs/getDateAndTime'
import {TermsOfService} from '../../../../modules/custom/TermsOfService'
import {CardFooter} from '../../../../modules/custom/form-elements/CardFooter'
import {handleFileUpload} from '../../../../../_metronic/helpers/custom/funcs/handleFileUpload'
import {TermsOfServiceModal} from './TermsOfServiceModal'
import {ImageFileInput} from '../../../../modules/custom/form-elements/file-inputs/ImageFileInput'
import {ISetFieldValue} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {PatronymicCheckbox} from '../../../receivers/elements/PatronymicCheckbox'
import {FormattedMessage, useIntl} from 'react-intl'
import {KTSVG} from '../../../../../_metronic/helpers'

const FILE_LINK_BASE = `${process.env.REACT_APP_FILE_ENDPOINT}`

interface Props {
  user: UserModel
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
}

export function ProfileOverview({user, setCurrentUser}: Props) {
  const intl = useIntl()

  const initialValues: Partial<UserModel> = {
    firstName: user.firstName,
    lastName: user.lastName,
    patronymic: user.patronymic || '',
    noPatronymic: !!user.no_pat,
    passport: user.passport || {base_url: '', filename: ''},
  }

  const [loading, setLoading] = useState(false)
  const [imageIsLoading, setImageIsLoading] = useState(false)

  const [isOpen, setModalOpen] = useState(user.tos_accepted === null ? true : false)

  const {currentUser} = useAuth()

  const [success, setSuccess] = useState(false)

  const saveImage = async (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: ISetFieldValue
  ) => {
    setLoading(true)
    setImageIsLoading(true)

    const linkResponse = await handleFileUpload(
      e,
      setFieldValue,
      'passport',
      'file',
      profileApi.addPassportScan
    )

    const response = await profileApi.editProfileOverview({
      passport: linkResponse.filename,
    })
    console.log(response)

    if (response) {
      setCurrentUser(response)
      setSuccess(true)

      setTimeout(() => {
        setSuccess(false)
      }, 3000)
    } else {
      // set error
    }

    setImageIsLoading(false)
    setLoading(false)
  }

  const deleteImage = async (setFieldValue: ISetFieldValue) => {
    setLoading(true)

    setFieldValue('passport', '')

    const response = await profileApi.editProfileOverview({
      passport: '',
    })

    if (response) {
      setCurrentUser(response)

      setLoading(false)

      setSuccess(true)

      setTimeout(() => {
        setSuccess(false)
      }, 3000)
    }
  }

  const handleSubmit = async (
    values: Omit<UserModel, 'tos_accepted' | 'phoneNumber'>,
    actions: FormikValues
  ) => {
    console.log('values', values)
    setLoading(true)
    const response = await profileApi.editProfileOverview({
      ...values,
      no_pat: values.noPatronymic,
      passport: currentUser?.passport?.filename,
    })
    console.log('resp', response)

    if (response) {
      setCurrentUser(response)
      setLoading(false)

      setSuccess(true)

      setTimeout(() => {
        setSuccess(false)
      }, 3000)
    }
  }

  const acceptTos = async () => {
    const response = await profileApi.editProfileOverview({
      tos_accepted: true,
    })

    if (response) {
      setCurrentUser(response)

      setLoading(false)
    }
  }

  return (
    <>
      {isOpen && (
        <TermsOfServiceModal
          handleClick={setModalOpen}
          acceptTos={acceptTos}
          accepted={user.tos_accepted == null ? false : true}
        />
      )}
      <div className='card mb-5 mb-xl-10'>
        <Formik
          validationSchema={profileOverviewSchema(intl)}
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={handleSubmit}
        >
          {({values, setFieldValue}) => (
            <Form noValidate className='form'>
              <div className='card-body border-top p-9'>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                    <FormattedMessage id='PROFILE.PAGE.OVERVIEW.FULL_NAME_LABEL' />
                  </label>

                  <div className='col-lg-8'>
                    <div className='row'>
                      <div className='col-lg-6 fv-row'>
                        <InputTemplate
                          addPrompt={false}
                          inputName='firstName'
                          required={false}
                          type='text'
                          placeholder={intl.formatMessage({
                            id: 'PROFILE.PAGE.OVERVIEW.NAME_PLACEHOLDER',
                          })}
                          addSymbol={
                            <KTSVG
                              path='/media/icons/duotune/communication/com006.svg'
                              className='svg-icon-3'
                            />
                          }
                          symbolMarginTop={false}
                        />
                      </div>

                      <div className='col-lg-6 fv-row'>
                        <InputTemplate
                          addPrompt={false}
                          inputName='lastName'
                          required={false}
                          type='text'
                          placeholder={intl.formatMessage({
                            id: 'PROFILE.PAGE.OVERVIEW.LAST_NAME_PLACEHOLDER',
                          })}
                          addSymbol={
                            <KTSVG
                              path='/media/icons/duotune/communication/com006.svg'
                              className='svg-icon-3'
                            />
                          }
                          symbolMarginTop={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>{' '}
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'></label>
                  <div className='col-lg-8'>
                    <div className='row'>
                      <div className='col-lg-6 fv-row'>
                        <InputTemplate
                          addPrompt={false}
                          inputName='patronymic'
                          required={false}
                          type='text'
                          placeholder={intl.formatMessage({
                            id: 'PROFILE.PAGE.OVERVIEW.PATRONYMIC_PLACEHOLDER',
                          })}
                          addSymbol={
                            <KTSVG
                              path='/media/icons/duotune/communication/com006.svg'
                              className='svg-icon-3'
                            />
                          }
                          symbolMarginTop={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'></label>
                  <div className='col-lg-8'>
                    <div className='row'>
                      {' '}
                      <PatronymicCheckbox
                        setFieldValue={setFieldValue}
                        title={intl.formatMessage({
                          id: 'PROFILE.PAGE.OVERVIEW.NO_PATRONYMIC_CHECKBOX',
                        })}
                        value={values.noPatronymic || false}
                      />
                    </div>
                  </div>
                </div>
                <ImageFileInput
                  title={intl.formatMessage({id: 'PROFILE.PAGE.OVERVIEW.FIRST_PASSPORT_PAGE'})}
                  inputName='passport'
                  inputId={`${user.id}-profile-file-input`}
                  uploadedFile={{
                    base: user?.passport?.base_url || '',
                    link: user?.passport?.filename || '',
                  }}
                  setFieldValue={setFieldValue}
                  uploadFunction={saveImage}
                  deleteFunction={deleteImage}
                  placeholder={intl.formatMessage({
                    id: 'PROFILE.PAGE.OVERVIEW.FIRST_PASSPORT_PAGE',
                  })}
                  notification={{
                    bool: true,
                    text: intl.formatMessage({
                      id: 'PROFILE.PAGE.OVERVIEW.FIRST_PASSPORT_PAGE.PROMPT',
                    }),
                    borderTop: false,
                  }}
                  required={true}
                  loading={imageIsLoading}
                  linkEndpoint='/passport'
                />
                <div className='row mb-0'>
                  <TermsOfService
                    heading={intl.formatMessage({
                      id: 'PROFILE.PAGE.OVERVIEW.TERMS_OF_SERVICE',
                    })}
                    text={
                      user.tos_accepted
                        ? `${intl.formatMessage({
                            id: 'GENERAL.STATUSES.ACCEPTED',
                          })}
                         ${getDateAndTime(user.tos_accepted)}`
                        : intl.formatMessage({
                            id: 'GENERAL.STATUSES.DECLINED',
                          })
                    }
                    buttonText={intl.formatMessage({
                      id: 'GENERAL.SEE_DETAILS',
                    })}
                    handleClick={setModalOpen}
                    accepted={user.tos_accepted ? true : false}
                  />
                </div>
              </div>
              <CardFooter loading={loading} success={success} />
            </Form>
          )}
        </Formik>
      </div>
    </>
  )
}
