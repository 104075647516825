import {
  GoodsCategoriesQueryResponse,
  IBuyoutCategorySelectOption,
  ICatBrandSelectOption,
  IGoodsCategoryBrand,
  IGoodsCategoryItem,
  IGoodsGroupSelectOption,
  ISetFieldValue,
  ITrackCategorySelectOption,
} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {useEffect, useRef, useState} from 'react'
import {IBasicSelect} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {SearchGroupSelect} from './SearchGroupSelect'
import {SearchCategorySelect} from './SearchCategorySelect'
import {SearchBrandSelect} from './SearchBrandSelect'

interface Props {
  setFieldValue: ISetFieldValue
  inputName: {categories: string; groups: string; brands: string}
  noOptionsMessage?: string
  disabled?: boolean
  selectValues: {
    groups: IGoodsGroupSelectOption
    categories: IBuyoutCategorySelectOption | ITrackCategorySelectOption
    brands: ICatBrandSelectOption
  }
  apiRequest: (cat: string, groupId: string | number) => Promise<GoodsCategoriesQueryResponse>
}

export function CategoriesWrapper({
  setFieldValue,
  inputName,
  noOptionsMessage = 'Не найдено',
  disabled = false,
  apiRequest,
  selectValues,
}: Props) {
  const [resultsLoading, setResultsLoading] = useState(false)
  const [groupsResults, setGroupsResults] = useState<IGoodsGroupSelectOption[]>([])
  const [categoriesResults, setCategoriesResults] = useState<
    IBuyoutCategorySelectOption[] | ITrackCategorySelectOption[]
  >([])

  const [brandsResults, setBrandsResults] = useState<ICatBrandSelectOption[]>([])

  const {categories, groups, brands} = selectValues

  const isFirstRun = useRef(true)

  useEffect(() => {
    filterCategories('')
  }, [])

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false
      return
    }

    if (
      !groupsResults
        .find((item) => +item.value === +groups.value)
        ?.items?.find((item) => item.id === categories.value)
    ) {
      setFieldValue(inputName.categories, {value: '', label: ''})
    }
    filterCategories('')
  }, [groups.value])

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false
      return
    }
    setFieldValue(inputName.brands, {value: '', label: ''})
    console.log('v', categories.value)

    if (groups.value === '' && categories.group) {
      setFieldValue(inputName.groups, categories.group)
    }
    console.log('c', categories, categoriesResults)
    const foundBrands = categoriesResults.find((cat) => cat.value === categories.value)
    console.log('fb', foundBrands)

    if (categories.brands && categories.brands?.length > 0) {
      filterBrands(categories.brands)
    } else if (brands && brands.value !== '' && categories.value !== '') {
      console.log('br', brands)
      setBrandsResults([brands])

      setFieldValue(inputName.brands, brands)
    } else {
      console.log('set as empty')

      setBrandsResults([])
    }
  }, [categories.value])

  const filterBrands = (brands: ICatBrandSelectOption[]) => {
    const reformedBrands = brands.map((item) => {
      return {
        ...item,
        value: item.id || '',
        label:
          item.commission && item.commission > 0
            ? `${item.name} (+${item.commission}%)`
            : item.name || '',
      }
    })
    setBrandsResults(reformedBrands)
  }

  const filterCategories = (query: string) => {
    setResultsLoading(true)
    apiRequest(query, selectValues.groups.value || '')
      .then((res) => {
        const reformedGroups = res.map((item) => {
          return {
            ...item,
            value: item.id,
            label: item.name,
          }
        })
        setGroupsResults(reformedGroups)

        let reformedCategories: IBuyoutCategorySelectOption[] | ITrackCategorySelectOption[]
        if (groups.value === '') {
          const items = res
            .flatMap((group) =>
              group.items.map((item) => {
                return {...item, group: {value: group.id, label: group.name}}
              })
            )
            .filter((item) => item !== null)

          reformedCategories = items.map((item: IGoodsCategoryItem) => {
            return {
              ...item,
              value: item.id,
              label: item.synonym && query ? item.translate + ` (${item.synonym})` : item.translate,
              brands: item.brands.map((item) => {
                return {...item, label: item.name, value: item.id}
              }),
            }
          })
        } else {
          const group = res.find((item) => groups.value === item.id)

          if (group) {
            reformedCategories = group.items.map((item: IGoodsCategoryItem) => {
              return {
                ...item,
                value: item.id,
                label:
                  item.synonym && query ? item.translate + ` (${item.synonym})` : item.translate,
                group: {value: group.id, label: group.name},
                brands: item.brands.map((item) => {
                  return {...item, label: item.name, value: item.id}
                }),
              }
            })
          } else {
            reformedCategories = []
          }
        }
        setCategoriesResults(reformedCategories)
      })
      .catch((err) => console.log(err))
      .finally(() => setResultsLoading(false))
  }

  return (
    <>
      <SearchGroupSelect
        inputName={inputName.groups}
        setFieldValue={setFieldValue}
        value={groups}
        options={groupsResults}
        isLoading={resultsLoading}
      />

      <SearchCategorySelect
        inputName={inputName.categories}
        setFieldValue={setFieldValue}
        value={categories}
        options={categoriesResults}
        isLoading={resultsLoading}
        onInputChange={filterCategories}
        selectValues={selectValues}
        apiRequest={apiRequest}
        setCategoriesResults={setCategoriesResults}
        setResultsLoading={setResultsLoading}
      />

      {brandsResults.length > 0 && (
        <SearchBrandSelect
          inputName={inputName.brands}
          setFieldValue={setFieldValue}
          value={brands}
          options={brandsResults}
          isLoading={resultsLoading}
        />
      )}
    </>
  )
}
