import {FormattedMessage} from 'react-intl'

interface Props {
  handleStepperItemClick: (e: React.MouseEvent<HTMLSpanElement>, number: number) => void
  currentStepperIndex: number
}

export function TrackStepper({handleStepperItemClick, currentStepperIndex}: Props) {
  return (
    <div className='d-flex justify-content-md-center justify-content-xl-start flex-row-auto w-100 w-xl-300px mb-3 mb-md-0'>
      <div className='stepper-nav ps-lg-10'>
        <div
          className='stepper-item current CustomStepper'
          style={{cursor: 1 < currentStepperIndex ? 'pointer' : ''}}
          data-kt-stepper-element='nav'
          onClick={(e: React.MouseEvent<HTMLSpanElement>) => handleStepperItemClick(e, 1)}
        >
          <div className='stepper-wrapper'>
            {' '}
            <div className='stepper-icon w-40px h-40px'>
              <span className='stepper-number'>1</span>
            </div>
            <div className='stepper-label'>
              <h3 className='stepper-title'>
                <FormattedMessage id='TRACKS.ADD_MODAL.FORM.GEN_TAB' />
              </h3>
            </div>
            <span className='arrow'>
              <span></span>
              <span></span>
            </span>
          </div>
        </div>{' '}
        <div
          className='stepper-item CustomStepper'
          data-kt-stepper-element='nav'
          style={{cursor: 2 < currentStepperIndex ? 'pointer' : ''}}
          onClick={(e: React.MouseEvent<HTMLSpanElement>) => handleStepperItemClick(e, 2)}
        >
          <div className='stepper-line h-15px h-md-40px'></div>

          <div className='stepper-wrapper'>
            <div className='stepper-icon w-40px h-40px'>
              <span className='stepper-number'>2</span>
            </div>
            <div className='stepper-label'>
              <h3 className='stepper-title'>
                <FormattedMessage id='TRACKS.ADD_MODAL.FORM.CONTENT_TAB' />
              </h3>
            </div>
            <span className='arrow'>
              {' '}
              <span></span>
              <span></span>
            </span>
          </div>
        </div>
        <div
          className='stepper-item CustomStepper'
          style={{cursor: 3 < currentStepperIndex ? 'pointer' : ''}}
          data-kt-stepper-element='nav'
          onClick={(e: React.MouseEvent<HTMLSpanElement>) => handleStepperItemClick(e, 3)}
        >
          <div className='stepper-line h-15px h-md-40px'></div>

          <div className='stepper-wrapper'>
            <div className='stepper-icon w-40px h-40px'>
              <span className='stepper-number'>3</span>
            </div>

            <div className='stepper-label'>
              <h3 className='stepper-title'>
                <FormattedMessage id='TRACKS.ADD_MODAL.FORM.TYPE_TAB' />
              </h3>
            </div>
            <span className='arrow'>
              {' '}
              <span></span>
              <span></span>
            </span>
          </div>
        </div>
        <div
          className='stepper-item CustomStepper'
          style={{cursor: 4 < currentStepperIndex ? 'pointer' : ''}}
          data-kt-stepper-element='nav'
          onClick={(e: React.MouseEvent<HTMLSpanElement>) => handleStepperItemClick(e, 4)}
        >
          <div className='stepper-line h-15px h-md-40px'></div>

          <div className='stepper-wrapper'>
            {' '}
            <div className='stepper-icon w-40px h-40px'>
              <span className='stepper-number'>4</span>
            </div>
            <div className='stepper-label'>
              <h3 className='stepper-title'>
                <FormattedMessage id='TRACKS.ADD_MODAL.FORM.INVOICE_TAB' />
              </h3>
            </div>
            <span className='arrow'>
              {' '}
              <span></span>
              <span></span>
            </span>
          </div>
        </div>{' '}
        <div
          className='stepper-item CustomStepper'
          data-kt-stepper-element='nav'
          style={{cursor: 5 < currentStepperIndex ? 'pointer' : ''}}
          onClick={(e: React.MouseEvent<HTMLSpanElement>) => handleStepperItemClick(e, 5)}
        >
          <div className='stepper-line h-15px h-md-40px'></div>

          <div className='stepper-wrapper'>
            <div className='stepper-icon w-40px h-40px'>
              <span className='stepper-number'>5</span>
            </div>

            <div className='stepper-label'>
              <h3 className='stepper-title'>
                <FormattedMessage id='TRACKS.ADD_MODAL.FORM.INSPECTION_TAB' />
              </h3>
            </div>
            <span className='arrow'>
              <span></span>
              <span></span>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}
