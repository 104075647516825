import {ISetFieldValue} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {components} from 'react-select'
import Select, {createFilter} from 'react-select'
import {IBasicSelect} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {FormattedMessage, useIntl} from 'react-intl'
import {customStyles} from '../../form-elements/select/ReactSelectTemplate'

interface Props {
  setFieldValue: ISetFieldValue
  value: IBasicSelect | {value: ''; label: ''}
  inputName: string
  isLoading: boolean
  noOptionsMessage?: string
  disabled?: boolean
  options: IBasicSelect[]
}

export function SearchGroupSelect({
  setFieldValue,
  value,
  inputName,
  noOptionsMessage = 'Не найдено',
  disabled = false,
  options,
  isLoading,
}: Props) {
  const intl = useIntl()

  const NoOptionsMessage = (props: any) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className='custom-css-class'>{noOptionsMessage}</span>
      </components.NoOptionsMessage>
    )
  }

  const handleOnChange = (selectedValue: IBasicSelect | null) => {
    if (selectedValue !== null) {
      setFieldValue(inputName, selectedValue)
    } else {
      setFieldValue(inputName, {value: '', label: ''})
    }
  }

  return (
    <div className={`fv-row d-flex w-100 mb-5`}>
      <label
        className={`d-flex position-relative  fs-5 flex-column fw-bold mb-2 w-100`}
        htmlFor={inputName}
      >
        <div className='mb-2'>
          {' '}
          <span className={`fs-6`}>
            <FormattedMessage id='COMPONENTS.SearchGroupSelect.GROUP' />
          </span>
        </div>
        <Select
          styles={customStyles}
          options={options}
          form={inputName}
          isDisabled={disabled}
          components={{NoOptionsMessage}}
          isClearable={value.value === '' ? false : true}
          loadingMessage={() => intl.formatMessage({id: 'GENERAL.BUTTONS.WAIT'})}
          placeholder={intl.formatMessage({
            id: 'COMPONENTS.SearchGroupSelect.PLACEHOLDER',
          })}
          isLoading={isLoading}
          value={value}
          filterOption={createFilter()}
          onChange={handleOnChange}
        />
      </label>
    </div>
  )
}
