import {setLocale} from "yup";

export const setYupLocale = (intl:any) => {
  setLocale({
    mixed: {
      default: intl.formatMessage({ id: 'VALIDATION.INVALID' } ),
      required: intl.formatMessage({ id: 'VALIDATION.REQUIRED' } ),
    },
    string: {
      min: ({ min }) => intl.formatMessage({ id: 'VALIDATION.MIN_LENGTH' }, { min } ),
      max: ({ max }) => intl.formatMessage({ id: 'VALIDATION.MAX_LENGTH' }, { max } ),
      email: intl.formatMessage({ id: 'VALIDATION.EMAIL' } ),
    },
    number: {
      positive: intl.formatMessage({ id: 'VALIDATION.POSITIVE' } ),
      integer: intl.formatMessage({ id: 'VALIDATION.INT' } ),
    },
    array: {
      min: ({ min }) => intl.formatMessage({ id: 'VALIDATION.ARRAY_MIN_LENGTH' }, { min } ),
    },
  })
}
