/* eslint-disable jsx-a11y/anchor-is-valid */
import {FormattedMessage, useIntl} from 'react-intl'
import {Buyout} from '../../../../core/_models/_buyout-models'

type Props = {
  buyout: Buyout
}

const BuyoutItemsInfoCell = ({buyout}: Props) => {
  const {prods} = buyout
  const intl = useIntl()
  return (
    <div>
      {prods.length > 4 ? (
        <>
          {prods?.slice(0, 4).map(({name, qty, cost}, index) => (
            <span key={index} className='text-muted fw-semobold text-muted d-block fs-7'>
              {!name && !cost
                ? ''
                : `${name ? `${name}` : '-'} x ${qty ? `${qty}` : '-'} x ${
                    cost ? `$${cost}` : '-'
                  }`}
            </span>
          ))}
          <span className='text-muted fw-semobold text-muted d-block fs-7'>
            <FormattedMessage
              id='GENERAL.COUNTABLES.MORE.GOODS'
              values={{itemCount: prods.length - 4}}
            />
          </span>
        </>
      ) : (
        prods?.map(({name, qty, cost}, index) => (
          <span key={index} className='text-muted fw-semobold text-muted d-block fs-7'>
            {!name && !cost
              ? ''
              : `${name ? `${name}` : '-'} x ${qty ? `${qty}` : '-'} x ${cost ? `$${cost}` : '-'}`}
          </span>
        ))
      )}
    </div>
  )
}

export {BuyoutItemsInfoCell}
