import {Navigate, Routes, Route, Outlet, useLocation} from 'react-router-dom'
import {PageTitle} from '../../../../_metronic/layout/core'
import {BuyoutHeader} from './header/BuyoutHeader'
import {BuyoutSettings} from './settings/BuyoutSettings'
import {BuyoutStatus} from './status/BuyoutStatus'
import {BuyoutContent} from './content/BuyoutContent'
import {BuyoutPrices} from './price/BuyoutPrices'
import {useEffect, useState} from 'react'
import {useQuery} from 'react-query'
import {getBuyoutById, getBuyoutLastMessage} from '../core/_requests'
import {Buyout} from '../core/_models/_buyout-models'
import {useIntl} from 'react-intl'
import {BuyoutComments} from './comments/BuyoutComments'

interface Props {
  id: string | number
}

const BuyoutPage = ({id}: Props) => {
  const {data: buyoutById} = useQuery('buyout', () => {
    return getBuyoutById(id)
  })
  const {data: lastBuyoutMessage} = useQuery(
    'last-buyout-message',
    () => {
      return getBuyoutLastMessage(id)
    },
    {cacheTime: 0}
  )

  const intl = useIntl()

  const location = useLocation()
  const {pathname} = location

  const [currBuyout, setCurrBuyout] = useState<Buyout | null>(null)
  const isArchive = pathname.includes('/archived-buyouts') ? true : false

  useEffect(() => {
    if (buyoutById) {
      setCurrBuyout({...buyoutById, lastMessage: lastBuyoutMessage || undefined})
    }
  }, [buyoutById, lastBuyoutMessage])

  return (
    <>
      {currBuyout !== null ? (
        <>
          {currBuyout && buyoutById && (
            <Routes>
              <Route
                element={
                  <>
                    <BuyoutHeader
                      buyout={currBuyout}
                      setCurrBuyout={setCurrBuyout}
                      isArchive={isArchive}
                    />
                    <Outlet />
                  </>
                }
              >
                <Route
                  path={`content/:id`}
                  element={
                    <>
                      <PageTitle>
                        {intl.formatMessage({id: 'BUYOUT_PAGE.CONTENT_TAB'}, {items: null})}
                      </PageTitle>
                      <BuyoutContent
                        buyout={currBuyout}
                        setCurrBuyout={setCurrBuyout}
                        isArchive={isArchive}
                      />
                    </>
                  }
                />

                <Route
                  path={`comments/:id`}
                  element={
                    <>
                      <PageTitle>{intl.formatMessage({id: 'BUYOUT_PAGE.COMMENTS_TAB'})}</PageTitle>
                      <BuyoutComments buyout={currBuyout} />
                    </>
                  }
                />

                <Route
                  path={`status/:id`}
                  element={
                    <>
                      <PageTitle>{intl.formatMessage({id: 'BUYOUT_PAGE.STATUSES_TAB'})}</PageTitle>
                      <BuyoutStatus buyout={currBuyout} />
                    </>
                  }
                />
                <Route
                  path={`price/:id`}
                  element={
                    <>
                      <PageTitle>{intl.formatMessage({id: 'BUYOUT_PAGE.COST_TAB'})}</PageTitle>
                      <BuyoutPrices buyout={currBuyout} isArchive={isArchive} />{' '}
                    </>
                  }
                />
                <Route
                  path={`settings/:id`}
                  element={
                    <>
                      <PageTitle>{intl.formatMessage({id: 'BUYOUT_PAGE.SETTINGS_TAB'})}</PageTitle>
                      <BuyoutSettings
                        buyout={currBuyout}
                        setCurrBuyout={setCurrBuyout}
                        isArchive={isArchive}
                      />
                    </>
                  }
                />

                <Route index element={<Navigate to={`content/${id}`} />} />
              </Route>
            </Routes>
          )}
        </>
      ) : (
        intl.formatMessage({id: 'GENERAL.BUTTONS.WAIT'})
      )}
    </>
  )
}

export default BuyoutPage
