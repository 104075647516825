import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {FormattedMessage, useIntl} from 'react-intl'
import {KTSVG} from '../../../../../_metronic/helpers'
import {statusColor} from '../../../../../_metronic/helpers/custom/funcs/statusColor'
import {Parcel} from '../../core/_models/_parcels-models'

interface Props {
  parcel: Parcel
}

export function ParcelHeaderInfo({parcel}: Props) {
  const {c_code, code, type_delive, weight, length, width, height, cost, prods, c_debt} = parcel
  const parcelCode = c_code || code
  const intl = useIntl()
  return (
    <div className='flex-grow-1 w-100'>
      <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
        <div className='d-flex flex-column overflow-auto overflow-md-unset overflow-lg-unset'>
          <div className='d-flex align-items-md-center mb-4 align-items-start flex-column flex-md-row justify-content-between justify-content-md-start'>
            <div className='d-flex align-items-center mb-2 mb-md-none'>
              {type_delive == 0 ? (
                <KTSVG
                  path='/media/icons/flight.svg'
                  className='svg-icon-primary svg-icon-3 me-1'
                />
              ) : (
                <KTSVG
                  path='/media/icons/anchor.svg'
                  className='svg-icon-primary svg-icon-3 me-1'
                />
              )}
              <p className='text-gray-800 m-0 fs-6 fs-md-2 fw-bolder me-3 d-none d-md-block'>
                {parcelCode}
              </p>{' '}
              <div className='d-md-none d-lg-none'>
                {' '}
                <OverlayTrigger placement={'top'} overlay={<Tooltip>{parcelCode}</Tooltip>}>
                  <p className='text-gray-800 m-0 fs-6 fs-md-2 fw-bolder me-3'>{parcelCode}</p>
                </OverlayTrigger>
              </div>{' '}
            </div>
            {c_debt && c_debt.to_release > 0 && (
              <span className={`p-1 me-2 fw-semobold  StatusBar__status ${statusColor(false)}`}>
                <FormattedMessage id='PARCELS.LIST.STATUSES.TO_BE_PAID' />
              </span>
            )}
          </div>

          <div className='d-flex flex-wrap fw-bold fs-7 mb-4 pe-2'>
            <span className='d-flex align-items-center text-gray-400 me-5 mb-2'>
              <KTSVG
                path='/media/icons/duotune/electronics/elc008.svg'
                className='svg-icon-4 me-1'
              />
              {weight ? `${weight} ${intl.formatMessage({id: 'GENERAL.MEASURES.KG'})}` : '--,'}
            </span>

            <span className='d-flex align-items-center text-gray-400 me-5 mb-2'>
              {`${
                height ? `${height}${intl.formatMessage({id: 'GENERAL.MEASURES.CM'})}` : '--'
              } x ${
                width ? `${width}${intl.formatMessage({id: 'GENERAL.MEASURES.CM'})}` : '--'
              } x ${length ? `${length}${intl.formatMessage({id: 'GENERAL.MEASURES.CM'})}` : '--'}`}
            </span>

            {prods.length > 0 ? (
              <span className='d-flex align-items-center text-gray-400 me-5 mb-2'>
                <span>$</span>
                <span>{cost}</span>
              </span>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
