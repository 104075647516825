import {FormattedMessage} from 'react-intl'
import {KTSVG} from '../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'

interface Props {
  trackNumber: string
}

const TrackModalHeader = ({trackNumber}: Props) => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  return (
    <div className='modal-header px-6'>
      {/* begin::Modal title */}
      <h2 className='fw-bolder'>
        <FormattedMessage
          id={itemIdForUpdate ? 'TRACKS.ADD_MODAL.HEADER.EDIT' : 'TRACKS.ADD_MODAL.HEADER.CREATE'}
          values={{trackNumber}}
        />
      </h2>
      {/* end::Modal title */}

      {/* begin::Close */}
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-users-modal-action='close'
        onClick={() => setItemIdForUpdate(undefined)}
        style={{cursor: 'pointer'}}
      >
        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
      </div>
      {/* end::Close */}
    </div>
  )
}

export {TrackModalHeader}
