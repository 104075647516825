/* eslint-disable jsx-a11y/anchor-is-valid */ import {FC, useEffect, useRef} from 'react'
import {getCSSVariableValue} from '../../../_metronic/assets/ts/_utils'
import {useThemeMode} from '../../../_metronic/partials'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {DashboardItemStat} from './DashboardItemStat'
import {useRowSelect} from 'react-table'
import {IDashboardItem} from './core/_models'
import {Link} from 'react-router-dom'

interface Props {
  item: IDashboardItem
  className: string
  chartSize?: number
  chartLine?: number
  chartRotate?: number
}

const DashboardItem = ({
  className,
  chartSize = 70,
  chartLine = 11,
  chartRotate = 145,
  item,
}: Props) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  useEffect(() => {
    refreshChart()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode])

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    setTimeout(() => {
      initChart(chartSize, chartLine, chartRotate)
    }, 10)
  }

  const {rows, title, number, linkTo, blank, type} = item

  return (
    <div className={`card card-flush ${className}  flex-shrink-1`}>
      <div className='card-header pt-5'>
        {blank === true ? (
          <a href={toAbsoluteUrl(linkTo)} target='_blank' rel='noreferrer noopener'>
            <div className='card-title d-flex flex-column'>
              <div className='d-flex align-items-center'>
                {type === 'balance' && (
                  <span className='fs-4 fw-semibold text-gray-400 me-1 align-self-start'>$</span>
                )}{' '}
                <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>{number}</span>
              </div>
              <span className='text-gray-400 pt-1 fw-semibold fs-6'>{title}</span>
            </div>
          </a>
        ) : (
          <Link to={`/${linkTo}`}>
            <div className='card-title d-flex flex-column'>
              <div className='d-flex align-items-center'>
                {type === 'balance' && (
                  <span className='fs-4 fw-semibold text-gray-400 me-1 align-self-start'>$</span>
                )}{' '}
                <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>{number}</span>
              </div>
              <span className='text-gray-400 pt-1 fw-semibold fs-6'>{title}</span>
            </div>
          </Link>
        )}

        {blank === true ? (
          <a
            href={toAbsoluteUrl(linkTo)}
            target='_blank'
            rel='noreferrer noopener'
            className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end'
          >
            <KTSVG path='media/icons/duotune/arrows/arr095.svg' className='svg-icon-2' />
          </a>
        ) : (
          <Link
            to={`/${linkTo}`}
            className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end'
          >
            <KTSVG path='media/icons/duotune/arrows/arr095.svg' className='svg-icon-2' />
          </Link>
        )}
      </div>

      <div className='card-body pt-2 pb-4 d-flex flex-wrap align-items-center'>
        <div className='card-body pt-5'>
          {rows.map((row, index) => (
            <DashboardItemStat
              row={row}
              key={index}
              index={index}
              rowsLength={rows.length}
              type={type}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

const initChart = function (
  chartSize: number = 70,
  chartLine: number = 11,
  chartRotate: number = 145
) {
  const el = document.getElementById('kt_card_widget_17_chart')
  if (!el) {
    return
  }
  el.innerHTML = ''

  var options = {
    size: chartSize,
    lineWidth: chartLine,
    rotate: chartRotate,
    //percent:  el.getAttribute('data-kt-percent') ,
  }

  const canvas = document.createElement('canvas')
  const span = document.createElement('span')

  // @ts-ignore
  if (typeof G_vmlCanvasManager !== 'undefined') {
    // @ts-ignore
    G_vmlCanvasManager.initElement(canvas)
  }

  const ctx = canvas.getContext('2d')
  canvas.width = canvas.height = options.size

  el.appendChild(span)
  el.appendChild(canvas)

  // @ts-ignore
  ctx.translate(options.size / 2, options.size / 2) // change center
  // @ts-ignore
  ctx.rotate((-1 / 2 + options.rotate / 180) * Math.PI) // rotate -90 deg

  //imd = ctx.getImageData(0, 0, 240, 240);
  const radius = (options.size - options.lineWidth) / 2

  const drawCircle = function (color: string, lineWidth: number, percent: number) {
    percent = Math.min(Math.max(0, percent || 1), 1)
    if (!ctx) {
      return
    }

    ctx.beginPath()
    ctx.arc(0, 0, radius, 0, Math.PI * 2 * percent, false)
    ctx.strokeStyle = color
    ctx.lineCap = 'round' // butt, round or square
    ctx.lineWidth = lineWidth
    ctx.stroke()
  }

  // Init 2
  drawCircle('#E4E6EF', options.lineWidth, 100 / 100)
  drawCircle(getCSSVariableValue('--kt-primary'), options.lineWidth, 100 / 150)
  drawCircle(getCSSVariableValue('--kt-success'), options.lineWidth, 100 / 250)
}

export {DashboardItem}
