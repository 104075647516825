import {Navigate, Routes, Route, Outlet, useLocation} from 'react-router-dom'
import {PageTitle} from '../../../../_metronic/layout/core'
import {TrackHeader} from './header/TrackHeader'
import {useQuery} from 'react-query'
import {TrackOverview} from './overview/TrackOverview'
import {TrackContent} from './content/TrackContent'
import {TrackReceiver} from './receiver/TrackReceiver'
import {TrackInvoice} from './invoice/TrackInvoice'
import {TrackInspection} from './inspection/TrackInspection'
import {TrackStatus} from './status/TrackStatus'
import {getTrackById} from '../core/_requests'
import {useEffect, useState} from 'react'
import {Track} from '../core/_models/_tracks-models'
import {TrackParcel} from './parcel/TrackParcel'
import {FormattedMessage, useIntl} from 'react-intl'
import {AllTrackParcels} from './all-parcels/AllTrackParcels'

interface Props {
  id: string | number
}

const TrackPage = ({id}: Props) => {
  const {data: trackById} = useQuery('track', () => {
    return getTrackById(id)
  })
  const intl = useIntl()

  const location = useLocation()
  const {pathname} = location

  const [currTrack, setCurrTrack] = useState<Track | null>(null)
  console.log('ct', currTrack)

  const isArchive =
    pathname.includes('/archived-tracks') || currTrack?.delivered === 1 ? true : false

  useEffect(() => {
    if (trackById) {
      setCurrTrack(trackById)
    }
  }, [trackById])

  return (
    <>
      {currTrack !== null ? (
        <>
          {currTrack && trackById && (
            <Routes>
              <Route
                element={
                  <>
                    <TrackHeader track={currTrack} isArchive={isArchive} />
                    <Outlet />
                  </>
                }
              >
                <Route
                  path={`overview/:id`}
                  element={
                    <>
                      <PageTitle>{intl.formatMessage({id: 'TRACK_PAGE.GEN_TAB'})}</PageTitle>
                      <TrackOverview
                        track={currTrack}
                        setCurrTrack={setCurrTrack}
                        isArchive={isArchive}
                      />
                    </>
                  }
                />
                <Route
                  path={`parcel/:id`}
                  element={
                    <>
                      <PageTitle>{intl.formatMessage({id: 'TRACK_PAGE.TYPE_TAB'})}</PageTitle>
                      <TrackParcel
                        track={currTrack}
                        setCurrTrack={setCurrTrack}
                        isArchive={isArchive}
                      />
                    </>
                  }
                />
                <Route
                  path={`content/:id`}
                  element={
                    <>
                      <PageTitle>{intl.formatMessage({id: 'TRACK_PAGE.CONTENT_TAB'})}</PageTitle>
                      <TrackContent
                        track={currTrack}
                        setCurrTrack={setCurrTrack}
                        isArchive={isArchive}
                      />
                    </>
                  }
                />
                <Route
                  path={`invoice/:id`}
                  element={
                    <>
                      <PageTitle>{intl.formatMessage({id: 'TRACK_PAGE.INVOICE_TAB'})}</PageTitle>
                      <TrackInvoice
                        track={currTrack}
                        setCurrTrack={setCurrTrack}
                        isArchive={isArchive}
                      />
                    </>
                  }
                />

                <Route
                  path={`control/:id`}
                  element={
                    <>
                      <PageTitle>{intl.formatMessage({id: 'TRACK_PAGE.INSPECTION_TAB'})}</PageTitle>
                      <TrackInspection
                        track={currTrack}
                        setCurrTrack={setCurrTrack}
                        isArchive={isArchive}
                      />
                    </>
                  }
                />
                <Route
                  path={`status/:id`}
                  element={
                    <>
                      <PageTitle>{intl.formatMessage({id: 'TRACK_PAGE.STATUSES_TAB'})}</PageTitle>
                      <TrackStatus track={currTrack} />
                    </>
                  }
                />
                <Route
                  path={`all-parcels/:id`}
                  element={
                    <>
                      <PageTitle>{intl.formatMessage({id: 'TRACK_PAGE.PARCELS_TAB'})}</PageTitle>
                      <AllTrackParcels track={currTrack} />
                    </>
                  }
                />

                <Route
                  path={`receiver/:id`}
                  element={
                    <>
                      <PageTitle>{intl.formatMessage({id: 'TRACK_PAGE.RECEIVER_TAB'})}</PageTitle>
                      <TrackReceiver
                        track={currTrack}
                        setCurrTrack={setCurrTrack}
                        isArchive={isArchive}
                      />
                    </>
                  }
                />
                <Route index element={<Navigate to={`overview/${id}`} />} />
              </Route>
            </Routes>
          )}
        </>
      ) : (
        <FormattedMessage id='GENERAL.BUTTONS.WAIT' />
      )}
    </>
  )
}

export default TrackPage
