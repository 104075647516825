/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useId, useState} from 'react'
import {useFormik} from 'formik'
import {usePageData} from '../../../layout/core'
import * as Yup from 'yup'
import {checkout} from '../../../../app/pages/balance/core/_requests'
import {FormattedMessage, useIntl} from 'react-intl'
import {Modal} from 'react-bootstrap'
import {useQueryClient} from 'react-query'
import {QUERIES} from '../../../helpers'
import {useAuth} from '../../../../app/modules/auth'

const checkoutSchema = (intl: any) =>
  Yup.object().shape({
    sum: Yup.number().required(intl.messages.YUP_VALIDATION_PAYMENT_ENTER_SUM).positive(),
    comment: Yup.string(),
  })
const CheckoutModal: FC = () => {
  const [isSubmitting, setSubmitting] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [cardDataValid, setCDV] = useState({ccnumber: false, ccexp: false, cvv: false})
  const {pageCustomData, setPageCustomData, addNotification} = usePageData()
  const {currentUser} = useAuth()
  const intl = useIntl()
  const queryClient = useQueryClient()
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      sum: pageCustomData?.checkoutSum || '',
      debts: pageCustomData?.debts,
      comment: '',
      payment_token: '',
    },
    validationSchema: checkoutSchema(intl),
    onSubmit: async () => {
      setSubmitting(true)
      try {
        // @ts-ignore
        window.CollectJS?.startPaymentRequest()
      } catch (ex) {
        console.error(ex)
      }
    },
  })
  useEffect(() => {
    if (submitted && formik.values.payment_token && formik.values.sum > 0)
      checkout(
        parseFloat(formik.values.sum as string),
        formik.values.comment,
        formik.values.payment_token,
        pageCustomData?.debts
      ).then((data) => {
        if (data.success) {
          formik.setStatus(false)
          setPageCustomData({checkoutShow: false, checkoutSum: ''})
          if (pageCustomData?.confirmRequest && pageCustomData?.confirmId) {
            pageCustomData?.confirmRequest(pageCustomData?.confirmId)
          }
          queryClient.invalidateQueries(QUERIES.BALANCE_CURRENT)

          addNotification({
            header: 'GENERAL.DONE_TAB',
            body: 'GENERAL.TOOLTIPS.SUCCESSFUL_PAYMENT',
          })
          formik.resetForm()
          setSubmitted(false)
        } else {
          formik.setStatus(data.err)
        }
      })
  }, [formik.values])

  const finishSubmit = (response: any) => {
    formik.setFieldValue('payment_token', response.token)
    setSubmitting(false)
    setSubmitted(true)
  }
  const timeout = () => {
    formik.setFieldValue('payment_token', '')
    setSubmitting(false)
    setSubmitted(true)
  }
  useEffect(() => {
    if (pageCustomData?.checkoutShow)
      // @ts-ignore
      window.CollectJS?.configure({
        variant: 'inline',
        invalidCss: {
          color: '#B40E3E',
        },
        validCss: {
          color: '#14855F',
        },
        validationCallback: function (field: string, status: any, message: string) {
          setCDV((prev) => ({...prev, [field]: !!status}))
        },
        timeoutDuration: 10000,
        timeoutCallback: timeout,
        customCss: {
          'background-color': 'transparent',
          border: 'transparent',
          height: '100%',
        },
        styleSniffer: true,
        callback: (response: any) => {
          finishSubmit(response)
        },
        fields: {
          ccnumber: {
            placeholder: intl.formatMessage({id: 'PAYMENT.FORM.CC_NUMBER'}),
            selector: '#ccnumber',
          },
          ccexp: {
            placeholder: intl.formatMessage({id: 'PAYMENT.FORM.CC_EXPIRATION'}),
            selector: '#ccexp',
          },
          cvv: {
            placeholder: intl.formatMessage({id: 'PAYMENT.FORM.CVV'}),
            selector: '#cvv',
          },
        },
      })
  }, [pageCustomData?.checkoutShow])
  const handleClose = () => setPageCustomData((prev: any) => ({...prev, checkoutShow: false}))

  return (
    <Modal show={pageCustomData?.checkoutShow} onHide={handleClose} backdrop='static'>
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage id='PAYMENT.ADD_BUTTON' />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {formik.status && <div className='alert alert-danger'>{formik.status}</div>}

        <form onSubmit={formik.handleSubmit} id='kt_checkout_form'>
          <div className='row mb-3'>
            <label className='col-lg-4 col-form-label required fw-bold fs-6'>
              <FormattedMessage id='PAYMENT.FORM.SUM' />
            </label>

            <div className='col-lg-8 position-relative d-flex flex-column'>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder='0.00'
                {...formik.getFieldProps('sum')}
                onChange={(event) =>
                  formik.setFieldValue('sum', event.target.value.replace(/,/g, '.'))
                }
              />
              {pageCustomData?.showPayAll === true &&
                currentUser?.balance &&
                currentUser?.balance < 0 && (
                  <div className='row d-flex justify-content-center'>
                    <a
                      href='#'
                      className='text-gray-400 btn btn-sm text-hover-primary ps-0'
                      onClick={() => {
                        setPageCustomData({
                          ...pageCustomData,
                          checkoutSum: Math.abs(currentUser?.balance || 0),
                        })
                      }}
                    >
                      Сплатити весь борг ${Math.abs(currentUser?.balance || 0)}
                    </a>
                  </div>
                )}
              <span
                className={`fs-6 fw-normal position-absolute`}
                style={{
                  left: '80%',
                  top: '27%',
                  transform: 'translateY(-50%)',
                }}
              >
                $
              </span>
              {formik.touched.sum && formik.errors.sum && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.sum}</div>
                </div>
              )}
            </div>
          </div>

          <div className='row mb-3'>
            <div className='col-lg-12'>
              <div className='input-group'>
                <span className='input-group-text' id='basic-addon1'>
                  <i className='lar la-credit-card fs-2'></i>
                </span>
                <div
                  id='ccnumber'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0 payment-field'
                />
              </div>
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-lg-6'>
              <div className='input-group'>
                <span className='input-group-text' id='basic-addon1'>
                  <i className='lar la-calendar fs-2'></i>
                </span>
                <div
                  id='ccexp'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0 payment-field'
                />
              </div>
            </div>
            <div className='col-lg-6'>
              <div
                id='cvv'
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0 payment-field'
              />
            </div>
          </div>
          <div className='row mb-3'>
            <label className='form-label fw-bold fs-6'>
              <FormattedMessage id='PAYMENT.FORM.COMMENT' />
            </label>

            <div className='col-lg-12'>
              <textarea
                rows={3}
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                {...formik.getFieldProps('comment')}
              />
              {formik.touched.comment && formik.errors.comment && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.comment}</div>
                </div>
              )}
            </div>
          </div>
        </form>
        {/* {JSON.stringify(cardDataValid)} */}
      </Modal.Body>
      <Modal.Footer>
        <button
          type='button'
          className='btn btn-secondary fw-bold'
          disabled={isSubmitting}
          onClick={handleClose}
        >
          <FormattedMessage id='GENERAL.BUTTONS.CLOSE' />
        </button>
        <button
          id='kt_checkout_submit'
          form='kt_checkout_form'
          type='submit'
          className='btn btn-primary fw-bold'
          disabled={
            isSubmitting || !(cardDataValid.ccnumber && cardDataValid.ccexp && cardDataValid.cvv)
          }
        >
          {!isSubmitting && (
            <FormattedMessage id='GENERAL.BUTTONS.CONFIRM_PAYMENT' values={{sum: null}} />
          )}
          {isSubmitting && (
            <span className='indicator-progress' style={{display: 'block'}}>
              <FormattedMessage id='GENERAL.BUTTONS.WAIT' />
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export {CheckoutModal}
