import {useFormikContext} from 'formik'
import {Dispatch, SetStateAction, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {checkForAnyCommercialProducts} from '../../../../../_metronic/helpers/custom/funcs/checkForCommercial'
import {earlySubmit} from '../../../../../_metronic/helpers/custom/funcs/earlySubmit'
import {handleFileUpload} from '../../../../../_metronic/helpers/custom/funcs/handleFileUpload'
import {ISetFieldValue} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {usePageData} from '../../../../../_metronic/layout/core'
import {DocumentFileInput} from '../../../../modules/custom/form-elements/file-inputs/DocumentFileInput'
import {Notification} from '../../../../modules/custom/Notification'
import {AddTrackForm, IInvoice, IRecentInvoiceRes} from '../../core/_models/_tracks-models'
import {uploadInvoice} from '../../core/_requests'
import {InvoiceCard} from './InvoiceCard'
import {ListOfRecentInvoices} from './ListOfRecentInvoices'
import {TrackInvoiceLinks} from './TrackInvoiceLinks'

interface Props {
  setIsLoading: Dispatch<SetStateAction<boolean>>
  values: AddTrackForm
  invoiceArray: IInvoice[] | []
  setInvoiceArray: Dispatch<SetStateAction<IInvoice[] | []>>
  recentInvoicesData: IRecentInvoiceRes[]
  currentStep: number
}

export function TrackInvoicesTab({
  setIsLoading,
  values,
  invoiceArray,
  setInvoiceArray,
  recentInvoicesData,
  currentStep,
}: Props) {
  const intl = useIntl()

  const {setFieldValue, validateForm, setTouched, setFieldTouched} = useFormikContext()
  const {pageCustomData} = usePageData()

  const handleInvoiceArray = (
    item: IInvoice,
    itemIsAdded: boolean,
    setFieldValue: ISetFieldValue
  ) => {
    setFieldValue('invoice_file', item)
    itemIsAdded === true
      ? setInvoiceArray((prev) => {
          if (prev.length === 1) {
            setFieldValue('invoice_file', null)
          }

          return prev.filter((inv) => inv.file.filename !== item.file.filename)
        })
      : setInvoiceArray((prev) => [...prev, item])
  }

  const saveInvoice = async (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: ISetFieldValue,
    targetValue: string
  ) => {
    setIsLoading(true)

    const linkResponse = await handleFileUpload(
      e,
      setFieldValue,
      'invoice_file',
      'file',
      uploadInvoice
    )

    if (linkResponse) {
      const localFileName = targetValue.split('\\')[targetValue.split('\\').length - 1]
      const newObject = {
        name: localFileName,
        file: {base_url: linkResponse.base_url, filename: linkResponse.filename},
      }
      if (invoiceArray === []) {
        setInvoiceArray([newObject])
      } else {
        setInvoiceArray((prev) => [...prev, newObject])
      }

      setFieldValue('invoice_file', newObject)
    } else {
      console.log('ошибка')
    }
    setIsLoading(false)
  }

  useEffect(() => {
    const anyComProds = checkForAnyCommercialProducts(values.prods)

    validateForm()
      .then((errs) => {
        setTouched({})

        return errs
      })
      .then((errors) => {
        if (pageCustomData?.trackFormSimplified === true) {
        } else {
          earlySubmit({
            currentStep,
            setFieldValue,
            values,
            noFormErrors: Object.keys(errors).length === 0,
            anyComProds,
            allInvoices: invoiceArray,
            invoiceLinks: values.invoiceLinks || [],
          })
        }
      })
  }, [invoiceArray, values.invoiceLinks, currentStep, values.invoice_file])

  return (
    <>
      <div className='w-100'>
        <div className='fv-row mb-10'>
          <TrackInvoiceLinks
            invoiceLinks={values.invoiceLinks || []}
            required={+values.require_invoice === 0 ? false : !invoiceArray[0] ? true : false}
          />
        </div>

        <div className='fv-row'>
          <input
            name={'require_invoice'}
            className='form-control form-control-lg form-control-solid DocumentFileInput visually-hidden'
            type='text'
          />
          <DocumentFileInput
            inputId='invoice-input'
            required={
              +values.require_invoice === 0
                ? false
                : values?.invoiceLinks && values.invoiceLinks[0] === ''
                ? true
                : false
            }
            inputName='invoice_file'
            title={intl.formatMessage({
              id: 'TRACKS.ADD_MODAL.FORM.INVOICE_TITLE',
            })}
            uploadedFiles={invoiceArray}
            setFieldValue={setFieldValue}
            uploadFunction={saveInvoice}
          />
        </div>

        <div className='d-flex flex-gutter flex-wrap mt-8'>
          {invoiceArray.map((item, index) => (
            <InvoiceCard
              key={index}
              title={item.name}
              id={item.file.filename}
              icon='/media/svg/files/pdf.svg'
              handleDelete={setInvoiceArray}
              fileType={item.file.filename.split('.')[item.file.filename.split('.').length - 1]}
              uploadedFile={{
                base: item.file.base_url,
                link: item.file.filename || '',
              }}
              linkEndpoint='/files'
              loading={false}
              invoiceArray={invoiceArray}
              handleInvoiceArray={handleInvoiceArray}
            />
          ))}
        </div>

        <div className='my-8'>
          <Notification
            noteText={intl.formatMessage({
              id: 'TRACKS.ADD_MODAL.FORM.INVOICE_FORMATS_EXPL',
            })}
          />
        </div>
        {recentInvoicesData.length > 0 && (
          <div className='fv-row mb-10 d-flex justify-content-center'>
            <ListOfRecentInvoices
              recentInvoicesData={recentInvoicesData}
              handleInvoiceArray={handleInvoiceArray}
              addedInvoices={invoiceArray}
            />
          </div>
        )}
      </div>
    </>
  )
}
