import {useFormikContext} from 'formik'
import {FormattedMessage, useIntl} from 'react-intl'
import {InputTemplate} from '../../../form-elements/InputTemplate'
import {IPostShimpentProps} from './PostReserve'

export const PostMain = ({
  citiesLoading,
  citiesResults,
  offLoading,
  offResults,
  values,
  filterCities,
  setQuery,
}: IPostShimpentProps) => {
  const intl = useIntl()
  const {setFieldValue} = useFormikContext()

  return (
    <>
      <h3>
        {' '}
        <FormattedMessage id='COMPONENTS.PostShipment.MAIN_NP_TITLE' />
      </h3>
      <div className='d-flex justify-content-around flex-column'>
        <InputTemplate
          addPrompt={false}
          inputName='mainOfficeCity'
          required={true}
          type='select'
          title={intl.formatMessage({id: 'COMPONENTS.PostShipment.CITY'})}
          marginClass='me-4'
          value={values.mainOfficeCity}
          reactSelectprops={{
            options: citiesResults,
            isLoading: citiesLoading,
            onInputChange: filterCities,
            isClearable: true,
            selectName: 'mainOfficeCity',
            setFieldValue: setFieldValue,
            placeholder: intl.formatMessage({id: 'COMPONENTS.PostShipment.CITY_PLACEHOLDER'}),
            currentValue: citiesResults.find((item: any) => item.value === values.mainOfficeCity),
          }}
        />

        <InputTemplate
          addPrompt={false}
          inputName='mainOfficeNumber'
          required={true}
          type='select'
          title={intl.formatMessage({id: 'COMPONENTS.PostShipment.NP'})}
          value={values.mainOfficeNumber}
          reactSelectprops={{
            options: offResults,
            isLoading: offLoading,
            onInputChange: setQuery,
            isClearable: true,
            selectName: 'mainOfficeNumber',
            setFieldValue: setFieldValue,
            placeholder: intl.formatMessage({id: 'COMPONENTS.PostShipment.NP_PLACEHOLDER'}),
            currentValue: offResults.find((item: any) => item.value === values.mainOfficeNumber),
          }}
        />
      </div>
    </>
  )
}
