import {KTSVG} from '../../../_metronic/helpers'

interface Props {
  heading: string
  text: string
  buttonText: string
  handleClick: (isOpen: boolean) => void
  accepted: boolean
}

export function TermsOfService({heading, text, buttonText, handleClick, accepted}: Props) {
  return (
    <div
      className={`notice d-flex ${
        accepted ? 'bg-light-primary border-primary ' : 'border-danger'
      } rounded border border-dashed p-6`}
    >
      {accepted ? (
        <KTSVG
          path='/media/icons/duotune/general/gen048.svg'
          className='svg-icon-2tx svg-icon-primary me-4'
        />
      ) : (
        <KTSVG
          path='/media/icons/duotune/general/gen050.svg'
          className='svg-icon-2tx svg-icon-primary me-4'
        />
      )}
      <div className='d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap'>
        <div className='mb-3 mb-md-0 fw-bold'>
          <h4 className='text-gray-800 fw-bolder'>{heading}</h4>
          <div className='fs-6 text-gray-600 pe-7'>{text}</div>
        </div>
        <button
          className='btn btn-primary px-6 align-self-center text-nowrap'
          data-bs-toggle='modal'
          data-bs-target='#kt_modal_two_factor_authentication'
          onClick={() => handleClick(true)}
        >
          {buttonText}
        </button>
      </div>{' '}
    </div>
  )
}
