/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {useLayout} from '../../../_metronic/layout/core'
import {Languages} from './components/Languages'
import {FormattedMessage} from 'react-intl'

const AuthLayout = () => {
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])
  const {websiteData} = useLayout()

  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed bg-images-none min-h-100'
      style={{
        backgroundImage: `url(${toAbsoluteUrl('/media/custom/P1.png')}),
                       url(${toAbsoluteUrl('/media/custom/P2.png')})`,
        backgroundPosition: 'left bottom, right bottom',
        backgroundSize: 'auto 40%',
      }}
    >
      {/* begin::Content */}
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        {/* begin::Logo */}
        <div className='w-md-500px mb-12 d-flex justify-content-between flex-column flex-md-row'>
          <a
            href={'https://' + websiteData.host}
            target='_blank'
            rel='noreferrer noopener'
            className='d-block mb-6'
          >
            <img
              alt='Logo'
              // src={websiteData.logoUrl ? websiteData.logoPrep + websiteData.logoUrl : ''}
              className='h-45px'
            />
          </a>
          <div className='d-flex justify-content-center justify-content-md-end'>
            <Languages />
          </div>
        </div>
        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div className='w-md-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
          <Outlet />
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
      {/* begin::Footer */}
      {websiteData.links?.length ? (
        <div className='d-flex flex-center flex-column-auto p-10'>
          <div className='d-flex align-items-center fw-bold fs-6'>
            {/*{websiteData.links.map((link, index) => {*/}
            {/*  return link.websiteUrl ? (*/}
            {/*    <a*/}
            {/*      key={index}*/}
            {/*      href={link.websiteUrl}*/}
            {/*      target='_blank'*/}
            {/*      rel='noreferrer'*/}
            {/*      className='text-muted text-hover-primary px-2'*/}
            {/*    >*/}
            {/*      <FormattedMessage id={`AUTH.${link.linkName.toUpperCase()}`} />*/}
            {/*    </a>*/}
            {/*  ) : (*/}
            {/*    ''*/}
            {/*  )*/}
            {/*})}*/}
          </div>
        </div>
      ) : (
        ''
      )}
      {/* end::Footer */}
    </div>
  )
}

export {AuthLayout}
