import {FormattedMessage, useIntl} from 'react-intl'
import {statusColor} from '../../../../_metronic/helpers/custom/funcs/statusColor'
import {User} from '../../../modules/apps/user-management/users-list/core/_models'

interface Props {
  user: User
}

export function ProfileHeaderName({user}: Props) {
  const intl = useIntl()
  const {firstName, lastName, verified} = user
  return (
    <div className='flex-grow-1'>
      <div className='d-flex justify-content-between align-items-start flex-wrap mb-2 w-75'>
        <div className='d-flex flex-column'>
          <div className='d-flex align-items-center mb-4 align-items-start flex-column flex-md-row justify-content-between justify-content-md-start'>
            <div className='d-flex align-items-center mb-2 mb-md-none'>
              <p className='text-gray-800 text-hover-primary fs-2 fw-bolder m-0 me-3'>
                {`${firstName} ${lastName}`}
              </p>
            </div>
            {verified !== undefined ? (
              <span className={`p-1 me-2 fw-semobold  StatusBar__status ${statusColor(+verified)}`}>
                {+verified === 1 && <FormattedMessage id='GENERAL.STATUSES.VERIFIED' />}
                {+verified === 0 && <FormattedMessage id='GENERAL.STATUSES.NOT_VERIFIED' />}
              </span>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}
