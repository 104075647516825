import {FC} from 'react'
import {FormattedDate, FormattedMessage} from 'react-intl'
import {Row} from 'react-table'
import {usePageData} from '../../../../../../_metronic/layout/core'
import {ModalNotification} from '../../../../../modules/custom/modals/ModalNotification'
import {Parcel} from '../../../core/_models/_parcels-models'
import {DateRow} from '../../../core/_models/_parcels-models'

type Props = {
  row: Row<Parcel | DateRow>
}

const CustomRow: FC<Props> = ({row}) => {
  const {setPageCustomData} = usePageData()

  // console.log('r', row)
  const {original} = row
  // @ts-ignore
  const {debt, settings, delivered, on_border} = original

  const addDays = (date: number, days: number) => {
    var result = new Date(date)
    result.setDate(result.getDate() + days)
    return result
  }

  const expDayOfDelivery =
    settings?.exp_time != 0
      ? new Date(settings?.exp_time * 1000)
      : settings?.exp_days
      ? addDays(Date.now(), settings.exp_days)
      : '-'

  const showPayButton = debt ? debt?.to_release > 0 : false
  const showDeliveryDate =
    showPayButton === false && delivered === 0 && !!expDayOfDelivery && on_border === true

  return (
    <>
      {debt || settings ? (
        <tr className='bg-gray-100'>
          <td>
            {' '}
            {original.send_date ? (
              <div>
                <FormattedMessage
                  id='PARCELS.LIST.GROUP_ROW_SENT'
                  values={{
                    date: (
                      <FormattedDate
                        month='long'
                        day='numeric'
                        value={new Date(original.send_date * 1000)}
                      />
                    ),
                  }}
                />
              </div>
            ) : (
              <div></div>
            )}
          </td>
          <td></td>
          <td>
            <div className='d-flex justify-content-center text-black align-items-center px-3'>
              {debt?.sum?.cost ? `$${debt.sum.cost}` : ''}
            </div>
          </td>
          <td colSpan={2}>
            {' '}
            <div className='d-flex justify-content-end text-black align-items-center px-3'>
              {' '}
              <div className='d-flex align-items-center'>
                {showPayButton === true && (
                  <>
                    <button
                      type='button'
                      className='btn btn-sm btn-primary me-2'
                      data-bs-toggle='modal'
                      data-bs-target='#kt_modal_checkout'
                      onClick={() =>
                        setPageCustomData({
                          checkoutShow: true,
                          checkoutSum: debt.to_release,
                        })
                      }
                    >
                      <FormattedMessage
                        id='PARCELS.LIST.PAY_BUTTON'
                        values={{debt: debt.to_release}}
                      />
                    </button>
                    <ModalNotification
                      svg='/media/icons/duotune/general/gen046.svg'
                      addClass='ModalNotification__position ModalNotification__track-status'
                      children={
                        <div className='fs-7 fw-normal'>
                          <FormattedMessage id='PARCELS.LIST.PAY_EXPL' />
                        </div>
                      }
                    />{' '}
                  </>
                )}
                {showDeliveryDate === true && (
                  <p className='m-0'>
                    <FormattedMessage
                      id='PARCELS.LIST.APPR_DELIVERY'
                      values={{
                        date: <FormattedDate month='long' day='numeric' value={expDayOfDelivery} />,
                      }}
                    />
                  </p>
                )}
              </div>
            </div>
          </td>
        </tr>
      ) : (
        <tr {...row.getRowProps()}>
          {row.cells.map((cell) => {
            return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
          })}
        </tr>
      )}
    </>
  )
}

export {CustomRow}
