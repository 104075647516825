/* eslint-disable jsx-a11y/anchor-is-valid */
import {FormattedMessage, useIntl} from 'react-intl'
import {KTSVG} from '../../../../../_metronic/helpers'

interface Props {
  visibleTracks: any[]
}

export function ReferalTable({visibleTracks}: Props) {
  const intl = useIntl()
  return (
    <div className='card-body py-3'>
      <div className='table-responsive'>
        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
          <thead>
            <tr className='fw-bold text-muted'>
              <th className='min-w-150px'>
                <FormattedMessage id='PROFILE.PAGE.REFERAL.TABLE.FULL_NAME' />
              </th>
              <th className='min-w-140px'>
                <FormattedMessage id='PROFILE.PAGE.REFERAL.TABLE.REGISTRATION_DATE' />
              </th>

              <th className='min-w-100px text-center'>
                <FormattedMessage id='PROFILE.PAGE.REFERAL.TABLE.ORDERED' />
              </th>
            </tr>
          </thead>

          <tbody>
            {visibleTracks?.map(({name, registrationDate, ordered}, index) => (
              <tr key={index}>
                <td>
                  <div className='d-flex align-items-center'>
                    <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                      {name}
                    </a>
                  </div>
                </td>
                <td>
                  <span className='text-dark fw-bold text-hover-primary d-block fs-6'>
                    {registrationDate}
                  </span>
                </td>
                <td className='text-center'>
                  {ordered === true ? (
                    <KTSVG
                      path='/media/icons/duotune/general/gen043.svg'
                      className='svg-icon-3 svg-icon-success'
                    />
                  ) : (
                    <KTSVG
                      path='/media/icons/duotune/general/gen040.svg'
                      className='svg-icon-3 svg-icon-danger'
                    />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
