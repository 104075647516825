import React, {ReactNode} from 'react'

/* eslint-disable jsx-a11y/anchor-is-valid */
interface Props {
  price: {
    label: string
    price: number
    currency: string
    bold?: boolean
    details?: ReactNode
    notShowIfNull?: boolean
  }
}

function BuyoutPriceItem({price}: Props) {
  return (
    <>
      {price.notShowIfNull === true && price.price == 0 ? (
        <></>
      ) : (
        <tr>
          <td>
            <p
              className={`text-dark text-hover-primary m-0 d-block fs-6 ${
                price.bold === true ? 'fw-bold' : ''
              }`}
            >
              {price.label}
              {price.details}
            </p>
          </td>

          <td>
            <span
              className={`text-dark text-hover-primary d-block fs-6 ${
                price.bold === true ? 'fw-bold' : ''
              }`}
            >
              {price.currency}
              {price.price}
            </span>
          </td>
        </tr>
      )}
    </>
  )
}

export {BuyoutPriceItem}
