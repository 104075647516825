import axios, {AxiosResponse} from 'axios'
import {Response} from '../../../_metronic/helpers'
import {ICreateChatFromNewBuyoutComment} from './_models'

const BASE_URL = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}${process.env.REACT_APP_API_PATH}`

export async function getChatToken(chatQuery: string) {
  const response = await axios.post(`${BASE_URL}/chat-login?${chatQuery}`)
  const d = response.data
  return d.data
}

export const createChatFromNewBuyoutComment = (data: ICreateChatFromNewBuyoutComment) => {
  return axios.post(`${BASE_URL}/messages/create-from-comment`, data)
}
