/* eslint-disable jsx-a11y/anchor-is-valid */
import {Dispatch, SetStateAction, useEffect} from 'react'
import {MenuComponent} from '../../../../../../_metronic/assets/ts/components'
import {initialQueryState} from '../../../../../../_metronic/helpers'
import {useQueryRequest} from '../../../core/QueryRequestProvider'
import {useQueryResponse, useQueryResponsePagination} from '../../../core/QueryResponseProvider'
import {FormattedMessage, useIntl} from 'react-intl'

interface Props {
  warehouseTracks: boolean
  setWarehouseTracks: Dispatch<SetStateAction<boolean>>
  unpackedTracks: boolean
  setUnpackedTracks: Dispatch<SetStateAction<boolean>>
}

const TracksListFilter = ({
  warehouseTracks,
  setWarehouseTracks,
  unpackedTracks,
  setUnpackedTracks,
}: Props) => {
  const pagination = useQueryResponsePagination()
  const {updateState} = useQueryRequest()
  const {isLoading} = useQueryResponse()
  const {response} = useQueryResponse()
  // @ts-ignore
  const awaitingCount = response?.filters.find((filter) => filter.name === 'awaiting').count
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const resetData = () => {
    updateState({
      filter: undefined,
      ...initialQueryState,
      itemsPerPage: pagination.itemsPerPage || 10,
    })
    setWarehouseTracks(false)
    setUnpackedTracks(false)
  }

  const filterData = (filter: any) => {
    updateState({
      filter: filter,
      ...initialQueryState,
    })
  }

  return (
    <div className='d-flex flex-column align-items-center'>
      <div className='d-flex flex-gutter'>
        {unpackedTracks === false && (
          <button
            disabled={isLoading}
            type='button'
            className={`btn btn-light-primary min-w-125px ${
              awaitingCount && awaitingCount > 0 ? 'mb-2' : ''
            }`}
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            onClick={() => {
              if (
                warehouseTracks === true
                // || unpackedTracks === true
              ) {
                resetData()
              } else {
                filterData({in_storage: 1})
                setWarehouseTracks(true)
              }
            }}
          >
            {warehouseTracks === true ? (
              <FormattedMessage id='TRACKS.PAGE.LIST.FILTER.ALL' />
            ) : (
              <FormattedMessage id='TRACKS.PAGE.LIST.FILTER.WH' />
            )}
          </button>
        )}
        {warehouseTracks === false && (
          <button
            disabled={isLoading}
            type='button'
            className={`btn btn-light-primary min-w-125px ${
              awaitingCount && awaitingCount > 0 ? 'mb-2' : ''
            }`}
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            onClick={() => {
              if (unpackedTracks === true) {
                resetData()
              } else {
                filterData({in_storage: 0})
                setUnpackedTracks(true)
              }
            }}
          >
            {unpackedTracks === true ? (
              <FormattedMessage id='TRACKS.PAGE.LIST.FILTER.ALL' />
            ) : (
              <FormattedMessage id='TRACKS.PAGE.LIST.FILTER.NOT_PACKED' />
            )}
          </button>
        )}
      </div>
      {awaitingCount && awaitingCount > 0 ? (
        <a
          href='#'
          className='text-muted text-hover-primary fw-semibold fs-7'
          onClick={() => {
            filterData({in_storage: 1, dispatch: 0})
          }}
        >
          <FormattedMessage id='TRACKS.PAGE.LIST.FILTER.AWAIT' values={{items: awaitingCount}} />
        </a>
      ) : (
        <></>
      )}
    </div>
  )
}

export {TracksListFilter}
