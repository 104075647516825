import {SelectTemplateWrapper} from './select/SelectTemplateWrapper'
import {
  IBasicSelect,
  ICheckboxProps,
  IReactSelectProps,
  ISelectProps,
  ISetFieldValue,
} from '../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {ErrorMessage, FormikTouched, useFormikContext} from 'formik'
import {RadioCheckTemplate} from './RadioCheckTemplate'
import {NumberTextInput} from './NumberTextInput'
import {TextareaTemplate} from './TextareaTemplate'
import {ReactSelectTemplate} from './select/ReactSelectTemplate'
import {PhoneNumberInput} from './PhoneNumberInput'
import {ReactNode} from 'react'

const radioCheckClass = 'fs-6 row-reverse justify-content-end'
const basicClass = 'fs-5 flex-column'

interface Props {
  inputName: string
  type: string
  required?: boolean
  addPrompt?: boolean
  title?: string
  placeholder?: string
  promptText?: string
  value?: string | number | IBasicSelect
  marginClass?: string
  backgroundClass?: string
  selectProps?: ISelectProps
  reactSelectprops?: IReactSelectProps
  promptSign?: string
  addSymbol?: string | ReactNode
  containerMarginBottom?: boolean
  titleFontSize?: string
  fieldWidth?: string
  symbolMarginTop?: boolean
  autoFocus?: boolean
  inputFontSize?: string
  disabled?: boolean
  setFieldValue?: ISetFieldValue
  numbersArray?: boolean
  phoneArrayOnChange?: (index: number, name: string, value: string) => void
  arrayIndex?: number
  checkboxProps?: ICheckboxProps
  handleChange?: any
  defaultValue?: string | number | IBasicSelect
  objectErrorKey?: string
  custom?: ReactNode
}

function InputTemplate({
  title,
  addPrompt = false,
  promptText,
  inputName,
  placeholder,
  required = false,
  type,
  marginClass,
  backgroundClass,
  value,
  selectProps,
  reactSelectprops,
  promptSign,
  addSymbol,
  containerMarginBottom,
  titleFontSize,
  inputFontSize,
  fieldWidth,
  symbolMarginTop,
  autoFocus,
  disabled,
  setFieldValue,
  numbersArray = true,
  phoneArrayOnChange,
  arrayIndex,
  checkboxProps,
  handleChange,
  defaultValue,
  objectErrorKey,
  custom,
}: Props) {
  return (
    <div
      className={`fv-row d-flex ${fieldWidth ? fieldWidth : 'w-100'}  ${
        marginClass ? marginClass : ''
      } ${type === 'radio' || type === 'checkbox' ? 'flex-column' : 'flex-column'} ${
        containerMarginBottom === false ? '' : 'mb-5'
      }`}
    >
      {/* INPUT BODY */}
      <label
        className={`d-flex position-relative ${
          type === 'radio' || type === 'checkbox' ? radioCheckClass : basicClass
        }  ${addSymbol ? 'me-2' : ''}  
         fw-bold mb-2`}
        htmlFor={inputName}
      >
        {/* TITLE */}
        {title && (
          <div className='mb-2'>
            {' '}
            <span
              className={` ${titleFontSize ? titleFontSize : 'fs-6'} ${
                required ? 'required' : ''
              } ${type === 'radio' ? 'fw-normal' : ''}`}
            >
              {title}
            </span>
            {addPrompt && (
              <i
                className={`fas fa-${promptSign}-circle ms-2 fs-7`}
                data-bs-toggle='tooltip'
                title={promptText}
              ></i>
            )}
          </div>
        )}
        {/*  INPUT TYPE DIVISION */}
        {custom ? (
          custom
        ) : (
          <>
            {' '}
            {/* select */}
            {type === 'select' && selectProps && (
              <SelectTemplateWrapper selectProps={{...selectProps, backgroundClass}} />
            )}
            {type === 'select' && reactSelectprops && (
              <ReactSelectTemplate props={{...reactSelectprops, backgroundClass, disabled}} />
            )}
            {/* number || text */}
            {(type === 'number' || type === 'text' || type === 'mail') && (
              <NumberTextInput
                inputName={inputName}
                placeholder={placeholder}
                type={type}
                disabled={disabled}
                backgroundClass={backgroundClass}
                autoFocus={autoFocus}
                inputFontSize={inputFontSize}
                handleChange={handleChange}
              />
            )}
            {/* tel */}
            {type === 'tel' &&
              setFieldValue &&
              (typeof value === 'string' || typeof value === 'number' || value === null) && (
                <PhoneNumberInput
                  inputName={inputName}
                  setFieldValue={setFieldValue}
                  value={value}
                  numbersArray={numbersArray}
                  handleChange={phoneArrayOnChange}
                  index={arrayIndex}
                />
              )}
            {/* radio || checkbox */}
            {(type === 'radio' || type === 'checkbox') && checkboxProps && (
              <RadioCheckTemplate props={checkboxProps} />
            )}
            {/* textarea */}
            {type === 'textarea' && (
              <TextareaTemplate
                inputName={inputName}
                placeholder={placeholder}
                backgroundClass={backgroundClass}
                inputFontSize={inputFontSize}
                disabled={disabled}
              />
            )}
          </>
        )}
        {addSymbol && (
          <span
            className={`fs-6 ${symbolMarginTop === false ? '' : 'mt-5 '}fw-normal ${
              title ? 'InputTemplate_addSymbol-with-title' : 'InputTemplate_addSymbol'
            }`}
          >
            {addSymbol}
          </span>
        )}{' '}
      </label>

      {/* INPUT ERROR MESSAGE */}

      {/* {touched[inputName as keyof FormikTouched<unknown>] &&
        errors[inputName as keyof FormikTouched<unknown>] && ( */}
      <div className='text-danger'>
        <ErrorMessage name={objectErrorKey ? objectErrorKey : inputName} />
      </div>
      {/* )} */}
    </div>
  )
}

export {InputTemplate}
