/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../../../core/ListViewProvider'
import {Buyout} from '../../../../core/_models/_buyout-models'

type Props = {
  buyout: Buyout
}

const BuyoutNumberCell: FC<Props> = ({buyout}) => {
  const {id, type_delive, link} = buyout

  const {isArchive} = useListView()
  const pagePath = isArchive === true ? '/archived-buyouts/info' : '/buyouts/edit'

  return (
    <div className='d-flex align-items-center'>
      <div className='symbol symbol-45px me-5'>
        <img src={toAbsoluteUrl('/media/icons/duotune/ecommerce/ecm005.svg')} alt='' />
      </div>
      <div className='d-flex justify-content-start flex-column'>
        <Link to={`${pagePath}/content/${id}`} className='d-flex m-2'>
          <span className='me-1 text-dark fw-bold fs-6'>
            {type_delive == 0 ? (
              <KTSVG path='/media/icons/flight.svg' className='svg-icon-primary svg-icon-3' />
            ) : (
              <KTSVG path='/media/icons/anchor.svg' className='svg-icon-primary svg-icon-3' />
            )}
          </span>
          <span className='text-dark fw-bold fs-6'>{id}</span>
        </Link>{' '}
        {link && <p className='m-0 text-muted p-1 fs-8 fw-semobold'>{link}</p>}
      </div>
    </div>
  )
}

export {BuyoutNumberCell}
