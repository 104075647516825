/* eslint-disable react-hooks/exhaustive-deps */
import {useState, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {initialQueryState, KTSVG, useDebounce} from '../../../../../../_metronic/helpers'
import {useQueryRequest} from '../../../core/QueryRequestProvider'

const ParcelsListSearchComponent = () => {
  const {updateState} = useQueryRequest()
  const intl = useIntl()
  const [searchTerm, setSearchTerm] = useState<string>('')

  const debouncedSearchTerm = useDebounce(searchTerm, 400)

  const clearSearchField = () => {
    setSearchTerm('')
  }

  useEffect(() => {
    if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
      updateState({search: debouncedSearchTerm, ...initialQueryState})
    }
  }, [debouncedSearchTerm])

  return (
    <div className='card-title'>
      {/* begin::Search */}
      <div className='d-flex align-items-center position-relative my-1'>
        <KTSVG
          path='/media/icons/duotune/general/gen021.svg'
          className='svg-icon-1 position-absolute ms-6'
        />
        <input
          type='text'
          data-kt-user-table-filter='search'
          className='form-control form-control-solid w-250px ps-14'
          placeholder={intl.formatMessage({id: 'GENERAL.TABLE_SEARCH_WINDOW'})}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        {searchTerm && searchTerm !== '' && (
          <div onClick={clearSearchField} className=' position-absolute end-0 me-2'>
            <KTSVG path='/media/icons/duotune/general/gen040.svg' className='svg-icon-1' />
          </div>
        )}
      </div>
    </div>
  )
}

export {ParcelsListSearchComponent}
