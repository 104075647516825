/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../../../../_metronic/helpers'
import {ImageLightbox} from '../../../../../../modules/custom/images/ImageLightbox'
import {useListView} from '../../../../core/ListViewProvider'
import {Track} from '../../../../core/_models/_tracks-models'

type Props = {
  track: Track
}

const TrackNumberCell: FC<Props> = ({track}) => {
  const {track_code, receiver, type_delive, dispatch, preview_files} = track
  const intl = useIntl()
  const {isArchive} = useListView()
  const pagePath = isArchive === true ? '/archived-tracks/info' : '/tracks/edit'

  const [currentImage, setCurrentImage] = useState(0)

  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const openLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(true)
  }

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  return (
    <div className='d-flex align-items-center'>
      <div className='symbol symbol-45px me-5'>
        {preview_files && preview_files.length > 0 ? (
          <div className='cursor-zoom-in'>
            <div className='symbol symbol-fixed position-relative'>
              <img
                src={preview_files[0]}
                width='45'
                height='45'
                alt='track'
                onClick={openLightbox}
                style={{objectFit: 'contain'}}
              />

              <ImageLightbox
                viewerIsOpen={viewerIsOpen}
                closeLightbox={closeLightbox}
                currentImage={currentImage}
                images={preview_files.map((item, index) => {
                  return {src: item, title: `${track_code}-${index + 1}`}
                })}
              />
            </div>
          </div>
        ) : (
          <img src={toAbsoluteUrl('/media/icons/duotune/ecommerce/ecm010.svg')} alt='track' />
        )}{' '}
      </div>
      <div className='d-flex justify-content-start flex-column'>
        <Link to={`${pagePath}/overview/${track.id}`} className='d-flex m-2 mb-1'>
          <span className='me-1 text-dark fw-bold fs-6'>
            {type_delive == 0 ? (
              <KTSVG path='/media/icons/flight.svg' className='svg-icon-primary svg-icon-3' />
            ) : (
              <KTSVG path='/media/icons/anchor.svg' className='svg-icon-primary svg-icon-3' />
            )}
          </span>
          <span className='text-dark fw-bold fs-6'>{track_code}</span>
        </Link>{' '}
        {receiver && receiver.id !== 0 ? (
          <span className='mb-1 fs-8 fw-semobold'>{`${receiver.lastName} ${receiver.firstName} ${
            receiver.patronymic || ''
          }`}</span>
        ) : (
          <></>
        )}
        {dispatch === 0 && (
          <span className='text-muted fw-semobold text-muted d-block fs-7'>
            <span className={`p-1 fs-8 fw-semobold  StatusBar__status StatusBar_blocked`}>
              <FormattedMessage id='TRACKS.PAGE.LIST.NUMBER.PROH_STATUS' />
            </span>
          </span>
        )}
      </div>
    </div>
  )
}

export {TrackNumberCell}
