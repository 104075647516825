import {Formik, Form, FormikValues} from 'formik'
import {useState} from 'react'
import {IBuyoutPageSettingsTab} from '../../core/_models/_buyout-page-models'
import {CheckboxSwitcher} from '../../../../modules/custom/form-elements/checkboxes/CheckboxSwitcher'
import {Buyout} from '../../core/_models/_buyout-models'
import {buyoutPageSettingsSchema} from '../../core/yup/buyout-page'
import {InputTemplate} from '../../../../modules/custom/form-elements/InputTemplate'
import {CardFooter} from '../../../../modules/custom/form-elements/CardFooter'
import {SettingsRadio} from '../../../../modules/custom/form-elements/radios/SettingsRadio'
import {editBuyout} from '../../core/_requests'
import {FormattedMessage, useIntl} from 'react-intl'

interface Props {
  buyout: Buyout
  isArchive: boolean
  setCurrBuyout: (obj: Buyout) => void
}

export function BuyoutSettings({buyout, setCurrBuyout, isArchive}: Props) {
  const {dispatch, discount, type_delive, statuses, tracking, coupon_to_prod} = buyout
  const intl = useIntl()
  const initialValues = {
    delivery_type: +type_delive,
    dispatch: dispatch === 0 ? true : false,
    discountNumber: coupon_to_prod,
  }

  const disableCouponChange = statuses.find(({id_status}) => id_status === 2) ? true : false

  const disableDeliveryType = !tracking || tracking.tracks.length === 0 ? false : true

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  const handleSubmit = async (values: IBuyoutPageSettingsTab, actions: FormikValues) => {
    const {delivery_type, dispatch, discountNumber} = values

    setLoading(true)
    const response = await editBuyout({
      ...buyout,
      coupon: discountNumber,
      dispatch: dispatch === true ? 0 : 1,
      type_delive: delivery_type,
      conditionsAccepted: 1,
    })

    if (response) {
      setCurrBuyout(response)
      setLoading(false)
      setSuccess(true)

      setTimeout(() => {
        setSuccess(false)
      }, 3000)
    }
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <Formik
        validationSchema={buyoutPageSettingsSchema}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({values, setFieldValue}) => (
          <Form noValidate className='form'>
            <div className='card-body border-top p-9'>
              <div className='row mb-6 align-items-start'>
                <label className='col-lg-4 p-0 col-form-label fw-bold fs-6'>
                  <FormattedMessage id='BUYOUTS.FORM.SETTINGS.DISCOUNT' />
                </label>

                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-6 fv-row'>
                      <InputTemplate
                        inputName='discountNumber'
                        type='text'
                        disabled={isArchive === true ? isArchive : disableCouponChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-10 align-items-start'>
                <label className='col-lg-4 col-form-label  fw-bold fs-6 p-0'>
                  <FormattedMessage id='BUYOUTS.FORM.DELIVERY_TYPE_LABEL' />
                </label>

                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-6 fv-row'>
                      <CheckboxSwitcher
                        inputName='delivery_type'
                        firstOption={intl.formatMessage({id: 'GENERAL.DELIVERY_TYPE_NAME.AVIA'})}
                        secondOption={intl.formatMessage({id: 'GENERAL.DELIVERY_TYPE_NAME.SEA'})}
                        disabled={isArchive === true ? isArchive : disableDeliveryType}
                        // @ts-ignore
                        value={+values.delivery_type}
                        setFieldValue={setFieldValue}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {buyout.prods.length > 1 && !buyout.prods.some((item) => item.check_control === 1) && (
                <div className='row mb-6  align-items-start'>
                  <label className='col-lg-4 col-form-label  fw-bold fs-6 p-0'>Тип отправки</label>

                  <div className='col-lg-8'>
                    <div className='row'>
                      <SettingsRadio
                        title={intl.formatMessage({id: 'BUYOUTS.FORM.SEND_IN_FULL'})}
                        description={
                          <p className='text-muted fs-6 m-0'>
                            <FormattedMessage id='BUYOUTS.FORM.SEND_IN_FULL.EXPL' />
                          </p>
                        }
                        inputName='dispatch'
                        formValues={values}
                        value={0}
                        checkboxIsChecked={values.dispatch}
                        type='checkbox'
                        setFieldValue={setFieldValue}
                        disabled={isArchive}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            {isArchive === false && <CardFooter loading={loading} success={success} />}
          </Form>
        )}
      </Formik>
    </div>
  )
}
